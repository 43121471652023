;
PANA.multimedia = {};

PANA.multimedia.vidInsert = {
	init                  : function () {
		var _this = PANA.multimedia.vidInsert;

		_this.$videoContainer = $( '.video-frame' );

		_this.youTubeOptions = {
			'autoplay'       : 1,
			'controls'       : 1,
			'hl'             : 'fr-ca',
			'modestbranding' : 1,
			'rel'            : 0,
			'showinfo'       : 0,
			'color'          : 'white',
			'theme'          : 'light'
		}

		// Only once per session
		//if ( typeof _this.youtubeApiLoaded == 'undefined' || _this.youtubeApiLoaded == false ) {
		//	_this.loadYouTubeApi();
		_this.player;
		//} else {
		//}

		if ( _this.$videoContainer.length > 0 ) {

			_this.videoService = _this.$videoContainer.data( 'video-type' );
			_this.videoId = _this.$videoContainer.data( 'video-id' );
			_this.videoChapter = _this.$videoContainer.data( 'video-chapter' );
			_this.videoPage = _this.$videoContainer.data( 'video-page' );

			_this.bindEvents();
//_this.setupVimeo();
			_this.checkIfWatched( _this.videoChapter, _this.videoPage );

		}
	},
	bindEvents            : function () {
		var _this = PANA.multimedia.vidInsert;

		_this.$videoContainer.one( 'click', 'img', function ( e ) {
			_this.setupVimeo();

			//_this.player = new YT.Player( 'video-frame', {
			//	//height  : '390',
			//	//width   : '640',
			//	playerVars : _this.youTubeOptions,
			//	videoId    : 'LTrzSSf0YlA',
			//	events     : {
			//		'onReady'       : _this.onYtPlayerReady,
			//		'onStateChange' : _this.onYtPlayerStateChange
			//	}
			//} );
		} );
	},
	loadYouTubeApi        : function () {
		var _this = PANA.multimedia.vidInsert;

		// straight from https://developers.google.com/youtube/iframe_api_reference
		var tag = document.createElement( 'script' );

		tag.src = "https://www.youtube.com/iframe_api";
		var firstScriptTag = document.getElementsByTagName( 'script' )[ 0 ];
		firstScriptTag.parentNode.insertBefore( tag, firstScriptTag );

		_this.youtubeApiLoaded = true;

		window.onYouTubeIframeAPIReady = function () {

		}
	},
	setupYouTube          : function () {
		var _this = PANA.multimedia.vidInsert;


	},
	onYtPlayerReady       : function ( event ) {
		event.target.playVideo();
	},
	onYtPlayerStateChange : function ( event ) {
		var _this = PANA.multimedia.vidInsert;

		// event
		if ( event.data == YT.PlayerState.ENDED ) {

			_this.markAsWatched( _this.$videoContainer.data( 'video-chapter' ), _this.$videoContainer.data( 'video-page' ) );
			$( '#main-container' ).find( '.navigation' ).find( '.btn' ).addClass( 'active' ).removeClass( 'disable-click' );
			PANA.UTILS.scrollIntoView( $( '.navigation' ), -50, 150 );
		}
	},
	setupVimeo            : function () {
		var _this = PANA.multimedia.vidInsert;

		if ( typeof _this.videoPlayer != 'undefined' ) {
			delete _this.videoPlayer;
		}

		if ( typeof _this.$video != 'undefined' ) {
			_this.$video.remove();
			delete _this.$video;
			delete _this.vimPlayer;
			_this.cleanUpEvents();
		}

		_this.currIframeId = 'player_' + Number( new Date() );


		// Clear out the image
		_this.$videoContainer.html( '' );

		_this.$video = $( '<iframe name="' + _this.currIframeId + '" id="' + _this.currIframeId + '" src="https://player.vimeo.com/video/' + _this.videoId + '?api=1&player_id=' + _this.currIframeId + '&color=34c9c7&autoplay=1" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>' ).appendTo( _this.$videoContainer );


		// Need to grab the element
		_this.vimPlayer = $f( _this.$video );
		_this.vimPlayer.addEvent( 'ready', _this.bindVimeoEvents );


		if ( $( 'body' ).hasClass( 'ie9' ) || ( $( 'body' ).hasClass( 'ie10' ) && PANA.UTILS.hasFlash() ) ) {
			$( '#main-container' ).find( '.navigation' ).find( '.btn' ).addClass( 'active' ).removeClass( 'disable-click' );
			_this.markAsWatched( _this.$videoContainer.data( 'video-chapter' ), _this.$videoContainer.data( 'video-page' ) );
		}

	},
	bindVimeoEvents       : function () {
		var _this = PANA.multimedia.vidInsert;

		PANA.debug.console.log( 'Vimeo video is ready', _this.currIframeId, $f( _this.currIframeId ) );

		// never used, but could be useful
		//$f( _this.currIframeId ).addEvent( 'pause', function () {
		//} );

		$f( _this.currIframeId ).addEvent( 'finish', function () {
			_this.markAsWatched( _this.$videoContainer.data( 'video-chapter' ), _this.$videoContainer.data( 'video-page' ) );
			$( '#main-container' ).find( '.navigation' ).find( '.btn' ).addClass( 'active' ).removeClass( 'disable-click' );
			PANA.UTILS.scrollIntoView( $( '.navigation' ), -50, 150 );
		} );

		//$f( _this.currIframeId ).api( 'play' );
	},
	checkIfWatched        : function ( strChapter, strPage ) {
		var hasWatched = PANA.Games.utils.getGameState( strChapter, strPage );

		if ( hasWatched == true || hasWatched == 'win' ) {
			$( '#main-container' ).find( '.navigation' ).find( '.btn' ).addClass( 'active' ).removeClass( 'disable-click' );
		}
	},
	markAsWatched         : function ( strChapter, strPage ) {
		PANA.Games.utils.setGameState( strChapter, strPage, 'win' );

		PANA.D2L.fireEvent( 'vidéo visionnée en entier', strChapter + ':' + strPage );
	},
	cleanUpEvents         : function () {
		var _this = PANA.multimedia.vidInsert;

		if ( typeof $f != 'undefined' && _this.currIframeId != 'undefined' ) {

			// Vimeo events
			$f( _this.currIframeId ).removeEvent( 'ready' );
			$f( _this.currIframeId ).removeEvent( 'pause' );
			$f( _this.currIframeId ).removeEvent( 'finish' );

			try {
				_this.vimPlayer.api( 'unload' );
			}
			catch ( e ) {

			}
		}
	}
};

PANA.multimedia.audio = {
	init : function () {
		var _this = PANA.multimedia.audio;

	},


	cleanUpSounds : function ( arrIds ) {

		// rather not have split logic based on string versus array
		if ( !$.isArray( arrIds ) ) {
			arrIds = [ arrIds ];
		}

		for ( var i = 0; i < arrIds.length; i++ ) {
			createjs.Sound.removeSound( arrIds[ i ] );
		}
	}


}


PANA.multimedia.audio.mainInstructions = {
	init         : function ( playNow, forceAudioFile ) {
		var _this = PANA.multimedia.audio.mainInstructions;

		if ( typeof _this.currentInstructions == 'undefined' ) {
			_this.currentInstructions = false;
		}


		if ( typeof forceAudioFile == 'undefined' ) {
			var consigneAudio = $( 'header.header-info' ).data( 'audio-consigne' );
		} else if ( forceAudioFile != '' ) {
			var consigneAudio = forceAudioFile;
		}

		if ( typeof consigneAudio !== 'undefined' && '' != consigneAudio ) {
			PANA.config.$headerInfoInstructionsPlayBtn.show();

			//_this.currentInstructions = PANA.multimedia.audio.prepAudio( consigneAudio );

			if ( typeof _this.currentInstructions != 'undefined' ) {
				createjs.Sound.removeSound( 'currentInstructions' );
			}

			_this.currentInstructions = createjs.Sound.registerSound( consigneAudio, 'currentInstructions', 0, PANA.config.strAudioFolder );
			if ( typeof playNow != 'undefined' && playNow == true ) {
				createjs.Sound.play( _this.currentInstructions.id );
			}
		} else {
			PANA.config.$headerInfoInstructionsPlayBtn.hide();
			if ( typeof _this.consigneAudio != 'undefined' ) {
				_this.consigneAudio = null;
				delete _this.consigneAudio;
			}
		}

		_this.unbindEvents();
		_this.bindEvents();
	},
	bindEvents   : function () {
		var _this = PANA.multimedia.audio.mainInstructions;

		PANA.config.$headerInfoInstructionsPlayBtn.on( 'click.playinstructions', function ( e ) {
			e.preventDefault();

			if ( _this.currentInstructions ) {

				PANA.debug.console.log( 'Playing currentInstructions', PANA.config.interruptAudio );
				if ( PANA.config.interruptAudio == true ) {
					createjs.Sound.stop();
				}

				if ( typeof PANA.Games.multiplechoice.currentlyPlaying != 'undefined' && PANA.Games.multiplechoice.currentlyPlaying ) {
					PANA.Games.multiplechoice.currentlyPlaying.stop();
				}
				_this.playingInstruction = createjs.Sound.play( 'currentInstructions' );
			}
		} );

	},
	unbindEvents : function () {
		var _this = PANA.multimedia.audio.mainInstructions;

		PANA.config.$headerInfoInstructionsPlayBtn.off( 'click.playinstructions' );
	}
}