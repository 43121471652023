// Based on https://codepen.io/linrock/pen/Amdhr/

if ( typeof PANA.special == 'undefined' ) {
	PANA.special = {};
}

PANA.special.confetti = {
	init         : function ( $containerToFill ) {
		var _this = PANA.special.confetti;

		if ( typeof $containerToFill == 'undefined' ) {
			$containerToFill = $( 'body' );
		}

		_this.colors = [ [ 85, 71, 106 ], [ 174, 61, 99 ], [ 219, 56, 83 ], [ 244, 92, 68 ], [ 248, 182, 70 ] ];
		_this.numConfetti = 750;
		_this.pi_2 = 2 * Math.PI;

		_this.$containerToFill = $containerToFill;
		_this.$container = $( '<canvas id="confettti" >' ).appendTo( $containerToFill );
		_this.canvas = _this.$container.get( 0 );
		_this.context = _this.canvas.getContext( "2d" );

		_this.shouldContinue = true;

		_this.xpos = 0.5; // direction-related

		_this.adjustWindow();

		_this.allConfetti = _this.confetti();
		_this.step();

		_this.bindEvents();


	},
	adjustWindow : function () {
		var _this = PANA.special.confetti;

		_this.canvasWidth = _this.$containerToFill.innerWidth();
		_this.canvasHeight = _this.$containerToFill.innerHeight();

		_this.canvas.width = _this.canvasWidth;
		_this.canvas.height = _this.canvasHeight;
		_this.$container.width( _this.canvasWidth );
		_this.$container.height( _this.canvasHeight );

	}

	,
	bindEvents : function () {
		var _this = PANA.special.confetti;

		$( window ).on( 'resize.confetti', function () {
			_this.adjustWindow();
		} );

		$( 'body' ).on( 'mousemove.confetti', function ( e ) {
			_this.xpos = e.pageX / _this.canvasWidth;
		} );


		window.requestAnimationFrame = (function () {
			return window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || window.oRequestAnimationFrame || window.msRequestAnimationFrame || function ( callback ) {
					return window.setTimeout( callback, 1000 / 60 );
				};
		})();
	}
	,
	range      : function ( a, b ) {
		return (b - a) * Math.random() + a;
	}
	,
	drawCircle : function ( x, y, r, style ) {
		var _this = PANA.special.confetti;
		_this.context.beginPath();
		_this.context.arc( x, y, r, 0, _this.pi_2, false );
		_this.context.fillStyle = style;

		return _this.context.fill();
	}
	,
	confetti   : function () {
		var j, ref, results;
		var _this = PANA.special.confetti;

		results = [];
		for ( i = j = 1, ref = _this.numConfetti; 1 <= ref ? j <= ref : j >= ref; i = 1 <= ref ? ++j : --j ) {
			results.push( new Confetti );
		}
		return results;
	}
	,
	step       : function () {
		var c, j, len, results;
		var _this = PANA.special.confetti;
		if ( _this.shouldContinue ) {
			requestAnimationFrame( _this.step );
			_this.context.clearRect( 0, 0, _this.canvasWidth, _this.canvasHeight );
			results = [];

			for ( j = 0, len = _this.allConfetti.length; j < len; j++ ) {
				c = _this.allConfetti[ j ];
				results.push( c.draw() );
			}
			return results;
		} else {
			return false;
		}
	}
	,
	stop       : function () {
		var _this = PANA.special.confetti;
		_this.shouldContinue = false;
	},
	destroy    : function () {
		var _this = PANA.special.confetti;

		_this.stop();
		_this.$container.remove()
		delete _this.allConfetti;

		$( window ).off( 'resize.confetti' );

		$( 'body' ).off( 'mousemove.confetti' );
	}

}


Confetti = (function ( colors ) {
	var _this = Confetti;


	_this.range = PANA.special.confetti.range;
	_this.colors = [ [ 85, 71, 106 ], [ 174, 61, 99 ], [ 219, 56, 83 ], [ 244, 92, 68 ], [ 248, 182, 70 ] ];

	function Confetti() {
		this.style = _this.colors[ ~~_this.range( 0, 5 ) ];
		this.rgb = "rgba(" + this.style[ 0 ] + "," + this.style[ 1 ] + "," + this.style[ 2 ];
		this.r = ~~_this.range( 2, 6 );
		this.r2 = 2 * this.r;
		this.replace();
	}

	Confetti.prototype.replace = function () {
		this.opacity = 0;
		this.dop = 0.04 * Confetti.range( 1, 4 );
		this.x = Confetti.range( -this.r2, PANA.special.confetti.canvasWidth - this.r2 );
		this.y = Confetti.range( -20, PANA.special.confetti.canvasHeight - this.r2 );
		this.xmax = PANA.special.confetti.canvasWidth - this.r;
		this.ymax = PANA.special.confetti.canvasHeight - this.r;
		this.vx = PANA.special.confetti.range( 0, 2 ) + 8 * PANA.special.confetti.xpos - 5;
		return this.vy = 0.7 * this.r + PANA.special.confetti.range( -1, 1 );
	};

	Confetti.prototype.draw = function () {
		var ref;
		this.x += this.vx;
		this.y += this.vy;
		this.opacity += this.dop;
		if ( this.opacity > 1 ) {
			this.opacity = 1;
			this.dop *= -1;
		}
		if ( this.opacity < 0 || this.y > this.ymax ) {
			this.replace();
		}
		if ( !((0 < (ref = this.x) && ref < this.xmax)) ) {
			this.x = (this.x + this.xmax) % this.xmax;
		}
		return PANA.special.confetti.drawCircle( ~~this.x, ~~this.y, this.r, this.rgb + "," + this.opacity + ")" );
	};

	return Confetti;

})();