;
PANA.Games.multiplechoice = {
	init                            : function ( activityName, chapter, activity ) {
		var _this = PANA.Games.multiplechoice;

		_this.cleanUp();

		_this.options = {
			snapTo : true
		}

		_this.chapter = chapter;
		_this.activity = activity;

		_this.$activityContainer = $( '.activity' );

		_this.currentState = 'normal';


		_this.$questionsContainer = _this.$activityContainer.find( '.questions-container' );

		_this.$questions = _this.$questionsContainer.find( '.question' );
		_this.numQuestions = _this.$questions.length;
		_this.numCorrectAnswers = 0;

		_this.currentQuestion = 0;

		_this.$questionCounter = _this.$activityContainer.find( '.question-counter' );
		_this.$resultGraphic = _this.$activityContainer.find( '.ballon-container' ).add( '.arbre-container' );
		_this.$btnNext = $( '.question-nav' ).find( '.btn-next' );


		_this.hasBgMusic = _this.chapter == 'chapitre_4' && _this.activity == 'activite_3';
		PANA.config.interruptAudio = !_this.hasBgMusic;
		_this.currentlyPlaying = false;

		_this.setupSounds();
		_this.bindInfoClick();

		_this.setupQuestions();

		_this.setupTouch();
		_this.bindNextQuestion();

		_this.updateQuestionsCounter();

		_this.replayAnswers();

	},
	cleanUp                         : function () {
		var _this = PANA.Games.multiplechoice;

		PANA.config.interruptAudio = true;


		createjs.Sound.off( "fileload", _this.handleBgMusicLoad );

		// unbinding
		if ( typeof _this.$activityContainer != 'undefined' ) {
			_this.$activityContainer.off( 'click' );
		}

		if ( typeof _this.$btnNext != 'undefined' ) {
			_this.$btnNext.off( 'click' );
		}

		var keysToClean = [
			'options',
			'currentState',
			'sounds',
			'$questionsContainer',
			'$questions',
			'numQuestions',
			'numCorrectAnswers',
			'currentQuestion',
			'$questionCounter',
			'$resultGraphic',
			'$btnNext',
			'hasBgMusic',
			'currentlyPlaying',


			'$activityContainer',
		];

		PANA.UTILS.clearFromMemory( _this, keysToClean );

	},
	cleanupSounds                   : function () {
		var _this = PANA.Games.multiplechoice;

	},
	setupSounds                     : function () {
		var _this = PANA.Games.multiplechoice;


		_this.sounds = {};

		var soundGeneralWin = _this.$activityContainer.data( 'sound-win' ) || false,
			soundGeneralFail = _this.$activityContainer.data( 'sound-fail' ) || false,
			soundGeneralSuccess = _this.$activityContainer.data( 'sound-success' ) || false;

		_this.sounds.win = soundGeneralWin ? createjs.Sound.registerSound( soundGeneralWin, _this.chapter + '_' + _this.activity + '_win', 0, PANA.config.strAudioFolder ) : false;
		_this.sounds.fail = soundGeneralFail ? createjs.Sound.registerSound( soundGeneralFail, _this.chapter + '_' + _this.activity + '_fail', 0, PANA.config.strAudioFolder ) : false;
		_this.sounds.success = soundGeneralSuccess ? createjs.Sound.registerSound( soundGeneralSuccess, _this.chapter + '_' + _this.activity + '_success', 0, PANA.config.strAudioFolder ) : false;


		_this.sounds.choices = {};

		_this.$questions.each( function ( qIndex, qElement ) {

			var $thisQuestion = $( qElement ),
				$choices = $thisQuestion.find( '.choice' );

			_this.sounds.choices[ qIndex ] = {};

			$choices.each( function ( cIndex, cElement ) {
				var $thisChoice = $( cElement ),
					choiceConsigne = $thisChoice.data( 'sound-choice' ) || false,
					choiceRetroaction = $thisChoice.data( 'sound-retroaction' ) || false,
					choiceSuccess = $thisChoice.data( 'sound-success' ) || false;

				_this.sounds.choices[ qIndex ][ cIndex ] = {};

				_this.sounds.choices[ qIndex ][ cIndex ] = {
					retroaction : choiceRetroaction ? createjs.Sound.registerSound( choiceRetroaction, _this.chapter + '_' + _this.activity + '_' + qIndex + '_' + cIndex + '_retroaction', 0, PANA.config.strAudioFolder ) : false,
					consigne    : choiceConsigne ? createjs.Sound.registerSound( choiceConsigne, _this.chapter + '_' + _this.activity + '_' + qIndex + '_' + cIndex + '_consigne', 0, PANA.config.strAudioFolder ) : false,
					success     : choiceSuccess ? createjs.Sound.registerSound( choiceSuccess, _this.chapter + '_' + _this.activity + '_' + qIndex + '_' + cIndex + '_success', 0, PANA.config.strAudioFolder ) : false,
				}

			} );

		} );


		if ( _this.chapter == 4 && _this.activity == 1 ) {
			_this.sounds.success = [];
			for ( var i = 1; i < 7; i++ ) {
				_this.sounds.success.push( createjs.Sound.registerSound( 'chapitre_4_activite_1_success_' + i + '.mp3', _this.chapter + '_' + _this.activity + '_' + i + '_success', 0, PANA.config.strAudioFolder ) );
			}
		} else if ( _this.chapter == 'chapitre_4' && _this.activity == 'activite_3' ) {


			//createjs.Sound.on( "fileload", _this.handleBgMusicLoad );
			_this.sounds.music = createjs.Sound.registerSound( 'chapitre_4_activite_3_bg_music.mp3', _this.chapter + '_' + _this.activity + '_bg_music', 0, PANA.config.strAudioFolder )

		}

	},
	handleBgMusicLoad               : function ( event ) {
		var _this = PANA.Games.multiplechoice;

		if ( typeof _this.sounds != 'undefined' && typeof _this.sounds.music != 'undefined' && _this.sounds.music.id != 'undefined' && event.id == _this.sounds.music.id ) {
			createjs.Sound.play( _this.sounds.music.id, { loop : -1 } );
			createjs.Sound.off( "fileload", _this.handleBgMusicLoad );
		}
	},
	setupQuestions                  : function () {
		var _this = PANA.Games.multiplechoice;

		_this.$questions.not( _this.$questions.eq( _this.currentQuestion ) ).hide();

		PANA.debug.console.log( 'Setting up question index #' + _this.currentQuestion, _this.numCorrectAnswers, _this.numQuestions );

		if ( _this.numCorrectAnswers != _this.numQuestions ) {

			var $currentQuestionContainer = _this.$questions.eq( _this.currentQuestion ),
				$choices = $currentQuestionContainer.find( '.choice' ),
				$failIcon = $choices.find( '.chap4act1-fail' );


			$currentQuestionContainer.removeClass( 'failed success complete' )
				.addClass( 'not-answered' )
				.show();

			$choices.removeClass( 'success failed disabled' );
			$failIcon.remove();
		} else {
			_this.$questions.eq( _this.currentQuestion ).show();
		}
	},
	nextQuestion                    : function () {
		var _this = PANA.Games.multiplechoice;


		_this.currentQuestion = _this.findNextWrongOrUnplayedQuestion();

		if ( _this.currentQuestion === false ) {
			_this.currentQuestion = _this.numQuestions - 1;
		}

		if ( _this.currentlyPlaying ) {
			_this.currentlyPlaying.stop();
		}
		// In case the instructions audio is playing
		if ( typeof PANA.multimedia.audio.mainInstructions.playingInstruction === 'object' ) {
			try {
				PANA.multimedia.audio.mainInstructions.playingInstruction.stop();
			}
			catch ( e ) {

			}
		}

		_this.setupQuestions();
		_this.updateQuestionsCounter();


	},
	bindNextQuestion                : function () {
		var _this = PANA.Games.multiplechoice;

		_this.$btnNext.on( 'click', function ( e ) {
			e.preventDefault();

			var $this = $( this ),
				$question = $this.parents( '.question' );

			_this.nextQuestion();

			$( this ).removeClass( 'active' ).hide();

		} );
	},
	setupTouch                      : function () {
		var _this = PANA.Games.multiplechoice;


		_this.$activityContainer.on( 'click', '.choice .image-container', function ( e ) {
			e.preventDefault();

			var $clickedChoice = $( this ).parents( '.choice' );


			if ( $clickedChoice.hasClass( 'disabled' ) || $clickedChoice.parents( '.question' ).hasClass( 'complete' ) ) {
				return false;
			}


			_this.validateAnswer( $clickedChoice );


		} );

	},
	bindInfoClick                   : function () {
		var _this = PANA.Games.multiplechoice;

		_this.$activityContainer.on( 'click', '.play-container', function ( e ) {
			var $this = $( this ),
				$choices = $this.parents( '.choices' ).find( '.choice' ),
				choiceIndex = $choices.index( $this.parents( '.choice' ) );


			if ( typeof _this.sounds.choices[ _this.currentQuestion ][ choiceIndex ][ 'consigne' ] != 'undefined' ) {

				// Stop the currently playing sound but not the background music
				if ( _this.currentlyPlaying ) {
					_this.currentlyPlaying.stop();
				}
				// In case the instructions audio is playing
				if ( typeof PANA.multimedia.audio.mainInstructions.playingInstruction === 'object' ) {
					try {
						PANA.multimedia.audio.mainInstructions.playingInstruction.stop();
					}
					catch ( e ) {

					}
				}
				_this.currentlyPlaying = createjs.Sound.play( _this.sounds.choices[ _this.currentQuestion ][ choiceIndex ][ 'consigne' ].id );

			}

		} );
	},
	updateQuestionsCounter          : function () {
		var _this = PANA.Games.multiplechoice;

		_this.$questionCounter.html( (_this.currentQuestion + 1) + '/' + _this.numQuestions );
	},
	updateResultGraphic             : function () {
		var _this = PANA.Games.multiplechoice;

		var classes = '';
		for ( var i = 0; i < _this.numQuestions; i++ ) {
			classes += ' state-' + i + ' ';
		}

		_this.$resultGraphic
			.removeClass( classes )
			.addClass( 'state-' + _this.numCorrectAnswers );


	},
	validateAnswer                  : function ( $clicked ) {
		var _this = PANA.Games.multiplechoice;

		var $this = $clicked,
			clickedValue = $this.data( 'value' ),
			isCorrect = clickedValue == 1;

		if ( _this.currentState != 'replaying' ) {
			_this.saveAnswer( clickedValue );
		}

		if ( isCorrect ) {
			_this.numCorrectAnswers++;
			_this.showSuccess( $this, clickedValue );

		} else {
			_this.showFail( $this );
		}

		$this.parents( '.question' ).addClass( 'complete' );

		if ( _this.currentState == 'replaying' ) {
			// _this.nextQuestion();
		}

		_this.checkIsDone();
	},
	showSuccess                     : function ( $clicked, clickedValue ) {
		var _this = PANA.Games.multiplechoice;

		var choiceIndex = $clicked.parents( '.choices' ).find( '.choice' ).index( $clicked );


		$clicked.addClass( 'success disabled' );
		$clicked.parents( '.question' ).removeClass( 'failed not-answered' ).addClass( 'success' );

		_this.updateResultGraphic();

		if ( _this.currentState != 'replaying' ) {
			if ( _this.sounds.choices[ _this.currentQuestion ][ choiceIndex ][ 'retroaction' ] ) {

				// Stop the currently playing sound but not the background music
				if ( _this.currentlyPlaying ) {
					_this.currentlyPlaying.stop();
				}
				// In case the instructions audio is playing
				if ( typeof PANA.multimedia.audio.mainInstructions.playingInstruction === 'object' ) {
					try {
						PANA.multimedia.audio.mainInstructions.playingInstruction.stop();
					}
					catch ( e ) {

					}
				}


				// Play "success" specific to this choice
				_this.currentlyPlaying = createjs.Sound.play( _this.sounds.choices[ _this.currentQuestion ][ choiceIndex ][ 'retroaction' ].id );

			} else if ( _this.sounds.success ) {
				// Stop the currently playing sound but not the background music
				if ( _this.currentlyPlaying ) {
					_this.currentlyPlaying.stop();
				}
				// In case the instructions audio is playing
				if ( typeof PANA.multimedia.audio.mainInstructions.playingInstruction === 'object' ) {
					try {
						PANA.multimedia.audio.mainInstructions.playingInstruction.stop();
					}
					catch ( e ) {

					}
				}

				// Play a general "success" sound
				_this.currentlyPlaying = createjs.Sound.play( _this.sounds.success.id );
			}
		}

		_this.checkIsDone();

	},
	showFail                        : function ( $clicked ) {
		var _this = PANA.Games.multiplechoice;

		$clicked.addClass( 'failed disabled' );
		$clicked.parents( '.question' ).addClass( 'failed' ).removeClass( 'not-answered' );

		var choiceIndex = $clicked.parents( '.choices' ).find( '.choice' ).index( $clicked ),
			$failIcon = $( _this.failIcon ).appendTo( $clicked );

		if ( _this.currentState != 'replaying' ) {


			if ( _this.sounds.choices[ _this.currentQuestion ][ choiceIndex ][ 'retroaction' ] ) {
				// Stop the currently playing sound but not the background music
				if ( _this.currentlyPlaying ) {
					_this.currentlyPlaying.stop();
				}
				// In case the instructions audio is playing
				if ( typeof PANA.multimedia.audio.mainInstructions.playingInstruction === 'object' ) {
					try {
						PANA.multimedia.audio.mainInstructions.playingInstruction.stop();
					}
					catch ( e ) {

					}
				}

				// Play "success" specific to this choice
				_this.currentlyPlaying = createjs.Sound.play( _this.sounds.choices[ _this.currentQuestion ][ choiceIndex ][ 'retroaction' ].id );
			} else if ( _this.sounds.fail ) {
				// Stop the currently playing sound but not the background music
				if ( _this.currentlyPlaying ) {
					_this.currentlyPlaying.stop();
				}
				// In case the instructions audio is playing
				if ( typeof PANA.multimedia.audio.mainInstructions.playingInstruction === 'object' ) {
					try {
						PANA.multimedia.audio.mainInstructions.playingInstruction.stop();
					}
					catch ( e ) {

					}
				}

				// Play a general "success" sound
				_this.currentlyPlaying = createjs.Sound.play( _this.sounds.fail.id );
			}
		}
	},
	checkIsDone                     : function () {
		var _this = PANA.Games.multiplechoice;

		if ( _this.numCorrectAnswers == _this.numQuestions ) {
			PANA.Games.utils.setGameState( _this.chapter, _this.activity, 'win' );
			_this.endAnimation();


			// always for any activity other than 1, and in 1 only if they got all the correct answers
			if ( _this.sounds.win && (_this.activity !== 'activite_1' || (_this.activity == 'activite_1' && _this.numCorrectAnswers == _this.$questions.length )) ) {
				// Stop the currently playing sound but not the background music
				if ( _this.currentlyPlaying ) {
					_this.currentlyPlaying.stop();
				}
				// In case the instructions audio is playing
				if ( typeof PANA.multimedia.audio.mainInstructions.playingInstruction === 'object' ) {
					try {
						PANA.multimedia.audio.mainInstructions.playingInstruction.stop();
					}
					catch ( e ) {

					}
				}

				_this.currentlyPlaying = createjs.Sound.play( _this.sounds.win.id ); // The music is now included in the win
				//createjs.Sound.play(_this.sounds.music.id);
			}

			_this.$btnNext.removeClass( 'active' ).hide();

			PANA.NAV.showNav();

		} else {
			PANA.Games.utils.setGameState( _this.chapter, _this.activity, 'begun' );
			_this.$btnNext.addClass( 'active' ).show();
		}
	},
	saveAnswer                      : function ( clickedValue ) {
		var _this = PANA.Games.multiplechoice;

		PANA.Games.utils.saveAnswer( _this.chapter, _this.activity, clickedValue, _this.currentQuestion );

	},
	replayAnswers                   : function () {
		var _this = PANA.Games.multiplechoice;

		var state = PANA.Games.utils.getGameState( _this.chapter, _this.activity ),
			answers;

		if ( state ) {
			answers = PANA.Games.utils.getAnswers( _this.chapter, _this.activity );

			if ( answers ) {
				_this.currentState = 'replaying';


				for ( var i = 0; i < answers.length; i++ ) {
					if ( answers[ i ] == 1 ) {
						_this.numCorrectAnswers++;
						_this.currentQuestion = i;
					}
					//_this.validateAnswer( _this.$questions.eq( i ).find( '.choice[data-value="' + answers[ i ] + '"]' ), true );
				}

				if ( state == 'win' ) {
					_this.currentQuestion = _this.numQuestions - 1;
					_this.showSuccess( _this.$questions.last().find( '.choice[data-value="1"]' ) );
				} else {
					_this.nextQuestion();
					_this.$btnNext.removeClass( 'active' ).hide();
				}
				_this.setupQuestions();

				_this.currentState = 'normal';
				_this.checkIsDone();
			}
		}
	},
	endAnimation                    : function () {
		var _this = PANA.Games.multiplechoice;

		if ( typeof _this.alreadyWon == 'undefined' && _this.activity == 'activite_1' ) {
			if ( _this.numCorrectAnswers == _this.numQuestions ) {
				PANA.special.confetti.init();
			}
			_this.alreadyWon = true;
		}

	},
	findNextWrongOrUnplayedQuestion : function () {
		var _this = PANA.Games.multiplechoice;

		var answers = PANA.Games.utils.getAnswers( _this.chapter, _this.activity ),
			nextQuestion = _this.currentQuestion + 1;

		if ( answers ) {


			if ( typeof answers[ nextQuestion ] == 'undefined' && nextQuestion < _this.numQuestions ) {
				// next one hasn't been answered yet
				return nextQuestion;

			} else {
				if ( _this.currentState != 'replaying' ) {
					for ( var i = nextQuestion; i < _this.numQuestions; i++ ) {
						if ( answers[ i ] == 0 ) {
							// Looking after current for a false/bad answer
							return i;
						}
					}
				}

				// At this point, there aren't any questions after the current one that are "bad answers",
				// Let's start from the beginning to see if there are any others.
				for ( var i = 0; i < _this.numQuestions; i++ ) {
					if ( answers[ i ] == 0 || typeof answers[ i ] == 'undefined' ) {
						// Looking after current for a false/bad answer
						return i;
					}
				}

				// Didn't find any bad answers after this one AND didn't find any before and including this one
				// We must be done

				return false;
			}

		}
		return nextQuestion;
	},
	failIcon                        : '<img class="chap4act1-fail" alt="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAANcAAADXAQMAAACzs1b2AAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAAB1JREFUeNrtwTEBAAAAwqD1T20KP6AAAAAAAICHAReEAAHU6brLAAAAAElFTkSuQmCC">'
}