;

PANA.avatars = {
	init               : function () {
		var _this = PANA.avatars;

		_this.cleanUp();

		_this.selectedAvatar = false;
		_this.$activityNav = $( '.navigation' );
		_this.$activityContainer = $( '.activity-container' );
		_this.$avatars = _this.$activityContainer.find( '.avatar-container' );

		_this.alreadyRun = false;
		_this.checkIfDone( true );

		_this.bindEvents();
		_this.cleanupSounds();
		_this.setupAudio();
	},
	cleanUp            : function () {
		var _this = PANA.avatars;

		PANA.debug.console.log( 'cleaning avatar memory' );
		if ( typeof _this.$avatars != 'undefined' ) {
			_this.$avatars.each( function ( index, element ) {
				$( this ).empty();
			} );
		}

		// unbinding
		if ( typeof _this.$activityContainer != 'undefined' ) {
			_this.$activityContainer.off( 'click' );
		}

		var keysToClean = [
			'options',
			'$avatars',
			'selectedAvatar',
			'$activityNav',
			'$avatars',
			'alreadyRun',
		];

		PANA.UTILS.clearFromMemory( _this, keysToClean );


	},
	setupAudio         : function () {
		var _this = PANA.avatars;

		// Attach the soundClips to each avatar
		_this.$avatars.each( function ( index, element ) {
			var $this = $( this ),
				avatarAudio = $this.data( 'audio' ) || false,
				avatarKey = $this.data( 'avatar-key' ) || false;


			if ( avatarAudio ) {
				//$this.data('avatarAudio', PANA.multimedia.audio.prepAudio(avatarAudio));
				$this.data( 'avatarAudio', createjs.Sound.registerSound( avatarAudio, 'avatar-' + avatarKey, 0, PANA.config.strAudioFolder ) );
			}
		} );
	},
	cleanupSounds      : function () {
		var _this = PANA.avatars;


		_this.$avatars.each( function ( index, element ) {
			var $this = $( this ),
				avatarAudio = $this.data( 'audio' ) || false,
				avatarKey = $this.data( 'avatar-key' ) || false;


			if ( avatarAudio ) {
				//$this.data('avatarAudio', PANA.multimedia.audio.prepAudio(avatarAudio));
				createjs.Sound.removeSound( 'avatar-' + avatarKey );
			}
		} );
	},
	bindEvents         : function () {
		var _this = PANA.avatars;

		_this.$activityContainer.on( 'click', '.avatar-container', function ( e ) {
			var $this = $( this ),
				avatarAudio = $this.data( 'avatarAudio' ),
				avatarKey = $this.data( 'avatar-key' );

			_this.$avatars.not( $this ).removeClass( 'animated' );
			$this.addClass( 'clicked selected animated' );
			createjs.Sound.stop();
			createjs.Sound.play( avatarAudio.id );

			_this.checkIfDone();
		} );
	},
	checkIfDone        : function ( isFirstCheck ) {
		var _this = PANA.avatars;

		if ( isFirstCheck ) {
			_this.alreadyRun = PANA.Games.utils.getGameState( 'chapitre_0', 'accueil' ) == 'win';
		}

		if ( _this.$avatars.filter( '.selected' ).length == _this.$avatars.length || _this.alreadyRun == true ) {
			PANA.Games.utils.setGameState( 'chapitre_0', 'accueil', 'win' );
			PANA.NAV.showNav();
		}
	},
	updateHeaderAvatar : function ( chapterId ) {
		var _this = PANA.avatars;

		PANA.config.$headerAvatarContainer.html( _this.avatarSprites[ _this.avatarsLookup[ chapterId ] ] );

		PANA.config.$headerAvatarContainer
			.removeClass( 'chosen-avatar-caribou chosen-avatar-porc-epic chosen-avatar-castor chosen-avatar-ours chosen-avatar-moufette' )
			.addClass( 'chosen-avatar-' + _this.avatarsLookup[ chapterId ] );


	},
	avatarsLookup      : {
		chapitre_0 : 'moufette',
		chapitre_1 : 'caribou',
		chapitre_2 : 'moufette',
		chapitre_3 : 'castor',
		chapitre_4 : 'ours',
		chapitre_5 : 'porc-epic',
	},
	avatarSprites      : {
		'caribou'   : '<img class="header-avatar-caribou" alt="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMoAAADIAQMAAACTY+zgAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAABtJREFUeNrtwQEBAAAAgiD/r25IQAEAAAAAfBkVGAABhzC48AAAAABJRU5ErkJggg==">',
		'porc-epic' : '<img class="header-avatar-porc-epic" alt="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJQAAACJAQMAAADwuoPSAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAABpJREFUeNrtwTEBAAAAwqD1T20ND6AAAODbAAq0AAFBUh/dAAAAAElFTkSuQmCC">',
		'castor'    : '<img class="header-avatar-castor" alt="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIkAAAB9AQMAAACs6dKFAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAABlJREFUeNrtwTEBAAAAwqD1T20Hb6AAADgMCUcAAV0bpkAAAAAASUVORK5CYII=">',
		'ours'      : '<img class="header-avatar-ours" alt="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIcAAACHAQMAAAAGKdhJAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAABlJREFUeNrtwTEBAAAAwqD1T20LL6AAAPgZCX4AAVvp634AAAAASUVORK5CYII=">',
		'moufette'  : '<img class="header-avatar-moufette" alt="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIIAAACEAQMAAABmlGGjAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAABlJREFUeNrtwTEBAAAAwqD1T20Hb6AAAHgMCUgAAVkf6xEAAAAASUVORK5CYII=">'
	}
}