/*!
 * Used to layout and fit
 */


PANA.layout = {

	init             : function () {	// Based on https://github.com/enovajuan/reveal.js/blob/master/js/reveal.js#L1026-L1074
		var _this = PANA.layout;

		_this.adjustLayout();

		_this.bindEvents();

	},
	bindEvents       : function () {
		var _this = PANA.layout;

		// Debounced so that the browser doesn't go crazy during resize
		var debouncedLayout = PANA.UTILS.debounce( function () {
			_this.adjustLayout();
		}, 250 );
		window.addEventListener( 'resize', debouncedLayout );
	},
	adjustLayout     : function () {
		var _this = PANA.layout;

		var availableWidth = PANA.config.$pageWrapper.outerWidth(),
			availableHeight = PANA.config.$pageWrapper.outerHeight();


// Dimensions of the content
		var slideWidth = PANA.config.baseWidth,
			slideHeight = PANA.config.baseHeight;


		PANA.config.$ajaxContainer.get( 0 ).style.width = slideWidth + 'px';
		PANA.config.$ajaxContainer.get( 0 ).style.height = slideHeight + 'px';

// Determine scale of content to fit within available space
		var scale = Math.min( availableWidth / slideWidth, availableHeight / slideHeight );

// Respect max/min scale settings
//		scale = Math.max( scale, config.minScale );
//		scale = Math.min( scale, config.maxScale );

// Prefer applying scale via zoom since Chrome blurs scaled content
// with nested transforms
		if ( typeof PANA.config.$ajaxContainer.get( 0 ).style.zoom !== 'undefined' && !navigator.userAgent.match( /(iphone|ipod|ipad|android)/gi ) ) {
			PANA.config.$ajaxContainer.get( 0 ).style.zoom = scale;
		}
// Apply scale transform as a fallback
		else {
			_this.transformElement( PANA.config.$ajaxContainer.get( 0 ), 'translate(-50%, -50%) scale(' + scale + ') translate(50%, 50%)' );
		}

	},
	transformElement : function ( element, transform ) {
		var _this = PANA.layout;

		element.style.WebkitTransform = transform;
		element.style.MozTransform = transform;
		element.style.msTransform = transform;
		element.style.OTransform = transform;
		element.style.transform = transform;

	}
};

