;

PANA.NAV = {
	init       : function () {
		var _this = PANA.NAV;

		_this.$pageWrap = $( '#page-wrap' );

		_this.bindEvents();
	},
	bindEvents : function () {
		$( 'body' ).on( 'click', 'a[data-chapter-link]', function ( e ) {
			e.preventDefault();

			var $this = $( this );
			if ( !PANA.config.debug && $this.hasClass( 'disable-click' ) ) {
				return false;
			}


			var strPageToLoad = $this.attr( 'data-chapter-link' );

			// overload based on request to jump back based on completion
			if ( $this.hasClass( 'btn-prev' ) ) {
				// On recule sur la page de la vid�o du chapitre 2
				if ( strPageToLoad == 'chapitre_1:activite_4_video' ) {
					// Anything but false ( On a commenc� ou gagn� l'activit� 1 du chapitre 1 )
					if ( PANA.Games.utils.getGameState( 'chapitre_1', 'activite_1' ) ) {
						strPageToLoad = 'chapitre_1:activite_1';
					} else {
						strPageToLoad = 'chapitre_1:video';
					}
				}
				// On recule sur la page de la vid�o du chapitre 3
				else if ( strPageToLoad == 'chapitre_2:activite_1' ) {
					// Anything but false ( On a commenc� ou gagn� l'activit� 1 du chapitre 2)
					if ( PANA.Games.utils.getGameState( 'chapitre_2', 'activite_1' ) ) {
						strPageToLoad = 'chapitre_2:activite_1';
					} else {
						strPageToLoad = 'chapitre_2:video';
					}
				}
				// On recule sur la page de la vid�o du chapitre 4
				else if ( strPageToLoad == 'chapitre_3:activite_2' ) {
					// Anything but false ( On a commenc� ou gagn� l'activit� 1 du chapitre 3)
					if ( PANA.Games.utils.getGameState( 'chapitre_3', 'activite_1' ) ) {
						strPageToLoad = 'chapitre_3:activite_1';
					} else {
						strPageToLoad = 'chapitre_3:video';
					}
				}
				// On recule sur la page de la vid�o du chapitre 5
				else if ( strPageToLoad == 'chapitre_4:activite_2' ) {
					// Anything but false ( On a commenc� ou gagn� l'activit� 1 du chapitre 3)
					if ( PANA.Games.utils.getGameState( 'chapitre_4', 'activite_2' ) ) {
						strPageToLoad = 'chapitre_4:activite_2';
					} else {
						strPageToLoad = 'chapitre_4:activite_2_video';
					}
				}
			}


			PANA.NAV.loadPage( PANA.config.$ajaxContainer, strPageToLoad );
		} );
	},
	loadPage   : function ( $container, strGoto ) {
		var _this = PANA.NAV,
			arrGoto = strGoto.split( ':' ),
			strChapter = arrGoto[ 0 ],
			strPage = arrGoto[ 1 ];

		PANA.debug.console.log( strChapter );


		// Trying to 'unload' any Vimeo videos that might be in memory
		if ( PANA.multimedia.vidInsert.currIframeId != 'undefined' ) {
			if ( $( '#' + PANA.multimedia.vidInsert.currIframeId ).length > 0 ) {
				try {
					$f( PANA.multimedia.vidInsert.currIframeId ).api( 'unload' );
				}
				catch ( e ) {
					console.log( 'Vimeo exception', e );
				}
			}
		}


		if ( $( 'iframe' ).length > 0 ) {
			$( 'iframe' ).attr( 'src', '' ).remove();
		}

		var $activityContainer = $( '.activity' );


		// Memory cleanup for games
		if ( $activityContainer.length > 0 ) {
			var activityType = $activityContainer.data( 'activity-type' );
			if ( typeof activityType !== 'undefined' && activityType != '' ) {
				if ( typeof PANA.Games[ activityType ] != 'undefined' ) {

					if ( typeof PANA.Games[ activityType ][ 'cleanupSounds' ] == 'function' ) {

						PANA.Games[ activityType ].cleanupSounds();
					}

					if ( typeof PANA.Games[ activityType ][ 'cleanUp' ] == 'function' ) {

						PANA.Games[ activityType ].cleanUp();
					}
				}
			}
		}

		if ( PANA.config.currentActivity == 'accueil' ) {
			// Memory cleanup for Avatars page
			PANA.avatars.cleanUp();
		}

		if ( strGoto == 'chapitre_0:accueil' ) {
			PANA.config.$mainLogo.addClass( 'disable-click' );


		} else {
			PANA.config.$mainLogo.removeClass( 'disable-click' );
		}


		PANA.avatars.updateHeaderAvatar( strChapter );

		// Hack because we needed to go outside the AJAX container for one activity
		$( '.bins, .chapter3-act1-nav' ).remove();

		$.ajax( {
				url   : PANA.config.strContentFolder + '/' + strChapter + '/' + strPage + '.html',
				cache : false

			} )
			.done( function ( html ) {

				PANA.config.currentChapter = strChapter;
				PANA.config.currentActivity = strPage;

				PANA.D2L.fireEvent( 'navigating', arrGoto );

				PANA.UTILS.scrollIntoView( $( 'body' ), 0, 200 );

				var currWrapClass = PANA.config.$body.data( 'wrap-class' ),
					strChapterActivityClass = strChapter + '---' + strPage;

				if ( typeof currWrapClass != 'undefined' && currWrapClass != '' ) {
					PANA.config.$body.removeClass( currWrapClass );
				}

				PANA.config.$body.addClass( strChapterActivityClass ).data( 'wrap-class', strChapterActivityClass );


				if ( $( '#main-container' ).find( 'iframe' ).length > 0 ) {
					// Helps clearing memory for Vimeo videos
					$( '#main-container' ).find( 'iframe' ).attr( 'src', '' );
				}

				// Cleanup previous DOM nodes
				// http://stackoverflow.com/a/3955238

				var vanillaJsContainer = document.getElementById( 'main-container' );
				while ( vanillaJsContainer.firstChild ) {
					vanillaJsContainer.removeChild( vanillaJsContainer.firstChild );
				}
				$( '#main-container' ).empty(); // should also clear jQuery.cache

				// Trying to (extra) remove DOM trees and event bindings
				$container.find( '.activity, .activity-container' ).empty().remove();

				$container.html( html );
				var $loadedContentHeader = $container.find( 'header.header-info' ),
					$loadedContentTitle = $loadedContentHeader.find( '.header-title' ),
					$loadedContentInstructions = $loadedContentHeader.find( '.header-instructions' );


				if ( PANA.config.debug && typeof _this.$debugBar == 'undefined' ) {
					_this.$debugBar = $( '#debug_bar' );
					_this.$debugLinks = _this.$debugBar.find( '.debug_list' ).find( 'a' );
				}

				if ( PANA.config.debug ) {
					_this.$debugLinks.removeClass( 'active' );
					_this.$debugLinks.filter( '[data-chapter-link="' + strGoto + '"]' ).addClass( 'active' );
				}

				var htmlHeaderTitle = $loadedContentTitle.length > 0 ? $loadedContentTitle.html() : '',
					htmlHeaderInstructions = $loadedContentInstructions.length > 0 ? $loadedContentInstructions.html() : '',
					htmlHeaderChapter = strChapter != 'chapitre_0' ? 'Chapitre ' + strChapter.replace( 'chapitre_', '' ) : '';

				PANA.config.$headerInfoTitle.html( htmlHeaderTitle );
				PANA.config.$headerInfoInstructions.html( htmlHeaderInstructions );
				PANA.config.$headerInfoChapter.html( htmlHeaderChapter ).show();

				$loadedContentTitle.add( $loadedContentInstructions ).remove();


				// Interrupt / Reset audio
				//PANA.multimedia.audio.unsetPlaying();

				createjs.Sound.removeAllSounds();

				// Play audio?
				PANA.multimedia.audio.mainInstructions.init( false );

				if ( $( '#confettti' ).length > 0 ) {
					PANA.special.confetti.destroy();
				}


				var $activityContainer = $container.find( '.activity' );

				if ( $activityContainer.length > 0 ) {
					var activityType = $activityContainer.data( 'activity-type' );
					if ( typeof activityType !== 'undefined' && activityType != '' ) {
						if ( typeof PANA.Games[ activityType ] != 'undefined' ) {
							PANA.Games[ activityType ].init( arrGoto, strChapter, strPage );
							PANA.debug.console.log( 'Activity: ' + activityType );
						}
					}
				}


				if ( $container.find( '.avatars-display' ).length > 0 ) {
					PANA.avatars.init();
				}


				if ( strPage == 'video' ) {
					PANA.UTILS.scrollIntoView( $( '#main-container' ), 100, 150 );

				}


				PANA.tdm.revealer.init();

				PANA.tdm.markCompleted();

				//PANA.UTILS.minHeightCenter.init();

				PANA.multimedia.vidInsert.init();
			} )
			.fail( function () {
				PANA.debug.console.log( 'Page does not exist' );
				PANA.NAV.loadPage( PANA.config.$ajaxContainer, 'chapitre_0:accueil' );
			} );
	},
	showNav    : function () {
		$( '.navigation' ).find( '.btn-next' )
			.removeClass( 'disable-click' )
			.addClass( 'active' );

		PANA.UTILS.scrollIntoView( $( '.navigation' ), -50, 500 );
	},
	hideNav    : function () {
		$( '.navigation' ).find( '.btn-next' )
			.addClass( 'disable-click' )
			.removeClass( 'active' );
	}
};


