;
PANA.Games.chapitre4activite5 = {
	init : function ( activityName, chapter, activity ) {
		var _this = PANA.Games.chapitre4activite5;

		_this.cleanUp();


		_this.options = {
			variableThreshold : '30%',
			snapTo            : true
		}

		_this.chapter = chapter;
		_this.activity = activity;
		_this.currState = 'normal';

		_this.currStep = 0;
		_this.lastStep = 8;

		_this.failDuration = 500;

		_this.$activityContainer = $( '.activity' );

		_this.$machine = _this.$activityContainer.find( '.machine-container' );
		_this.$pattesContainer = _this.$activityContainer.find( '.pattes-container' );
		_this.$glowLayer = _this.$activityContainer.find( '.glow-layer' );
		_this.$animatableGlow = _this.$glowLayer.find( '#glow_XMLID_16_' );
		_this.glowAnimMin = -50;
		_this.glowAnimMax = 90;

		_this.consigneTexte = '';

		_this.cleanupSounds();
		_this.setupSounds();
		_this.setupDraggables();

		_this.resizeEverything();

		_this.bindEvents();
		_this.switchConsigneText();

		_this.replayAnswers();

	},

	steps                : {
		0 : {
			correct       : 'gouttes',
			winState      : 'state-wet',
			consigne      : 'Clique sur le bon objet et déplace-le pour laver les pattes de l’ourse.<br />Il faut d\'abord les mouiller.',
			audioConsigne : 'chapitre_4_activite_5_consigne_1.mp3'
		},
		1 : {
			correct       : 'savon',
			winState      : 'state-soapy',
			consigne      : 'Clique sur le bon objet pour mettre du savon sur les pattes de l’ourse.',
			audioConsigne : 'chapitre_4_activite_5_consigne_2.mp3'
		},
		2 : {
			correct       : 'gouttes',
			winState      : 'state-wet',
			consigne      : 'Clique sur le bon objet pour rincer les pattes de l’ourse.',
			audioConsigne : 'chapitre_4_activite_5_consigne_3.mp3'
		},
		3 : {
			correct       : 'serviette',
			winState      : 'state-normal',
			consigne      : 'Clique sur le bon objet pour sécher les pattes de l’ourse.',
			audioConsigne : 'chapitre_4_activite_5_consigne_4.mp3'
		},
		4 : {
			correct       : 'machine',
			winState      : 'state-dirty',
			consigne      : 'Appuie sur le bouton pour analyser les pattes et voir s\'il reste de la saleté.',
			audioConsigne : 'chapitre_4_activite_5_consigne_5.mp3'
		},
		5 : {
			correct       : 'gouttes',
			winState      : 'state-wet',
			consigne      : 'Lave de nouveau les pattes de l\'ourse pour les rendre toutes propres.<br /> Clique sur le bon objet et déplace-le pour laver les pattes de l’ourse.',
			audioConsigne : 'chapitre_4_activite_5_consigne_6.mp3'
		},
		6 : {
			correct       : 'savon',
			winState      : 'state-soapy',
			consigne      : 'Lave de nouveau les pattes de l\'ourse pour les rendre toutes propres.<br /> Clique sur le bon objet et déplace-le pour laver les pattes de l’ourse.',
			audioConsigne : 'chapitre_4_activite_5_consigne_6.mp3'
		},
		7 : {
			correct       : 'gouttes',
			winState      : 'state-wet',
			consigne      : 'Lave de nouveau les pattes de l\'ourse pour les rendre toutes propres.<br /> Clique sur le bon objet et déplace-le pour laver les pattes de l’ourse.',
			audioConsigne : 'chapitre_4_activite_5_consigne_6.mp3'
		},
		8 : {
			correct       : 'serviette',
			winState      : 'state-clean',
			consigne      : 'Lave de nouveau les pattes de l\'ourse pour les rendre toutes propres.<br /> Clique sur le bon objet et déplace-le pour laver les pattes de l’ourse.',
			audioConsigne : 'chapitre_4_activite_5_consigne_6.mp3'
		},
	},
	cleanUp         : function () {
		var _this = PANA.Games.chapitre4activite5;

		// Stop the hand glow animation
		_this.glowShouldAimate = false;
		if ( typeof _this.glowCurrentlyAnimating != 'undefined' ) {
			_this.glowCurrentlyAnimating.kill();
		}

		// unbinding
		if ( typeof _this.$draggables != 'undefined' ) {
			Draggable.get( _this.$draggables ).kill();
		}


		PANA.config.$headerInfoInstructionsPlayBtn.off( 'click.interruptPattes' );

		PANA.multimedia.audio.mainInstructions.bindEvents();

		if ( typeof _this.$activityContainer != 'undefined' ) {
			_this.$activityContainer.off( 'click' );
		}


		var keysToClean = [
			'options',
			'currState',
			'sounds',
			'currStep',
			'$animatableGlow',
			'$draggables',
			'$draggablesContainer',
			'$droppables',
			'$glowLayer',
			'$machine',
			'$pattesContainer',
			'consigneTexte',
			'failDuration',
			'glowAnimMax',
			'glowAnimMin',
			'lastStep',

			'$activityContainer',
		];

		PANA.UTILS.clearFromMemory( _this, keysToClean );
	},
	bindEvents      : function () {
		var _this = PANA.Games.chapitre4activite5;

		PANA.multimedia.audio.mainInstructions.unbindEvents();

		_this.$activityContainer.on( 'click', '.chap4act5-btn-audio', function ( e ) {
			e.preventDefault();

			var $this = $( this ),
				audioId = $this.parents( '.draggable' ).data( 'object' );

			if ( _this.sounds.info[ audioId ] ) {
				createjs.Sound.stop();
				createjs.Sound.play( _this.sounds.info[ audioId ].id );
			}
		} );


		_this.$activityContainer.one( 'click', '.boutons-machine', function () {
			_this.saveAnswer( 'machine' );

			_this.machineAnimation();


			PANA.config.$headerInfoInstructionsPlayBtn.hide();

		} );


		// Debounced so that the browser doesn't go crazy during resize
		var debouncedResize = PANA.UTILS.debounce( function () {
			_this.resizeEverything();
		}, 250 );
		window.addEventListener( 'resize', debouncedResize );

	},
	setupSounds     : function () {
		var _this = PANA.Games.chapitre4activite5;

		_this.sounds = {};

		var soundGeneralWin = _this.$activityContainer.data( 'sound-win' ) || false,
			soundGeneralFail = _this.$activityContainer.data( 'sound-fail' ) || false,
			soundGeneralSuccess = _this.$activityContainer.data( 'sound-success' ) || false;

		_this.sounds.win = !soundGeneralWin ? false : createjs.Sound.registerSound( soundGeneralWin, _this.chapter + '_' + _this.activity + '_' + 'win', 0, PANA.config.strAudioFolder );
		_this.sounds.fail = !soundGeneralFail ? false : createjs.Sound.registerSound( soundGeneralFail, _this.chapter + '_' + _this.activity + '_' + 'fail', 0, PANA.config.strAudioFolder );
		_this.sounds.success = !soundGeneralSuccess ? false : createjs.Sound.registerSound( soundGeneralSuccess, _this.chapter + '_' + _this.activity + '_' + 'success', 0, PANA.config.strAudioFolder );

		_this.sounds.info = {};


		_this.$activityContainer.find( '.draggable' ).each( function () {
			var $this = $( this ),
				objectId = $this.data( 'object' ),
				objectSound = $this.data( 'audio' ) || false;


			_this.sounds.info[ objectId ] = !objectSound ? false : createjs.Sound.registerSound( objectSound, _this.chapter + '_' + _this.activity + '_' + objectId, 0, PANA.config.strAudioFolder );
		} );

		_this.sounds.machine = createjs.Sound.registerSound( 'chapitre_4_activite_5_machine.mp3', _this.chapter + '_' + _this.activity + '_' + 'machine', 0, PANA.config.strAudioFolder );


		//_this.sounds.consignes = {};
		//$.each( _this.steps, function ( i, val ) {
		//	_this.sounds.consignes[ i ] = createjs.Sound.registerSound( this.audioConsigne, _this.chapter + '_' + _this.activity + '_' + 'cosnigne_' + i, 0, PANA.config.strAudioFolder );
		//
		//} );

	},
	cleanupSounds   : function () {
		var _this = PANA.Games.chapitre4activite5;


	},
	setupDraggables : function () {
		var _this = PANA.Games.chapitre4activite5;

		_this.$draggablesContainer = _this.$activityContainer.find( '.draggables-container' );
		_this.$draggables = _this.$draggablesContainer.find( '.draggable' );
		_this.$droppables = _this.$activityContainer.find( '.pattes-container' );


		Draggable.create( _this.$draggables, {
			type      : "top,left",
			onPress   : function () {
				StartX = this.x;
				StartY = this.y;
			},
			onRelease : function () {
				var $draggable = $( this.target ),
					_draggable = this,
					isMatch = false,
					$matchingDroppable,
					$attemptedDroppable;

				// Draggable's hitTest checks if it overlaps based on the threshold set
				// More info at http://greensock.com/docs/#/HTML5/GSAP/Utils/Draggable/hitTest/
				_this.$droppables.each( function () {

					// If they overlap AND aren't disabled
					if ( _draggable.hitTest( $( this ), _this.options.variableThreshold ) && !$( this ).hasClass( 'disabled' ) ) {

						// Setting this aside
						$attemptedDroppable = $( this );

						if ( _this.compareDragDrop( $draggable ) ) {
							isMatch = true;
							$matchingDroppable = $( this );
						}

					}

				} );

				if ( isMatch ) {
					// Yay! Win!
					_this.showSuccess( $draggable, $matchingDroppable );
					_this.saveAnswer( $draggable.data( 'object' ) );


					//_this.checkIsDone();

				} else {

					// we ALWAYS want to revert the draggable to its original position
					_this.revertDraggable( $draggable );

					// but it doesn't mean that it actually hovered over a droppable
					if ( $attemptedDroppable ) {
						_this.showFail( $draggable );
						_this.checkIsDone();
					}
				}


			} // onRelease
		} );

	},
	compareDragDrop : function ( $draggable ) {
		var _this = PANA.Games.chapitre4activite5;

		var draggableId = $draggable.data( 'object' ),
			droppableId = _this.steps[ _this.currStep ].correct;

		return draggableId == droppableId;
	},
	revertDraggable : function ( $draggable ) {
		var _this = PANA.Games.chapitre4activite5;


		TweenLite.to( $draggable, 0.5, { left : 0, top : 0, ease : 'Elastic' } );
	},
	showSuccess     : function ( $draggable ) {
		var _this = PANA.Games.chapitre4activite5;

		if ( _this.options.snapTo ) {
			if ( typeof $draggable != 'undefined' ) {
				_this.revertDraggable( $draggable );
			}

			if ( _this.currStep == 3 ) {

				// Sepcial step for the machine
				if ( _this.currState != 'replaying' ) {
					_this.$machine.fadeIn( function () {

						// If ever we need it to auto start the scanning, turn this back on
						//setTimeout(function() {
						//	_this.$activityContainer.find('.boutons-machine' ).trigger('click');
						//}, 250);
					} );
					_this.$draggablesContainer.fadeOut();

				} else {
					_this.$machine.show();
					_this.$draggablesContainer.hide();
				}

			} else {
				// All other steps
				if ( _this.currState != 'replaying' ) {
					_this.$machine.fadeOut();
					_this.$draggablesContainer.fadeIn();
				} else {
					_this.$machine.hide();
					_this.$draggablesContainer.show();
				}
			}

			_this.$pattesContainer.removeClass( 'state-normal state-wet state-soapy state-dirty' ).addClass( _this.steps[ _this.currStep ].winState );


			if ( _this.sounds.success && _this.currState != 'replaying' && _this.currStep != 3 ) {
				//_this.sounds.success.play();

				createjs.Sound.stop();
				createjs.Sound.play( _this.sounds.success.id );

			}

			_this.currStep++;
			_this.checkIsDone();

		}

	},
	showFail        : function ( $draggable ) {
		var _this = PANA.Games.chapitre4activite5;

		var $failIcon = $( '<img class="gen-fail-x" alt="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKIAAACiAQMAAAD4PIRYAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAABpJREFUeNrtwQENAAAAwqD3T20PBxQAAABvBg3sAAHckWr/AAAAAElFTkSuQmCC">' ).appendTo( $draggable );

		setTimeout( function () {
			$failIcon.fadeOut( 'slow', function () {
				$( this ).remove();
			} );
		}, _this.failDuration )


		if ( _this.sounds.fail && _this.currState != 'replaying' ) {
			//_this.sounds.fail.pos( 0 ).play();
			createjs.Sound.stop();
			createjs.Sound.play( _this.sounds.fail.id );

			PANA.D2L.fireEvent( 'mauvaise réponse', _this.chapter + ':' + _this.activity );
		}
	},
	checkIsDone     : function () {
		var _this = PANA.Games.chapitre4activite5;

		if ( _this.currStep == ( _this.lastStep + 1) ) {
			PANA.Games.utils.setGameState( _this.chapter, _this.activity, 'win' );
			_this.endAnimation();

			_this.$draggables.each( function () {
				Draggable.get( $( this ) ).disable();
			} );


			if ( _this.currState != 'replaying' ) {
				PANA.D2L.fireEvent( 'gagné', _this.chapter + ':' + _this.activity );
			}

			if ( _this.sounds.win && _this.currState != 'replaying' ) {
				//_this.sounds.win.play();

				createjs.Sound.stop();
				createjs.Sound.play( _this.sounds.win.id );


			}
			PANA.NAV.showNav();
		} else {
			PANA.Games.utils.setGameState( _this.chapter, _this.activity, 'begun' );
			_this.switchConsigneText();
		}
	},
	saveAnswer      : function ( objectId ) {
		var _this = PANA.Games.chapitre4activite5;

		PANA.Games.utils.saveAnswer( _this.chapter, _this.activity, objectId );

	},
	replayAnswers   : function () {
		var _this = PANA.Games.chapitre4activite5;

		var state = PANA.Games.utils.getGameState( _this.chapter, _this.activity ),
			answers;

		if ( state ) {
			answers = PANA.Games.utils.getAnswers( _this.chapter, _this.activity );
		}

		if ( answers ) {
			_this.currState = 'replaying';
			for ( var i = 0; i < answers.length; i++ ) {
				var objetId = answers[ i ];

				if ( objetId == 'machine' ) {

					_this.showSuccess();
					//_this.currStep++;

				} else {
					var $draggable = _this.$draggables.filter( '[data-object="' + objetId + '"]' ),
						$droppable = _this.$droppables;


					_this.showSuccess( $draggable, $droppable );
				}

			}
			_this.currState = 'normal';
		}
	},
	switchConsigneText : function () {
		var _this = PANA.Games.chapitre4activite5;

		if ( _this.steps[ _this.currStep ].consigne != _this.consigneTexte ) {
			_this.consigneTexte = _this.steps[ _this.currStep ].consigne;
			PANA.config.$headerInfoInstructions.html( _this.consigneTexte );
		}
		PANA.multimedia.audio.mainInstructions.init( false, _this.steps[ _this.currStep ].audioConsigne );

	},
	endAnimation       : function () {
		var _this = PANA.Games.chapitre4activite5;


	},
	machineAnimation   : function () {
		var _this = PANA.Games.chapitre4activite5;

		createjs.Sound.stop();
		var soundInstance = createjs.Sound.play( _this.sounds.machine.id );
		_this.$machine.removeClass( 'normal' ).addClass( 'machine-on' );
		_this.$pattesContainer.removeClass( 'state-normal' ).addClass( 'state-machine' );
		_this.$glowLayer.find( 'svg' ).show();

		_this.glowShouldAimate = true;

		_this.machineGlowTweenTo();

		_this.currStep++;

		// If someone listened to the instructions during the animation, this callback would never happen
		PANA.config.$headerInfoInstructionsPlayBtn.on( 'click.interruptPattes', function ( e ) {
			_this.machineAnimationEnd();
		} );

		soundInstance.on( 'complete', function () {

			_this.machineAnimationEnd();
		} );

	},
	machineGlowTweenTo : function () {
		var _this = PANA.Games.chapitre4activite5;
		if ( _this.glowShouldAimate ) {
			_this.glowCurrentlyAnimating = TweenLite.fromTo( _this.$animatableGlow, 1, { y : _this.glowAnimMax }, {
				y          : _this.glowAnimMin, ease : Power0.easeNone,
				onComplete : _this.machineGlowTweenFrom
			} );
		}
	},
	machineGlowTweenFrom : function () {
		var _this = PANA.Games.chapitre4activite5;
		if ( _this.glowShouldAimate ) {
			_this.glowCurrentlyAnimating = TweenLite.fromTo( _this.$animatableGlow, 1, { y : _this.glowAnimMin }, {
					y : _this.glowAnimMax, ease : Power0.easeNone,
					onComplete                  : _this.machineGlowTweenTo
				}
			);
		}
	},
	machineAnimationEnd  : function () {
		var _this = PANA.Games.chapitre4activite5;

		_this.$glowLayer.fadeOut( function () {
			_this.$machine.fadeOut( function () {

				_this.glowShouldAimate = false;

				_this.$draggablesContainer.fadeIn();

				_this.$pattesContainer.removeClass( 'state-machine' ).addClass( 'state-dirty' );

				_this.switchConsigneText();

				// If someone listened to the instructions during the animation, this callback would never happen
				PANA.config.$headerInfoInstructionsPlayBtn.off( 'click.interruptPattes' );
				PANA.config.$headerInfoInstructionsPlayBtn.show();
			} );
		} );
	},
	resizeEverything     : function () {
		var _this = PANA.Games.chapitre4activite5;

		var pattesContainerWidth = 730,
			pattesContainerHeight = 536,
			draggablesWidth = 710,
			draggablesHeight = 240,
			draggablesHeightRatio = 250 / 1080,
			machineWidth = 930,
			machineHeight = 1030,
			windowWidth = $( window ).width(),
			windowHeight = $( window ).height();

		if ( (windowWidth * 0.8) < pattesContainerWidth ) {
			PANA.debug.console.log( 'Pattes: resize horizontal' );
			_this.$pattesContainer.css( {
				width  : windowWidth * 0.8,
				height : windowWidth * 0.8 / pattesContainerWidth * pattesContainerHeight
			} );
		} else if ( (windowHeight / 2) < pattesContainerHeight ) {
			PANA.debug.console.log( 'Pattes: resize vertical' );

			_this.$pattesContainer.css( {
				width  : windowHeight / 2 / pattesContainerHeight * pattesContainerWidth,
				height : windowHeight / 2
			} );
		} else {
			PANA.debug.console.log( 'Pattes: normal' );
			_this.$pattesContainer.css( {
				width  : pattesContainerWidth,
				height : pattesContainerHeight
			} );
		}

		if ( draggablesWidth > ( windowWidth * 0.8 ) ) {
			PANA.debug.console.log( 'Draggables: resize horizontal', windowHeight, _this.$pattesContainer.offset().top, windowHeight - _this.$pattesContainer.offset().top );
			_this.$draggablesContainer.css( {
				width  : windowWidth * 0.8,
				height : windowWidth * 0.8 / draggablesWidth * draggablesHeight,
				top    : windowHeight - _this.$pattesContainer.height() - ( windowWidth * 0.8 / draggablesWidth * draggablesHeight ) - 30
			} )
		} else if ( draggablesHeight > windowHeight * draggablesHeightRatio ) {
			PANA.debug.console.log( 'Draggables: resize vertical', windowHeight - _this.$pattesContainer.offset().top );
			_this.$draggablesContainer.css( {
				width  : windowHeight * draggablesHeightRatio / draggablesHeight * draggablesWidth,
				height : windowHeight * draggablesHeightRatio,
				top    : windowHeight - _this.$pattesContainer.height() - ( windowHeight * draggablesHeightRatio ) - 30
			} )
		} else {
			PANA.debug.console.log( 'Draggables: normal' );
			_this.$draggablesContainer.css( {
				width  : draggablesWidth,
				height : draggablesHeight,
				top    : windowHeight - _this.$pattesContainer.offset().top - draggablesHeight - 30
			} )
		}

		PANA.debug.console.log( windowHeight - _this.$pattesContainer.offset().top, ( _this.$pattesContainer.height() * machineHeight / pattesContainerHeight / 820 * 230) );
		_this.$machine.css( {
			width  : _this.$pattesContainer.width() * machineWidth / pattesContainerWidth,
			height : _this.$pattesContainer.height() * machineHeight / pattesContainerHeight,
			top    : windowHeight - _this.$pattesContainer.height() - ( _this.$pattesContainer.height() * machineHeight / pattesContainerHeight ) + ( _this.$pattesContainer.height() * machineHeight / pattesContainerHeight / 1030 * 430)
		} );


		PANA.debug.console.log( '_this.$machine.offset().top', _this.$machine.offset().top, _this.$machine.find( '.machine' ), '_this.$machine.find(.machine ).height() ', _this.$machine.find( '.machine' ).height() );


	}
}