;
if ( typeof PANA.D2L == 'undefined' ) {
	PANA.D2L = {};
}


/*
 * Fires an event that has multiple params
 *
 */
PANA.D2L.fireEvent = function ( eventType, dataToPass ) {
	PANA.D2L.logEntry( eventType );

	PANA.config.$D2Lparent.trigger( 'pana-event', {
		eventType        : eventType,
		dataToPass       : dataToPass,
		genericD2Lobject : PANA.D2L.prepObject(),
		log              : PANA.D2L.log
	} );
}

PANA.D2L.prepObject = function () {
	return {
		chapters        : PANA.chapters,
		chosenAvatar    : PANA.config.selectedAvatar,
		currentChapter  : PANA.config.currentChapter,
		currentActivity : PANA.config.currentActivity,
	}
}

PANA.D2L.continueGame = function ( avatar, answers, lastChapter, lastActivity ) {
	PANA.Games.utils.loadGame( answers );
	PANA.config.selectedAvatar = avatar;
	PANA.NAV.loadPage( PANA.NAV.loadPage( PANA.config.$ajaxContainer, lastChapter + ':' + lastActivity ) );
	console.log( 'Successfully loaded previous game' );
}

PANA.D2L.resetGame = function () {
	PANA.chapters = PANA.defaultChapters;
	PANA.config.selectedAvatar = false;
	PANA.NAV.loadPage( PANA.NAV.loadPage( PANA.config.$ajaxContainer, 'chapitre_0:accueil' ) );
	console.log( 'Successfully reset everything' );
}


PANA.D2L.log = [];
PANA.D2L.logEntry = function ( entryText ) {
	PANA.D2L.log.push( {
		time     : new Date(),
		chapter  : PANA.config.currentChapter,
		activity : PANA.config.currentActivity,
		content  : entryText
	} );
}