/**
 * Created by Michal on 2015-08-04.
 */
PANA.debug = {};

PANA.debug.addMenu = function () {

	var $footer = $( '#footer-main' ),
		$footerContainer = $footer.find( '.footer-container' );

	$.ajax( {
		url : PANA.config.strContentFolder + '/chapitre_0/debug-menu.html'
	} ).done( function ( data ) {
		$footerContainer.append( data );
	} );

}

PANA.debug.detectMute = function () {
	if ( location.hash.indexOf( 'mute' ) != -1 ) {
		PANA.config.mute = true;
		PANA.debug.console.log( 'Muting' );
	} else {
		PANA.debug.console.log( 'No Muting' );
	}
}

// Turn on debugging based on querystring
PANA.debug.areWeDebugging = function () {
	if ( PANA.config.debug == true ) {
		return;
	}
	PANA.config.debug = $.inArray( PANA.UTILS.getQueryVars( 'debug' ), [ 'true', true, 1 ] ) != -1;
}


PANA.debug.console = {
	prepArguments : function () {
		var _this = PANA.debug.console,
			arrArguments = [];
		for ( var i = 0; i < arguments.length; i++ ) {
			arrArguments.push( arguments[ i ] );
		}

		return arrArguments;
	},
	debug         : function () {
		if ( PANA.config.debug === true ) {
			for ( var i = 0; i < arguments.length; i++ ) {
				console.debug( arguments[ i ] );
			}
		}
	},
	error         : function () {
		if ( PANA.config.debug === true ) {
			for ( var i = 0; i < arguments.length; i++ ) {
				console.error( arguments[ i ] );
			}
		}
	},
	log           : function () {
		if ( PANA.config.debug === true ) {
			for ( var i = 0; i < arguments.length; i++ ) {
				console.log( arguments[ i ] );
			}
		}
	},
	table         : function () {
		if ( PANA.config.debug === true ) {
			for ( var i = 0; i < arguments.length; i++ ) {
				console.table( arguments[ i ] );
			}
		}
	},
}