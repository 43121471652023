;
PANA.UTILS = {
	debounce        : function ( func, wait, immediate ) {
		var timeout;
		return function () {
			var context = this, args = arguments;
			var later = function () {
				timeout = null;
				if ( !immediate ) func.apply( context, args );
			};
			var callNow = immediate && !timeout;
			clearTimeout( timeout );
			timeout = setTimeout( later, wait );
			if ( callNow ) func.apply( context, args );
		}
	},
	/* Open all extrenal links in target: _blank */
	openExternal    : {
		init       : function () {
			var _this = PANA.UTILS.openExternal;
			_this.bindEvents();
		},
		bindEvents : function () {
			$( 'body' ).on( 'click', 'a', function ( e ) {
				if ( this.hostname && this.hostname !== location.hostname ) {
					e.preventDefault();
					e.stopPropagation();
					window.open( this.href, '_blank' );
				}
			} );
		}
	},
	minHeightCenter : {
		init          : function () {
			var _this = PANA.UTILS.minHeightCenter;

			if ( typeof _this.$centerContainer == 'undefined' ) {
				_this.$centerContainer = $( '#main-container' );
				_this.$header = $( '#header-main' );
				_this.$footer = $( '#footer-main' );
			}

			_this.recalcHeights();
			_this.bindEvents();
		},
		recalcHeights : function () {
			var _this = PANA.UTILS.minHeightCenter;

			var windowHeight = $( window ).outerHeight(),
				headerHeight = _this.$header.outerHeight(),
				footerHeight = _this.$footer.outerHeight(),
				leftover = windowHeight - headerHeight - footerHeight,
				mainContainerHeight;

			_this.$centerContainer.css( {
				'height'     : 'auto',
				'min-height' : 0
			} );
			mainContainerHeight = _this.$centerContainer.outerHeight();

			if ( leftover > mainContainerHeight ) {
				_this.$centerContainer.find( ' > article' ).add( _this.$centerContainer ).css( 'min-height', leftover );
			}


		},
		bindEvents    : function () {
			var _this = PANA.UTILS.minHeightCenter;

			// Debounced so that the browser doesn't go crazy during resize
			var debouncedRecalc = PANA.UTILS.debounce( function () {
				_this.recalcHeights();
			}, 250 );
			window.addEventListener( 'resize', debouncedRecalc );
		}
	}
}


PANA.UTILS.scrollIntoView = function ( $selector, iOffset, iSpeedMs ) {
	if ( $selector.length > 0 ) {

		var iOffsetToUse = iOffset ? iOffset : 0,
			iSpeedToUse = iSpeedMs ? iSpeedMs : 1000,
			iWhereTo = $selector.offset().top - $( window ).height() + $selector.outerHeight() - iOffsetToUse;


		$( 'html:not(:animated), body:not(:animated)' ).animate( {
			scrollTop : iWhereTo
		}, iSpeedToUse );
	}
};


PANA.UTILS.areWeMobile = function () {
	return $( '.mobile-checker' ).is( ':visible' );
}

PANA.UTILS.getQueryVars = function ( queryVar ) {
	var query = window.location.search.substring( 1 );
	var vars = query.split( "&" );
	for ( var i = 0; i < vars.length; i++ ) {
		var pair = vars[ i ].split( "=" );
		if ( pair[ 0 ] == queryVar ) {
			return pair[ 1 ];
		}
	}
	return (false);
}


PANA.UTILS.fisherShuffle = function ( array ) {
	// http://stackoverflow.com/a/2450976
	var currentIndex = array.length, temporaryValue, randomIndex;

	// While there remain elements to shuffle...
	while ( 0 !== currentIndex ) {

		// Pick a remaining element...
		randomIndex = Math.floor( Math.random() * currentIndex );
		currentIndex -= 1;

		// And swap it with the current element.
		temporaryValue = array[ currentIndex ];
		array[ currentIndex ] = array[ randomIndex ];
		array[ randomIndex ] = temporaryValue;
	}

	return array;
}

PANA.UTILS.lpad = function ( number, desiredLength, paddingChar ) {
	// From http://stackoverflow.com/a/10073788/636709
	paddingChar = paddingChar || '0';
	number = number + '';
	return number.length >= desiredLength ? number : new Array( desiredLength - number.length + 1 ).join( paddingChar ) + number;
}

PANA.UTILS.formatFrenchMoney = function ( amount ) {
	var strAmount = amount.toFixed( 2 ) + '',
		arrSplit = strAmount.split( '.' ),
		dec = typeof arrSplit[ 1 ] == 'undefined' ? 0 : arrSplit[ 1 ];

	return arrSplit[ 0 ] + ',' + PANA.UTILS.lpad( dec, 2 ) + '&nbsp;$';
}


PANA.UTILS.requestAnimationFramePolyfill = function () {
	var lastTime = 0;
	var vendors = [ 'webkit', 'moz' ];
	for ( var x = 0; x < vendors.length && !window.requestAnimationFrame; ++x ) {
		window.requestAnimationFrame = window[ vendors[ x ] + 'RequestAnimationFrame' ];
		window.cancelAnimationFrame =
			window[ vendors[ x ] + 'CancelAnimationFrame' ] || window[ vendors[ x ] + 'CancelRequestAnimationFrame' ];
	}

	if ( !window.requestAnimationFrame )
		window.requestAnimationFrame = function ( callback, element ) {
			var currTime = new Date().getTime();
			var timeToCall = Math.max( 0, 16 - (currTime - lastTime) );
			var id = window.setTimeout( function () {
					callback( currTime + timeToCall );
				},
				timeToCall );
			lastTime = currTime + timeToCall;
			return id;
		};

	if ( !window.cancelAnimationFrame )
		window.cancelAnimationFrame = function ( id ) {
			clearTimeout( id );
		};

}

PANA.UTILS.hasFlash = function () {
	// Based on http://stackoverflow.com/a/20095467
	var hasFlash = false;
	try {
		var fo = new ActiveXObject( 'ShockwaveFlash.ShockwaveFlash' );
		if ( fo ) {
			hasFlash = true;
		}
	} catch ( e ) {
		if ( navigator.mimeTypes
			&& navigator.mimeTypes[ 'application/x-shockwave-flash' ] != undefined
			&& navigator.mimeTypes[ 'application/x-shockwave-flash' ].enabledPlugin ) {
			hasFlash = true;
		}
	}

	return hasFlash;
}

PANA.UTILS.clearFromMemory = function ( mainObject, arrKeys ) {
	for ( var i = 0; i < arrKeys.length; i++ ) {

		if ( typeof mainObject[ arrKeys[ i ] ] != 'undefined' && mainObject[ arrKeys[ i ] ] != null ) {
			mainObject[ arrKeys[ i ] ] = null;
			delete mainObject[ arrKeys[ i ] ];
		}

	}
}