;

"use strict";

if ( typeof PANA === 'undefined' ) {
	PANA = {};
}


window.addEventListener( "load", function () {
	window. scrollTo( 0, 0 );
} );

PANA.Games = {};


PANA.init = function () {
	$( 'body' ).removeClass( 'no-js' );

	if ( Function( '/*@cc_on return document.documentMode===10@*/' )() ) {
		$( 'body' ).addClass( 'ie10' );
	}

	// uses https://github.com/kmewhort/pointer_events_polyfill
	// Mainly for Chap5:Act1... otherwise IE10 and down don't let the user click through
	PointerEventsPolyfill.initialize( {} );


	//if (PANA.config.debug) {
	$.ajaxSetup( {
		cache : true
	} );
	//}

	// Helpful for when you're tired of listening to the audio for the millionth time
	PANA.debug.detectMute();
	PANA.debug.areWeDebugging();

	// Getting rid of the 300ms delay on mobile
	// https://github.com/ftlabs/fastclick
	FastClick.attach( document.body );

	// Make sure all external links open in external tabs
	PANA.UTILS.openExternal.init();

	PANA.D2L.fireEvent( 'arrived on site' );

	PANA.UTILS.requestAnimationFramePolyfill();

	// Initalize the site navigation
	PANA.NAV.init();


	if ( PANA.config.debug == true ) {
		PANA.debug.addMenu();
	}


	PANA.config.defaultSoundJSprops = new createjs.PlayPropsConfig().set( {
		interrupt : createjs.Sound.INTERRUPT_ANY,
		loop      : false,
		offset    : 0,
		volume    : 1
	} );
	createjs.FlashAudioPlugin.swfPath = "../vendors/soundJS/src/soundjs/flashaudio";
	if ( navigator.userAgent.toLowerCase().indexOf( 'firefox' ) > -1 ) {
		// in version 0.6.1, Firefox had issues with the webaudio plugin
		createjs.Sound.registerPlugins( [ /*createjs.WebAudioPlugin, */createjs.HTMLAudioPlugin, createjs.FlashAudioPlugin ] );
	} else {
		createjs.Sound.registerPlugins( [ createjs.WebAudioPlugin, createjs.HTMLAudioPlugin, createjs.FlashAudioPlugin ] );
	}

	if ( PANA.config.debug == true ) {
		$( document ).on( 'pana-event', function ( e, data ) {
			console.log( 'Pana Event: ', e, data );
		} );
	}

	var pageToJumpTo = PANA.UTILS.getQueryVars( 'page' );

	pageToJumpTo = pageToJumpTo ? pageToJumpTo.replace( '%3A', ':' ) : false;
	PANA.debug.console.log( 'pageToJumpTo', pageToJumpTo );
	// Load the default page
	if ( pageToJumpTo ) {
		PANA.NAV.loadPage( PANA.config.$ajaxContainer, pageToJumpTo );
	} else if ( PANA.config.override_start_page != '' && PANA.config.debug === true ) {
		PANA.NAV.loadPage( PANA.config.$ajaxContainer, PANA.config.override_start_page );
	} else {
		PANA.NAV.loadPage( PANA.config.$ajaxContainer, PANA.config.strDefaultChapterPage );
	}


	$( 'body' ).one( 'touchstart click', function () {
		var body = document.documentElement;
		if ( body.requestFullscreen ) {
			body.requestFullscreen();
		} else if ( body.webkitrequestFullscreen ) {
			body.webkitrequestFullscreen();
		} else if ( body.mozrequestFullscreen ) {
			body.mozrequestFullscreen();
		} else if ( body.msrequestFullscreen ) {
			body.msrequestFullscreen();
		}

	} );

//	PANA.layout.init();
};