;
PANA.Games.chapitre3activite1 = {
	init            : function ( activityName, chapter, activity ) {
		var _this = PANA.Games.chapitre3activite1;

		_this.cleanUp();

		_this.options = {
			variableThreshold : '30%',
			snapTo            : true
		}

		_this.chapter = chapter;
		_this.activity = activity;
		_this.currState = 'normal';

		_this.$activityContainer = $( '.activity' );

		_this.$animals = _this.$activityContainer.find( '.animal-container' );
		_this.$animalsNormal = _this.$animals.filter( '.animal-normal' );
		_this.$animalsHappy = _this.$animals.filter( '.animal-happy' );

		_this.$bins = $( '.bins' );
		_this.$bins.appendTo( PANA.config.$pageWrapper );
		_this.$nav = $( '.chapter3-act1-nav' );
		_this.$nav.appendTo( PANA.config.$footer );

		_this.setupSounds();
		_this.setupDraggables();

		_this.replayAnswers();

	},
	cleanUp         : function () {
		var _this = PANA.Games.chapitre3activite1;

		if ( typeof  _this.$draggables != 'undefined' ) {
			Draggable.get( _this.$draggables ).kill();
		}

		var keysToClean = [
			'options',
			'currState',
			'sounds',
			'$animals',
			'$animalsNormal',
			'$animalsHappy',
			'$bins',
			'$nav',
			'$nav',
			'$draggables',
			'$droppables',
			'$activityContainer',
		];
		PANA.UTILS.clearFromMemory( _this, keysToClean );

	},
	setupSounds     : function () {
		var _this = PANA.Games.chapitre3activite1;

		if ( typeof _this.sounds !== 'undefined' ) {
			delete _this.sounds;
		}

		_this.sounds = {};

		var soundGeneralWin = _this.$activityContainer.data( 'sound-win' ) || false,
			soundGeneralFail = _this.$activityContainer.data( 'sound-fail' ) || false,
			soundGeneralSuccess = _this.$activityContainer.data( 'sound-success' ) || false;


		_this.sounds.win = !soundGeneralWin ? false : createjs.Sound.registerSound( soundGeneralWin, _this.chapter + '_' + _this.activity + '_' + 'win', 0, PANA.config.strAudioFolder, PANA.config.defaultSoundJSprops );
		_this.sounds.fail = !soundGeneralFail ? false : createjs.Sound.registerSound( soundGeneralFail, _this.chapter + '_' + _this.activity + '_' + 'fail', 0, PANA.config.strAudioFolder, PANA.config.defaultSoundJSprops );
		_this.sounds.success = !soundGeneralSuccess ? false : createjs.Sound.registerSound( soundGeneralSuccess, _this.chapter + '_' + _this.activity + '_' + 'success', 0, PANA.config.strAudioFolder, PANA.config.defaultSoundJSprops );

		_this.sounds.animals = {};

		_this.$activityContainer.find( '.activity-draggable' ).each( function () {
			var $this = $( this ),
				animalId = $this.data( 'animal' ),
				audioFile = $this.data( 'success-audio' );

			_this.sounds.animals[ animalId ] = !audioFile ? false : createjs.Sound.registerSound( audioFile, _this.chapter + '_' + _this.activity + '_' + animalId + ' _success', 0, PANA.config.strAudioFolder, PANA.config.defaultSoundJSprops );
		} );


	},
	setupDraggables : function () {
		var _this = PANA.Games.chapitre3activite1;

		_this.$draggables = _this.$activityContainer.find( '.activity-draggable' ).find( 'img' );
		_this.$droppables = $( '.activity-droppable' );


		Draggable.create( _this.$draggables, {
			type        : "top,left",
			onDragStart : function () {
				var $draggable = $( this.target ),
					$draggableParent = $draggable.parents( '.activity-draggable' ),
					_draggable = this,
					matchingAnimal = $draggableParent.data( 'animal' );

				_this.$activityContainer.find( '.animal-' + matchingAnimal ).find( '.animal-normal' ).fadeIn();

			},
			onRelease   : function () {
				var $draggable = $( this.target ),
					$draggableParent = $draggable.parents( '.activity-draggable' ),
					_draggable = this,
					matchingAnimal = $draggableParent.data( 'animal' ),
					isMatch = false,
					$matchingDroppable,
					$attemptedDroppable,
					draggableIndex = _this.$draggables.index( $draggable );

				// Draggable's hitTest checks if it overlaps based on the threshold set
				// More info at http://greensock.com/docs/#/HTML5/GSAP/Utils/Draggable/hitTest/
				_this.$droppables.each( function () {

					// If they overlap AND aren't disabled
					if ( _draggable.hitTest( $( this ), _this.options.variableThreshold ) && !$( this ).hasClass( 'disabled' ) ) {

						// Setting this aside
						$attemptedDroppable = $( this );

						if ( _this.compareDragDrop( $draggableParent, $( this ) ) ) {
							isMatch = true;
							$matchingDroppable = $( this );
						}

						_this.saveAnswer( draggableIndex, _this.$droppables.index( $attemptedDroppable ) );
					}

				} );

				if ( isMatch ) {
					// Yay! Win!
					_this.showSuccess( $draggable, $matchingDroppable, matchingAnimal );
					//_this.checkIsDone();

				} else {

					// we ALWAYS want to revert the draggable to its original position
					_this.revertDraggable( $draggable );
					_this.$activityContainer.find( '.animal-' + matchingAnimal ).find( '.animal-normal' ).fadeOut( 'fast' );

					// but it doesn't mean that it actually hovered over a droppable
					if ( $attemptedDroppable ) {
						_this.showFail( $draggable, $attemptedDroppable );
						_this.checkIsDone();
					}
				}


			} // onRelease
		} );

	},
	compareDragDrop : function ( $draggable, $droppable ) {
		var draggableId = $draggable.data( 'draggable-id' ),
			droppableId = $droppable.data( 'droppable-id' );

		return draggableId == droppableId;
	},
	revertDraggable : function ( $draggable ) {
		TweenLite.to( $draggable, 0.5, { left : 0, top : 0, ease : 'Elastic' } );
	},
	showSuccess     : function ( $draggable, $droppable, animal ) {
		var _this = PANA.Games.chapitre3activite1;

		if ( _this.options.snapTo ) {
			var $matchingAnimal = _this.$activityContainer.find( '.animal-' + animal ).find( '.animal-normal' );

			// hiding object sprite
			TweenLite.to( $draggable, 0.5, {
				opacity    : 0,
				onComplete : function () {


					$draggable.css( {
						left    : 0,
						top     : 0,
						opacity : 0
					} );


				}
			} );

			// Need to get the instance of the Draggable object rather than the jQuery object.
			Draggable.get( $draggable ).disable();

			$matchingAnimal.fadeIn();


			$draggable.addClass( 'success' );

			if ( _this.currState != 'replaying' ) {

				if ( _this.sounds.animals[ animal ] ) {
					createjs.Sound.stop();
					createjs.Sound.play( _this.sounds.animals[ animal ].id );
				} else if ( _this.sounds.success ) {
					createjs.Sound.stop();
					createjs.Sound.play( _this.sounds.success.id );

				}

				PANA.D2L.fireEvent( 'bonne réponse', _this.chapter + ':' + _this.activity );
			}

			_this.checkIsDone();

		}

	},
	showFail        : function ( $draggable, $attemptedDroppable ) {
		var _this = PANA.Games.chapitre3activite1;

		if ( _this.currState != 'replaying' ) {
			var $failIcon = $( '<img class="gen-fail-x" alt="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKIAAACiAQMAAAD4PIRYAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAABpJREFUeNrtwQENAAAAwqD3T20PBxQAAABvBg3sAAHckWr/AAAAAElFTkSuQmCC">' ).appendTo( $attemptedDroppable );


			setTimeout( function () {
				$failIcon.fadeOut( 'slow', function () {
					$( this ).remove();
				} );
			}, _this.failDuration )

		}

		if ( _this.sounds.fail && _this.currState != 'replaying' ) {
			createjs.Sound.stop();
			createjs.Sound.play( _this.sounds.fail.id );

			PANA.D2L.fireEvent( 'mauvaise réponse', _this.chapter + ':' + _this.activity );
		}
	},
	checkIsDone     : function () {
		var _this = PANA.Games.chapitre3activite1;

		if ( _this.$draggables.length === _this.$draggables.filter( '.success' ).length ) {
			PANA.Games.utils.setGameState( _this.chapter, _this.activity, 'win' );
			_this.endAnimation();

			if ( _this.sounds.win && _this.currState != 'replaying' ) {
				createjs.Sound.stop();
				createjs.Sound.play( _this.sounds.win.id );

				PANA.D2L.fireEvent( 'gagné', _this.chapter + ':' + _this.activity );
			}
			PANA.NAV.showNav();
		} else {
			PANA.Games.utils.setGameState( _this.chapter, _this.activity, 'begun' );
		}
	},
	saveAnswer      : function ( draggableIndex, droppableIndex ) {
		var _this = PANA.Games.chapitre3activite1;

		PANA.Games.utils.saveAnswer( _this.chapter, _this.activity, {
			draggableIndex : draggableIndex,
			droppableIndex : droppableIndex
		} );

	},
	replayAnswers   : function () {
		var _this = PANA.Games.chapitre3activite1;

		var state = PANA.Games.utils.getGameState( _this.chapter, _this.activity ),
			answers;

		if ( state ) {
			answers = PANA.Games.utils.getAnswers( _this.chapter, _this.activity );
		}

		if ( answers ) {
			_this.currState = 'replaying';
			for ( var i = 0; i < answers.length; i++ ) {
				var draggableIndex = answers[ i ].draggableIndex,
					droppableIndex = answers[ i ].droppableIndex;
				//if (draggableid === droppableId) {
				var $draggable = _this.$draggables.eq( draggableIndex ),
					$droppable = _this.$droppables.eq( droppableIndex ),
					animal = $draggable.parents( '.activity-draggable' ).data( 'animal' );


				_this.showSuccess( $draggable, $droppable, animal );
				//}
			}
			_this.currState = 'normal';
		}
	},
	endAnimation    : function () {
		var _this = PANA.Games.chapitre3activite1;

		_this.$animalsNormal.hide();
		_this.$animalsHappy.show();

	}
}