;
PANA.Games.chapitre4activite2 = {
	init            : function ( activityName, chapter, activity ) {
		var _this = PANA.Games.chapitre4activite2;

		_this.cleanUp();

		_this.options = {
			variableThreshold : '30%',
			snapTo            : true
		}

		_this.chapter = chapter;
		_this.activity = activity;
		_this.currState = 'normal';

		_this.$activityContainer = $( '.activity' );

		_this.cleanupSounds();
		_this.setupSounds();
		_this.setupDraggables();

		_this.bindEvents();

		_this.replayAnswers();

	},
	cleanUp         : function () {
		var _this = PANA.Games.chapitre4activite2;

		if ( typeof _this.$activityContainer != 'undefined' ) {
			_this.$activityContainer.off( 'click' );
		}

		if ( typeof _this.$draggables != 'undefined' ) {
			Draggable.get( _this.$draggables ).kill();
		}

		var keysToClean = [
			'options',
			'currState',
			'sounds',
			'$draggables',
			'$droppables',
			'$activityContainer',
		];
		PANA.UTILS.clearFromMemory( _this, keysToClean );
	},
	bindEvents      : function () {
		var _this = PANA.Games.chapitre4activite2;

		_this.$activityContainer.on( 'click', '.btn-container', function ( e ) {
			e.preventDefault();

			var $this = $( this ),
				pictoId = $this.parents( '.draggable-container' ).data( 'picto-id' );

			if ( _this.sounds.pictos[ pictoId ] ) {
				createjs.Sound.stop();
				createjs.Sound.play( _this.sounds.pictos[ pictoId ].id );
			}
		} );

	},
	setupSounds     : function () {
		var _this = PANA.Games.chapitre4activite2;

		_this.sounds = {};

		var soundGeneralWin = _this.$activityContainer.data( 'sound-win' ) || false,
			soundGeneralFail = _this.$activityContainer.data( 'sound-fail' ) || false,
			soundGeneralSuccess = _this.$activityContainer.data( 'sound-success' ) || false;

		_this.sounds.win = !soundGeneralWin ? false : createjs.Sound.registerSound( soundGeneralWin, _this.chapter + '_' + _this.activity + '_' + 'win', 0, PANA.config.strAudioFolder );
		_this.sounds.fail = !soundGeneralFail ? false : createjs.Sound.registerSound( soundGeneralFail, _this.chapter + '_' + _this.activity + '_' + 'fail', 0, PANA.config.strAudioFolder );
		_this.sounds.success = !soundGeneralSuccess ? false : createjs.Sound.registerSound( soundGeneralSuccess, _this.chapter + '_' + _this.activity + '_' + 'success', 0, PANA.config.strAudioFolder );

		_this.sounds.pictos = {};


		_this.$activityContainer.find( '.draggable-container' ).each( function () {
			var $this = $( this ),
				pictoId = $this.data( 'picto-id' ),
				pictoSound = $this.data( 'picto-sound' ) || false;


			_this.sounds.pictos[ pictoId ] = !pictoSound ? false : createjs.Sound.registerSound( pictoSound, _this.chapter + '_' + _this.activity + '_' + pictoId, 0, PANA.config.strAudioFolder );
		} );

	},
	cleanupSounds   : function () {
		var _this = PANA.Games.chapitre4activite2;

	},
	setupDraggables : function () {
		var _this = PANA.Games.chapitre4activite2;

		_this.$draggables = _this.$activityContainer.find( '.draggable-container' ).find( '.draggable' );
		_this.$droppables = _this.$activityContainer.find( '.droppable-container' );


		Draggable.create( _this.$draggables, {
			type      : "top,left",
			onPress   : function () {
				StartX = this.x;
				StartY = this.y;
			},
			onRelease : function () {
				var $draggable = $( this.target ),
					_draggable = this,
					isMatch = false,
					$matchingDroppable,
					$attemptedDroppable;

				// Draggable's hitTest checks if it overlaps based on the threshold set
				// More info at http://greensock.com/docs/#/HTML5/GSAP/Utils/Draggable/hitTest/
				_this.$droppables.each( function () {

					// If they overlap AND aren't disabled
					if ( _draggable.hitTest( $( this ), _this.options.variableThreshold ) && !$( this ).hasClass( 'disabled' ) ) {

						// Setting this aside
						$attemptedDroppable = $( this );

						if ( _this.compareDragDrop( $draggable, $( this ) ) ) {
							isMatch = true;
							$matchingDroppable = $( this );
						}

					}

				} );

				if ( isMatch ) {
					// Yay! Win!
					_this.showSuccess( $draggable, $matchingDroppable );
					_this.saveAnswer( $attemptedDroppable.data( 'correct-picto' ), $draggable.parents( '.draggable-container' ).data( 'picto-id' ) );

				} else {

					// we ALWAYS want to revert the draggable to its original position
					_this.revertDraggable( $draggable );

					// but it doesn't mean that it actually hovered over a droppable
					if ( $attemptedDroppable ) {
						_this.showFail( $draggable, $attemptedDroppable );
						_this.checkIsDone();
					}
				}


			} // onRelease
		} );

	},
	compareDragDrop : function ( $draggable, $droppable ) {
		var draggableId = $draggable.parents( '.draggable-container' ).data( 'picto-id' ),
			droppableId = $droppable.data( 'correct-picto' );

		return draggableId == droppableId;
	},
	revertDraggable : function ( $draggable ) {
		var _this = PANA.Games.chapitre4activite2;


		TweenLite.to( $draggable, 0.5, { left : 0, top : 0, ease : 'Elastic' } );
	},
	showSuccess     : function ( $draggable, $droppable ) {
		var _this = PANA.Games.chapitre4activite2;

		if ( _this.options.snapTo ) {
			var droppablePosition = $droppable.position(),
				$draggableParent = $draggable.parents( '.draggable-container' ),
				draggableParentPosition = $draggableParent.position(),
				draggableParentWidth = $draggableParent.innerWidth(),
				draggableParentHeight = $draggableParent.innerHeight(),
				desiredLeft = droppablePosition.left - draggableParentPosition.left,
				desiredTop = droppablePosition.top - draggableParentPosition.top;


			Draggable.get( $draggable ).disable();


			$draggable.addClass( 'success' );
			if ( _this.currState != 'replaying' ) {
				PANA.D2L.fireEvent( 'bonne réponse', _this.chapter + ':' + _this.activity );

				//	$draggableParent.find( '.btn-container' ).fadeOut();

				TweenLite.to( $draggable, 0.5, {
					left       : desiredLeft, top : desiredTop, ease : 'Elastic',
					onComplete : function () {
						$draggable.css( {
							left : desiredLeft / draggableParentWidth * 100 + '%',
							top  : desiredTop / draggableParentHeight * 100 + '%'
						} );
					}
				} );
			} else {
				$draggable.css( {
					left : desiredLeft / draggableParentWidth * 100 + '%',
					top  : desiredTop / draggableParentHeight * 100 + '%'
				} );
				//	$draggableParent.find( '.btn-container' ).hide();
			}
			//$droppable.addClass( 'disabled success' );


			if ( _this.sounds.success && _this.currState != 'replaying' ) {
				//_this.sounds.success.play();

				createjs.Sound.stop();
				createjs.Sound.play( _this.sounds.success.id );

			}

			_this.checkIsDone();

		}

	},
	showFail        : function ( $draggable, $attemptedDroppable ) {
		var _this = PANA.Games.chapitre4activite2;

		var $failIcon = $( '<img class="gen-fail-x" alt="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKIAAACiAQMAAAD4PIRYAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAABpJREFUeNrtwQENAAAAwqD3T20PBxQAAABvBg3sAAHckWr/AAAAAElFTkSuQmCC">' ).appendTo( $attemptedDroppable );

		setTimeout( function () {
			$failIcon.fadeOut( 'slow', function () {
				$( this ).remove();
			} );
		}, _this.failDuration )


		if ( _this.sounds.fail && _this.currState != 'replaying' ) {
			//_this.sounds.fail.pos( 0 ).play();
			createjs.Sound.stop();
			createjs.Sound.play( _this.sounds.fail.id );

			PANA.D2L.fireEvent( 'mauvaise réponse', _this.chapter + ':' + _this.activity );
		}
	},
	checkIsDone     : function () {
		var _this = PANA.Games.chapitre4activite2;

		if ( _this.$draggables.length === _this.$draggables.filter( '.success' ).length ) {
			PANA.Games.utils.setGameState( _this.chapter, _this.activity, 'win' );
			_this.endAnimation();


			if ( _this.currState != 'replaying' ) {
				PANA.D2L.fireEvent( 'gagné', _this.chapter + ':' + _this.activity );
			}

			if ( _this.sounds.win && _this.currState != 'replaying' ) {
				//_this.sounds.win.play();

				createjs.Sound.stop();
				createjs.Sound.play( _this.sounds.win.id );


			}
			PANA.NAV.showNav();
		} else {
			PANA.Games.utils.setGameState( _this.chapter, _this.activity, 'begun' );
		}
	},
	saveAnswer      : function ( draggableId, droppableId ) {
		var _this = PANA.Games.chapitre4activite2;

		PANA.Games.utils.saveAnswer( _this.chapter, _this.activity, {
			draggableId : draggableId,
			droppableId : droppableId
		} );

	},
	replayAnswers   : function () {
		var _this = PANA.Games.chapitre4activite2;

		var state = PANA.Games.utils.getGameState( _this.chapter, _this.activity ),
			answers;

		if ( state ) {
			answers = PANA.Games.utils.getAnswers( _this.chapter, _this.activity );
		}

		if ( answers ) {
			_this.currState = 'replaying';
			for ( var i = 0; i < answers.length; i++ ) {
				var draggableId = answers[ i ].draggableId,
					droppableId = answers[ i ].droppableId;

				if ( draggableId === droppableId ) {
					var $draggable = _this.$draggables.parents( '.draggable-container' ).filter( '[data-picto-id="' + draggableId + '"]' ).find( '.draggable' ),
						$droppable = _this.$droppables.filter( '[data-correct-picto="' + droppableId + '"]' );

					$draggable.css( 'zIndex', 5 );
					$droppable.css( 'zIndex', 4 );
					_this.showSuccess( $draggable, $droppable );
				}
			}
			_this.currState = 'normal';
		}
	},
	endAnimation    : function () {
		var _this = PANA.Games.chapitre4activite2;


	}
}