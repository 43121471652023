;
/**
 * Table of contents builder for the homepage (table of contents page)
 *
 */


PANA.tdm = {};
PANA.tdm.revealer = {
	init          : function () {
		var _this = PANA.tdm.revealer;

		_this.$mainContainer = $( '.tdm-container' );

		if ( _this.$mainContainer.length > 0 ) {
			_this.$togglers = $( '.activity-toggler' );
			_this.setupTogglers();
			_this.bindEvents();
		}
	},
	setupTogglers : function () {
		var _this = PANA.tdm.revealer;

		_this.$togglers.each( function () {
			var $this = $( this ),
				$activitiesSibling = $this.siblings( '.activities' );

			$this.data( 'sectionToToggle', $activitiesSibling );

		} );

	},
	bindEvents    : function () {
		var _this = PANA.tdm.revealer;

		_this.$mainContainer.on( 'click', '.activity-toggler', function ( e ) {

			var $this = $( this );

			$this.add( $this.data( 'sectionToToggle' ) ).toggleClass( 'open' );
		} );

	}
}

PANA.tdm.markCompleted = function () {
	var $mainContainer = $( '.tdm-container' ),
		$togglers = $mainContainer.find( '.activity-icon' );

	if ( $mainContainer.length === 0 ) {
		return false;
	}

	$togglers.each( function ( index ) {

		var $this = $( this ),
			chapterLink = $this.data( 'activite-done' ),
			arrChapterLink = chapterLink.split( ':' ),
			strChapter = arrChapterLink[ 0 ],
			strPage = arrChapterLink[ 1 ];

		if (
			typeof PANA.chapters[ strChapter ] !== 'undefined'
			&& typeof PANA.chapters[ strChapter ][ strPage ] !== 'undefined'
			&& typeof PANA.chapters[ strChapter ][ strPage ][ 'state' ] !== 'undefined'
		) {
			var classToAdd = PANA.chapters[ strChapter ][ strPage ][ 'state' ] == 'win' ? 'complete' : 'begun';

			$this.addClass( PANA.chapters[ strChapter ][ strPage ][ 'state' ], classToAdd );

			if ( PANA.chapters[ strChapter ][ strPage ][ 'state' ] == 'win' ) {
				$this.append( '<svg version="1.1" class="svg-crochet" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 51 42.9" style="enable-background:new 0 0 51 42.9;" xml:space="preserve"><style type="text/css">.svg-crochet .st0{opacity:0.29;}	.svg-crochet .st1{fill:#508632;}</style><path id="XMLID_42_" class="st0" d="M51,7.1L45.5,2c-12,8.7-18,17.1-24.3,28.1c-4.1-4.6-9.1-8.6-15-11.5L1,24.8 c8.4,4.2,14.7,9.4,20.8,17.2l0.1-0.1l0.7,1c1.1-2,2.2-4.1,3.3-6.1l0.2-0.2c0,0,0,0,0,0C32.3,25.6,40.2,15.1,51,7.1z"/><path id="XMLID_4_" class="st1" d="M50,5.1L44.5,0c-12,8.7-18,17.1-24.3,28.1c-4.1-4.6-9.1-8.6-15-11.5L0,22.8 c8.4,4.2,14.7,9.4,20.8,17.2l0.1-0.1l0.7,1c1.1-2,2.2-4.1,3.3-6.1l0.2-0.2c0,0,0,0,0,0C31.3,23.6,39.2,13.1,50,5.1z"/></svg>' );
			}
		}


	} );
}