;
PANA.Games.chapitre2 = {
	init                 : function ( activityName, chapter, activity ) {
		var _this = PANA.Games.chapitre2;

		_this.cleanUp();

		_this.options = {
			variableThreshold : '30%',
			snapTo            : true
		}

		_this.chapter = chapter;
		_this.activity = activity;
		_this.currState = 'normal';


		_this.delayError = 2000; // How long to show the error state in Milliseconds

		_this.$activityContainer = $( '.activity' );


		_this.$headDraggables = _this.$activityContainer.find( '.container-chap2act1-drag-hiver-tuque, .container-chap2act1-drag-automne-chapeau, .container-chap2act1-drag-ete-chapeau, .container-chap2act1-drag-printemps-chapeau' );
		_this.$headWearables = _this.$activityContainer.find( '.container-chap2act1-automne-bonnet, .hiver-head, .container-chap2act1-ete-chapeau, .container-chap2act1-printemps-chapeau' );
		_this.$bodyDraggables = _this.$activityContainer.find( '.container-chap2act1-drag-automne-manteau, .container-chap2act1-drag-ete-vetements , .container-chap2act1-drag-hiver-habit, .container-chap2act1-drag-printemps-poncho' );
		_this.$bodyWearables = _this.$activityContainer.find( '.container-chap2act1-automne-manteau, .ete-body, .hiver-body, .printemps-body' );
		_this.$feetDraggables = _this.$activityContainer.find( '.container-chap2act1-drag-automne-bottes, .container-chap2act1-drag-ete-chaussures, .ete-feet, .container-chap2act1-drag-hiver-bottes, .container-chap2act1-drag-printemps-bottes' );
		_this.$feetWearables = _this.$activityContainer.find( '.container-chap2act1-automne-bottes, .ete-pieds, .container-chap2act1-hiver-bottes, .container-chap2act1-printemps-bottes' );

		_this.$allDraggableContainers = _this.$headDraggables.add( _this.$bodyDraggables ).add( _this.$feetDraggables );
		_this.$allDraggables = _this.$allDraggableContainers.find( '.draggable' );
		_this.$allWearables = _this.$headWearables.add( _this.$bodyWearables ).add( _this.$feetWearables );


		_this.$errorIcon = _this.$activityContainer.find( '.container-chap2act1-icon-error' );
		_this.$btnRadio = _this.$activityContainer.find( '.audio-radio' );
		_this.$btnAmbiance = _this.$activityContainer.find( '.audio-saison' );
		_this.$btnNextSeason = $( '.btn-next-season' );

		// États / rétroactions de la moufette
		_this.$moufette = _this.$activityContainer.find( '.container-chap2act1-moufette' );
		_this.$moufetteTropFroid = _this.$activityContainer.find( '.container-chap2act1-moufette-trop-froid' );
		_this.$moufetteMouillee = _this.$activityContainer.find( '.container-chap2act1-moufette-mouillee' );
		_this.$moufetteEnrhumee = _this.$activityContainer.find( '.container-chap2act1-moufette-enrhumee' );
		_this.$moufetteTropChaud = _this.$activityContainer.find( '.container-chap2act1-moufette-trop-chaud' );
		_this.$moufetteOtherStates = _this.$moufetteTropFroid.add( _this.$moufetteMouillee ).add( _this.$moufetteEnrhumee ).add( _this.$moufetteTropChaud );

		_this.currentSeasonIndex = 0;
		_this.currentHead = false;
		_this.currentBody = false;
		_this.currentFeet = false;

		_this.$navigation = $( '.navigation' );
		_this.$btnBackChapter = _this.$navigation.find( '.chapter-back' );
		_this.$btnBackActivity = _this.$navigation.find( '.activity-back' );
		_this.showProperBackButton();


		//_this.cleanupSounds();
		_this.setupSounds();
		_this.setupDraggables();

		_this.bindEvents();


		if ( typeof PANA.chapters.chapitre_2.activite_1.seasonsOrder == 'undefined' ) {
			_this.seasons = PANA.UTILS.fisherShuffle( [ 'ete', 'automne', 'hiver', 'printemps' ] );
			PANA.chapters.chapitre_2.activite_1.seasonsOrder = _this.seasons;
		} else {
			_this.seasons = PANA.chapters.chapitre_2.activite_1.seasonsOrder;
		}
		_this.replayAnswers();
		_this.replaySingleAnswer();


		_this.setupSeason();

		_this.checkIfDone();

	},
	info                 : {
		'ete'       : {
			correctHead   : [ 'container-chap2act1-ete-chapeau' ],
			correctBody   : [ 'ete-body' ],
			correctFeet   : [ 'ete-pieds' ],
			radioAudio    : 'chapitre_2_activite_1_radio_ete.mp3',
			ambianceAudio : 'chapitre_2_activite_1_ambiance_ete.mp3'
		},
		'automne'   : {
			correctHead   : [ 'container-chap2act1-automne-bonnet' ],
			correctBody   : [ 'container-chap2act1-automne-manteau' ],
			correctFeet   : [ 'container-chap2act1-automne-bottes', 'container-chap2act1-printemps-bottes' ],
			radioAudio    : 'chapitre_2_activite_1_radio_automne.mp3',
			ambianceAudio : 'chapitre_2_activite_1_ambiance_automne.mp3'
		},
		'hiver'     : {
			correctHead   : [ 'hiver-head' ],
			correctBody   : [ 'hiver-body' ],
			correctFeet   : [ 'container-chap2act1-hiver-bottes' ],
			radioAudio    : 'chapitre_2_activite_1_radio_hiver.mp3',
			ambianceAudio : 'chapitre_2_activite_1_ambiance_hiver.mp3'
		},
		'printemps' : {
			correctHead   : [ 'container-chap2act1-printemps-chapeau' ],
			correctBody : [ 'printemps-body' ],
			correctFeet : [ 'container-chap2act1-printemps-bottes', 'container-chap2act1-automne-bottes' ],
			radioAudio  : 'chapitre_2_activite_1_radio_printemps.mp3',
			ambianceAudio : 'chapitre_2_activite_1_ambiance_printemps.mp3'
		}
	},
	cleanUp              : function () {
		var _this = PANA.Games.chapitre2;

		//unbinding if existed prior
		if ( typeof _this.$bodyWearables != 'undefined' ) {
			_this.$bodyWearables.add( _this.$headWearables ).add( _this.$feetWearables ).off( 'click.wearables' )
		}

		if ( typeof _this.$btnRadio != 'undefined' ) {
			_this.$btnRadio.off( 'click.radio' )
		}

		if ( typeof _this.$btnNextSeason != 'undefined' ) {
			_this.$btnNextSeason.off( 'click' );
		}

		if ( typeof _this.$btnBackActivity != 'undefined' ) {
			_this.$btnBackActivity.off( 'click' )
		}

		if ( typeof _this.$draggables != 'undefined' ) {
			Draggable.get( '.draggable' ).kill();
		}

		var keysToClean = [
			'sounds',
			'options',
			'currState',
			'seasons',
			'delayError',
			'$draggables',
			'$droppables',
			'$headDraggables',
			'$headWearables',
			'$bodyDraggables',
			'$bodyWearables',
			'$feetDraggables',
			'$feetWearables',
			'$allDraggableContainers',
			'$allDraggables',
			'$allWearables',
			'$errorIcon',
			'$btnRadio',
			'$btnAmbiance',
			'$btnNextSeason',
			'$moufette',
			'$moufetteTropFroid',
			'$moufetteMouillee',
			'$moufetteEnrhumee',
			'$moufetteTropChaud',
			'$moufetteOtherStates',
			'currentSeasonIndex',
			'currentHead',
			'currentBody',
			'currentFeet',
			'$activityContainer',
			'$calendrierContainer',
			'$calendriers',
			'$fenetre',
			'$outsides',
			'$btnBackActivity',
			'$btnBackChapter',
			'$cintres',
			'$crochetsVides',
			'$navigation',
			'$activityContainer',

		];
		PANA.UTILS.clearFromMemory( _this, keysToClean );

	},
	setupSounds          : function () {
		var _this = PANA.Games.chapitre2;

		_this.sounds = {};

		var soundGeneralWin = _this.$activityContainer.data( 'sound-win' ) || false,
			soundGeneralFail = _this.$activityContainer.data( 'sound-fail' ) || false,
			soundGeneralSuccess = _this.$activityContainer.data( 'sound-success' ) || false;

		_this.sounds.win = !soundGeneralWin ? false : createjs.Sound.registerSound( soundGeneralWin, _this.chapter + '_' + _this.activity + '_' + 'win', 0, PANA.config.strAudioFolder, PANA.config.defaultSoundJSprops );
		_this.sounds.fail = !soundGeneralFail ? false : createjs.Sound.registerSound( soundGeneralFail, _this.chapter + '_' + _this.activity + '_' + 'fail', 0, PANA.config.strAudioFolder, PANA.config.defaultSoundJSprops );
		_this.sounds.success = !soundGeneralSuccess ? false : createjs.Sound.registerSound( soundGeneralSuccess, _this.chapter + '_' + _this.activity + '_' + 'success', 0, PANA.config.strAudioFolder, PANA.config.defaultSoundJSprops );


		_this.info.ete.radioAudioSound = createjs.Sound.registerSound( _this.info.ete.radioAudio, _this.chapter + '_' + _this.activity + '_' + 'ete_radio', 0, PANA.config.strAudioFolder, PANA.config.defaultSoundJSprops );
		_this.info.ete.ambianceAudioSound = createjs.Sound.registerSound( _this.info.ete.ambianceAudio, _this.chapter + '_' + _this.activity + '_' + 'ete_ambiance', 0, PANA.config.strAudioFolder, PANA.config.defaultSoundJSprops );

		_this.info.automne.radioAudioSound = createjs.Sound.registerSound( _this.info.automne.radioAudio, _this.chapter + '_' + _this.activity + '_' + 'automne_radio', 0, PANA.config.strAudioFolder, PANA.config.defaultSoundJSprops );
		_this.info.automne.ambianceAudioSound = createjs.Sound.registerSound( _this.info.automne.ambianceAudio, _this.chapter + '_' + _this.activity + '_' + 'automne_ambiance', 0, PANA.config.strAudioFolder, PANA.config.defaultSoundJSprops );

		_this.info.hiver.radioAudioSound = createjs.Sound.registerSound( _this.info.hiver.radioAudio, _this.chapter + '_' + _this.activity + '_' + 'hiver_radio', 0, PANA.config.strAudioFolder, PANA.config.defaultSoundJSprops );
		_this.info.hiver.ambianceAudioSound = createjs.Sound.registerSound( _this.info.hiver.ambianceAudio, _this.chapter + '_' + _this.activity + '_' + 'hiver_ambiance', 0, PANA.config.strAudioFolder, PANA.config.defaultSoundJSprops );

		_this.info.printemps.radioAudioSound = createjs.Sound.registerSound( _this.info.printemps.radioAudio, _this.chapter + '_' + _this.activity + '_' + 'printemps_radio', 0, PANA.config.strAudioFolder, PANA.config.defaultSoundJSprops );
		_this.info.printemps.ambianceAudioSound = createjs.Sound.registerSound( _this.info.printemps.ambianceAudio, _this.chapter + '_' + _this.activity + '_' + 'printemps_ambiance', 0, PANA.config.strAudioFolder, PANA.config.defaultSoundJSprops );


		_this.sounds.tropChaud = createjs.Sound.registerSound( 'chapitre_2_activite_1_trop_chaud.mp3', _this.chapter + '_' + _this.activity + '_' + 'tropChaud', 0, PANA.config.strAudioFolder, PANA.config.defaultSoundJSprops );
		_this.sounds.tropFroid = createjs.Sound.registerSound( 'chapitre_2_activite_1_avoir_froid.mp3', _this.chapter + '_' + _this.activity + '_' + 'tropFroid', 0, PANA.config.strAudioFolder, PANA.config.defaultSoundJSprops );
		_this.sounds.mouillee = createjs.Sound.registerSound( 'chapitre_2_activite_1_toute_mouillee.mp3', _this.chapter + '_' + _this.activity + '_' + 'mouillee', 0, PANA.config.strAudioFolder, PANA.config.defaultSoundJSprops );
		_this.sounds.enrhumee = createjs.Sound.registerSound( 'chapitre_2_activite_1_prendre_froid.mp3', _this.chapter + '_' + _this.activity + '_' + 'enrhumee', 0, PANA.config.strAudioFolder, PANA.config.defaultSoundJSprops );

	},
	cleanupSounds        : function () {
		var _this = PANA.Games.chapitre2;


	},
	setupSeason          : function () {
		var _this = PANA.Games.chapitre2;

		_this.$calendrierContainer = _this.$activityContainer.find( '.calendrier' );
		_this.$calendriers = _this.$calendrierContainer.find( '.item-container' );

		_this.$fenetre = _this.$activityContainer.find( '.fenetre' );
		_this.$outsides = _this.$fenetre.find( '.container-chap2act1-saison-fenetre-hiver, .container-chap2act1-saison-fenetre-printemps, .container-chap2act1-saison-fenetre-automne, .container-chap2act1-saison-fenetre-ete' );

		var currentSeason = _this.seasons[ _this.currentSeasonIndex ];

		for ( var i = 0; i < _this.seasons.length; i++ ) {

			_this.$calendriers.not( '.container-chap2act1-calendrier-' + currentSeason ).hide();
			_this.$calendriers.filter( '.container-chap2act1-calendrier-' + currentSeason ).show();

			_this.$outsides.not( '.container-chap2act1-saison-fenetre-' + currentSeason ).hide();
			_this.$outsides.filter( '.container-chap2act1-saison-fenetre-' + currentSeason ).show();
		}

	},
	bindEvents           : function () {
		var _this = PANA.Games.chapitre2;

		_this.$bodyWearables.add( _this.$headWearables ).add( _this.$feetWearables ).on( 'click.wearables', function ( e ) {

			var $this = $( this ),
				whichPart = $this.data( 'where' ),
				season = $this.data( 'season' ),
				$draggable = _this.$activityContainer.find( '.vetements-etagere' ).find( '[data-where="' + whichPart + '"][data-season="' + season + '"]' );

			_this.$activityContainer.find( '.vetements-sur-moufette' ).find( '[data-where="' + whichPart + '"][data-season="' + season + '"]' ).hide();

			if ( whichPart == 'head' ) {
				_this.currentHead = false;
			} else if ( whichPart == 'body' ) {
				_this.currentBody = false;
				_this.$cintres.hide();
			} else if ( whichPart == 'feet' ) {
				_this.currentFeet = false;
			}
			$draggable.css( { top : 0, left : 0 } );
			PANA.debug.console.log( 'reverting', $this, whichPart, season, $draggable.add( $draggable.parents( '.item-container' ) ) );

			$draggable.add( $draggable.parents( '.item-container' ) ).show();

		} );


		_this.$btnRadio.on( 'click.radio', function ( e ) {
			e.preventDefault();

			var wantedAudio = _this.info[ _this.seasons[ _this.currentSeasonIndex ] ][ 'radioAudioSound' ];

			if ( typeof wantedAudio != 'undefined' ) {
				createjs.Sound.stop();
				createjs.Sound.play( wantedAudio.id );
			}

		} );

		_this.$btnAmbiance.on( 'click.ambiance', function ( e ) {
			e.preventDefault();

			var wantedAudio = _this.info[ _this.seasons[ _this.currentSeasonIndex ] ][ 'ambianceAudioSound' ];

			if ( typeof wantedAudio != 'undefined' ) {

				createjs.Sound.stop();
				createjs.Sound.play( wantedAudio.id );
			}

		} );


		_this.$btnNextSeason.on( 'click', function ( e ) {
			e.preventDefault();

			_this.currentSeasonIndex++;

			_this.resetRound();
			_this.setupSeason();

			$( this ).hide();

			createjs.Sound.stop();

			_this.replaySingleAnswer();
			_this.showProperBackButton();
		} );

		_this.$btnBackActivity.on( 'click', function ( e ) {
			e.preventDefault();

			_this.currentSeasonIndex--;
			_this.resetRound();

			_this.setupSeason();
			_this.replaySingleAnswer();
			_this.showProperBackButton();


		} );

	},
	setupDraggables      : function () {
		var _this = PANA.Games.chapitre2;

		_this.$draggables = _this.$activityContainer.find( '.draggable' );
		_this.$droppables = _this.$activityContainer.find( '.container-chap2act1-moufette' );

		_this.$cintres = _this.$activityContainer.find( '.container-chap2act1-crochet-avec-cintre' );
		_this.$crochetsVides = _this.$activityContainer.find( '.container-chap2act1-crochet' );

		Draggable.create( '.draggable', {
			type        : "top,left",
			onDragStart : function () {
				var $draggable = $( this.target ),
					season = $draggable.data( 'season' ),
					$matchingCrochet = _this.$crochetsVides.filter( '.' + season );

				$matchingCrochet.addClass( 'dragging' );

			},
			onRelease   : function () {
				var $draggable = $( this.target ),
					_draggable = this,
				//originalPosition = $draggable.data( 'originalPosition' ),
					noHit = true,
					$matchingDroppable,
					$attemptedDroppable;


				// Draggable's hitTest checks if it overlaps based on the threshold set
				// More info at http://greensock.com/docs/#/HTML5/GSAP/Utils/Draggable/hitTest/
				_this.$droppables.each( function () {

					// If they overlap AND aren't disabled
					if ( _draggable.hitTest( $( this ), _this.options.variableThreshold ) && !$( this ).hasClass( 'disabled' ) ) {

						//// Setting this aside
						//$attemptedDroppable = $( this );
						//
						//if ( _this.compareDragDrop( $draggable, $( this ) ) ) {
						//	isMatch = true;
						//	$matchingDroppable = $( this );
						//}

						var whichPart = $draggable.data( 'where' ),
							matchingClothes = $draggable.data( 'matching-clothes' ),
							$imageOrClass = _this.$activityContainer.find( '.' + matchingClothes ),
							$additional = $imageOrClass.find( '.item-container' );

						noHit = false;

						if ( whichPart == 'head' ) {
							_this.$headWearables.hide();
							_this.$headDraggables.not( $draggable.parents( '.item-container' ) ).show().find( '.draggable' ).css( {
								top  : 0,
								left : 0
							} );
							//_this.currentHead = matchingClothes;

						} else if ( whichPart == 'body' ) {
							_this.$bodyWearables.hide();
							_this.$bodyDraggables.not( $draggable.parents( '.item-container' ) ).show().find( '.draggable' ).css( {
								top  : 0,
								left : 0
							} );


							//	_this.currentBody = matchingClothes;
						} else if ( whichPart == 'feet' ) {
							_this.$feetWearables.hide();
							_this.$feetDraggables.not( $draggable.parents( '.item-container, .draggable' ) ).show().find( '.draggable' ).css( {
								top  : 0,
								left : 0
							} );
							//_this.currentFeet = matchingClothes;
						}


						$imageOrClass.add( $additional ).show();


						//$draggable.css( {
						//	top  : 0,
						//	left : 0
						//} );

						$draggable.parents( '.item-container' ).hide();

						PANA.config.$activityBlocker.show();
						_this.validateAnswers( whichPart, matchingClothes, $draggable );
						_this.checkIfDone();

						//_this.saveAnswer( $attemptedDroppable.data( 'droppableId' ), $draggable.data( 'draggable-id' ) );
					}


				} );

				if ( noHit ) {
					_this.revertDraggable( $draggable );
				}


			} // onRelease
		} );

	},
	revertDraggable      : function ( $draggable ) {
		var _this = PANA.Games.chapitre2;


		if ( _this.currentState != 'replaying' ) {
			TweenLite.to( $draggable, 0.5, {
				left       : 0, top : 0, ease : 'Elastic',
				onComplete : function () {
					_this.$crochetsVides.removeClass( 'dragging' );
				}
			} );
		}
	},
	resetRound           : function () {
		var _this = PANA.Games.chapitre2;

		_this.currentHead = false;
		_this.currentBody = false;
		_this.currentFeet = false;

		_this.$allDraggables.css( {
			top  : 0,
			left : 0
		} );
		_this.$allDraggableContainers.fadeIn();

		_this.$allWearables.hide();

		_this.$cintres.stop().hide();
		_this.$crochetsVides.stop().show();

	},
	checkIfDone          : function () {
		var _this = PANA.Games.chapitre2;

		PANA.debug.console.log( 'Head: ' + _this.currentHead + ' | Body: ' + _this.currentBody + '  | Feet: ' + _this.currentFeet );


		if ( _this.currentBody && _this.currentFeet && _this.currentHead ) {
			// Everything is filled in

			var isWin = _this.validateAllAnswers();

			if ( isWin && _this.currentSeasonIndex == (_this.seasons.length - 1) ) {
				// All have been answered
				if ( _this.currState != 'replaying' ) {

					createjs.Sound.stop();
					createjs.Sound.play( _this.sounds.win.id );

					PANA.Games.utils.setGameState( _this.chapter, _this.activity, 'win' );
					PANA.D2L.fireEvent( 'gagné', _this.chapter + ':' + _this.activity );
				}
				PANA.NAV.showNav();

				_this.$btnNextSeason.hide();

			} else if ( isWin == true ) {
				_this.$btnNextSeason.show();

				if ( _this.currState != 'replaying' ) {
					createjs.Sound.stop();
					createjs.Sound.play( _this.sounds.win.id );
				}

				PANA.NAV.hideNav()

			}
		} else {
			_this.$btnNextSeason.hide();
		}

		_this.showProperBackButton();

	},
	validateAnswers      : function ( bodyPart, choice, $draggable ) {
		var _this = PANA.Games.chapitre2;

		var desiredAnswers = _this.info[ _this.seasons[ _this.currentSeasonIndex ] ],
			isCorrect = false,
			season = $draggable.data( 'season' );


		if ( bodyPart == 'body' ) {
			_this.currentBody = choice;
			isCorrect = $.inArray( _this.currentBody, desiredAnswers.correctBody ) != -1;
			PANA.debug.console.log( 'Validating' + '_this.currentBody: ' + _this.currentBody + '  | desiredAnswers.correctBody: ' + desiredAnswers.correctBody, isCorrect );
		} else if ( bodyPart == 'head' ) {
			_this.currentHead = choice;
			isCorrect = $.inArray( _this.currentHead, desiredAnswers.correctHead ) != -1;
			PANA.debug.console.log( 'Validating' + '_this.currentHead: ' + _this.currentHead + '  | desiredAnswers.correctHead: ' + desiredAnswers.correctHead, isCorrect );
		} else if ( bodyPart == 'feet' ) {
			_this.currentFeet = choice;
			isCorrect = $.inArray( _this.currentFeet, desiredAnswers.correctFeet ) != -1;
			PANA.debug.console.log( 'Validating' + '_this.currentFeet: ' + _this.currentFeet + '  | desiredAnswers.correctFeet: ' + desiredAnswers.correctFeet, isCorrect );
		}

		if ( isCorrect ) {

			if ( bodyPart == 'body' ) {
				_this.$cintres.filter( '.' + season ).stop().fadeIn();
				_this.$crochetsVides.filter( '.' + season ).stop().fadeOut();
			}

			PANA.config.$activityBlocker.hide();
			createjs.Sound.stop();
			createjs.Sound.play( _this.sounds.success.id );

			PANA.D2L.fireEvent( 'bonne réponse', _this.chapter + ':' + _this.activity );

			if ( _this.currState != 'replaying' ) {
				PANA.Games.utils.saveAnswer( _this.chapter, _this.activity, {
					currentBody : _this.currentBody,
					currentHead : _this.currentHead,
					currentFeet : _this.currentFeet
				}, _this.currentSeasonIndex );
				PANA.Games.utils.setGameState( _this.chapter, _this.activity, 'begun' );
			}


			return true;
		} else {
			// wrong

			if ( bodyPart == 'body' ) {
				_this.$cintres.stop().fadeOut();
				_this.$crochetsVides.stop().fadeIn();
			}

			var whichFail = _this.whichFail( bodyPart, choice );
			_this.$moufette.hide();
			if ( whichFail == 'trop chaud' ) {
				_this.$moufetteTropChaud.css( { opacity : 1 } ).show();
				createjs.Sound.stop();
				createjs.Sound.play( _this.sounds.tropChaud.id );

			} else if ( whichFail == 'trop froid' ) {
				_this.$moufetteTropFroid.css( { opacity : 1 } ).show();
				createjs.Sound.stop();
				createjs.Sound.play( _this.sounds.tropFroid.id );

			} else if ( whichFail == 'enrhumee' ) {
				_this.$moufetteEnrhumee.css( { opacity : 1 } ).show();
				createjs.Sound.stop();
				createjs.Sound.play( _this.sounds.enrhumee.id );

			} else if ( whichFail == 'mouillee' ) {
				_this.$moufetteMouillee.css( { opacity : 1 } ).show();
				createjs.Sound.stop();
				createjs.Sound.play( _this.sounds.mouillee.id );

			}


			// ERROR THEN REVERT
			_this.$errorIcon.show();
			setTimeout( function () {

				_this.$errorIcon.fadeOut();

				_this.$moufette.show();
				_this.$moufetteOtherStates.hide();


				if ( bodyPart == 'body' ) {
					//_this.$bodyDraggables.show();
					_this.$bodyWearables.hide();
				} else if ( bodyPart == 'head' ) {
					//_this.$headDraggables.show();
					_this.$headWearables.hide();
				} else if ( bodyPart == 'feet' ) {
					//_this.$feetDraggables.show();
					_this.$feetWearables.hide();
				}

				$draggable.parents( '.item-container' ).show();
				_this.revertDraggable( $draggable );

				PANA.config.$activityBlocker.hide();


			}, _this.delayError );


			PANA.D2L.fireEvent( 'mauvaise réponse', _this.chapter + ':' + _this.activity );

			return false;
		}


	},
	validateAllAnswers   : function () {
		var _this = PANA.Games.chapitre2;

		var desiredAnswers = _this.info[ _this.seasons[ _this.currentSeasonIndex ] ];

		return (
			$.inArray( _this.currentBody, desiredAnswers.correctBody ) != -1 &&
			$.inArray( _this.currentHead, desiredAnswers.correctHead ) != -1 &&
			$.inArray( _this.currentFeet, desiredAnswers.correctFeet ) != -1
		);

	},
	whichFail            : function ( bodySection, bodyPart ) {
		var _this = PANA.Games.chapitre2;
		var season = _this.seasons[ _this.currentSeasonIndex ];

		PANA.debug.console.log( 'whichFail', bodySection, bodyPart );

		//'.container-chap2act1-automne-bonnet, .hiver-head, .container-chap2act1-ete-chapeau, .container-chap2act1-printemps-chapeau'
		//'.container-chap2act1-automne-manteau, .ete-body, .hiver-body, .printemps-body'
		//'.container-chap2act1-automne-bottes, .ete-pieds, .container-chap2act1-hiver-bottes, .container-chap2act1-printemps-bottes'

		if ( season == 'ete' ) {
			return 'trop chaud';
		} else if ( season == 'hiver' ) {
			return 'trop froid';
		} else if ( season == 'printemps' ) {

			if ( $.inArray( bodyPart, [ 'container-chap2act1-ete-chapeau', 'ete-pieds', 'ete-body' ] ) != -1 ) {

				return 'trop froid';

			} else if ( $.inArray( bodyPart, [ 'hiver-head', 'container-chap2act1-hiver-bottes', 'hiver-body' ] ) != -1 ) {

				return 'trop chaud';

			} else if ( $.inArray( bodyPart, [ 'container-chap2act1-automne-bonnet', 'container-chap2act1-automne-manteau' ] ) != -1 ) {
				// dédouble, playing it safe to have a list to confirm
				return 'mouillee';
			}

			return 'mouillee';

		} else if ( season == 'automne' ) {
			if ( $.inArray( bodyPart, [ 'container-chap2act1-ete-chapeau', 'ete-pieds', 'ete-body' ] ) != -1 ) {

				return 'trop froid';

			} else if ( $.inArray( bodyPart, [ 'container-chap2act1-hiver-bottes', 'hiver-head', 'hiver-body' ] ) != -1 ) {

				return 'trop chaud';

			} else if ( $.inArray( bodyPart, [ 'container-chap2act1-printemps-chapeau', 'printemps-body' ] != -1 ) ) {
				// dédouble, playing it safe to have a list to confirm
				return 'enrhumee';
			}
			// else
			return 'enrhumee';
		}
	},
	replayAnswers        : function () {
		var _this = PANA.Games.chapitre2;

		var state = PANA.Games.utils.getGameState( _this.chapter, _this.activity ),
			answers;

		if ( state ) {
			answers = PANA.Games.utils.getAnswers( _this.chapter, _this.activity );
		}

		if ( answers ) {

			if ( answers.length >= _this.seasons.length ) {
				_this.currentSeasonIndex = _this.seasons.length - 1;
			} else {
				_this.currentSeasonIndex = answers.length - 1;
			}

			_this.currentBody = answers[ answers.length - 1 ].currentBody;
			_this.currentFeet = answers[ answers.length - 1 ].currentFeet;
			_this.currentHead = answers[ answers.length - 1 ].currentHead;

		}
	},
	replaySingleAnswer   : function () {
		var _this = PANA.Games.chapitre2;

		var state = PANA.Games.utils.getGameState( _this.chapter, _this.activity ),
			answers;

		if ( state ) {
			answers = PANA.Games.utils.getAnswers( _this.chapter, _this.activity );
		}

		_this.currState = 'replaying';
		if ( typeof answers != 'undefined' && typeof answers[ _this.currentSeasonIndex ] != 'undefined' ) {

			var desiredAnswers = _this.info[ _this.seasons[ _this.currentSeasonIndex ] ],
				season = _this.seasons[ _this.currentSeasonIndex ];

			if ( $.inArray( answers[ _this.currentSeasonIndex ].currentBody, desiredAnswers.correctBody ) != -1 ) {
				var $imageOrClassBody = _this.$activityContainer.find( '.' + answers[ _this.currentSeasonIndex ].currentBody ),
					$additionalBody = $imageOrClassBody.find( '.item-container' );

				_this.$cintres.filter( '.' + season ).stop().show();
				_this.$crochetsVides.filter( '.' + season ).stop().show();


				_this.$allDraggables.filter( '[data-season="' + season + '"][data-where="body"]' ).parents( '.item-container' ).hide();


				$imageOrClassBody.add( $additionalBody ).show();
				_this.currentBody = answers[ _this.currentSeasonIndex ].currentBody;

			}

			if ( $.inArray( answers[ _this.currentSeasonIndex ].currentHead, desiredAnswers.correctHead ) != -1 ) {
				var $imageOrClassHead = _this.$activityContainer.find( '.' + answers[ _this.currentSeasonIndex ].currentHead ),
					$additionalHead = $imageOrClassHead.find( '.item-container' );

				_this.$allDraggables.filter( '[data-season="' + season + '"][data-where="head"]' ).parents( '.item-container' ).hide();

				$imageOrClassHead.add( $additionalHead ).show();
				_this.currentHead = answers[ _this.currentSeasonIndex ].currentHead;

			}

			if ( $.inArray( answers[ _this.currentSeasonIndex ].currentFeet, desiredAnswers.correctFeet ) != -1 ) {
				var $imageOrClassFeet = _this.$activityContainer.find( '.' + answers[ _this.currentSeasonIndex ].currentFeet ),
					$additionalFeet = $imageOrClassFeet.find( '.item-container' );

				_this.$allDraggables.filter( '[data-season="' + season + '"][data-where="feet"]' ).parents( '.item-container' ).hide();

				$imageOrClassFeet.add( $additionalFeet ).show();
				_this.currentFeet = answers[ _this.currentSeasonIndex ].currentFeet;

			}


			_this.checkIfDone();
		}

		_this.currState = 'normal';
	},
	showProperBackButton : function () {
		var _this = PANA.Games.chapitre2;

		if ( _this.currentSeasonIndex == 0 ) {
			_this.$btnBackActivity.hide();
			_this.$btnBackChapter.show();
		} else {
			_this.$btnBackActivity.show();
			_this.$btnBackChapter.hide();
		}
	}

}