;
PANA.Games.chapitre5activite1 = {
	init                 : function ( activityName, chapter, activity ) {
		var _this = PANA.Games.chapitre5activite1;

		_this.cleanUp();

		_this.options = {
			variableThreshold : '30%',
			snapTo            : true
		}

		_this.chapter = chapter;
		_this.activity = activity;
		_this.currentState = 'normal';


		_this.$activityContainer = $( '.activity' );
		_this.$mapFrame = _this.$activityContainer.find( '.map-frame' );
		_this.$map = _this.$mapFrame.find( '.map' );
		_this.$instructionsSection = $( '.chapter-instructions' );
		_this.$btnNextStep = _this.$activityContainer.find( '.btn-next-step' );
		_this.$icons = _this.$activityContainer.find( '.map-sprites' );

		_this.currentStep = 0;
		_this.currentView = 'zoomed-out';


		_this.$btnNextStep.hide();

		// Overloading the usual instruction button mechanism
		PANA.multimedia.audio.mainInstructions.unbindEvents();
		PANA.config.$headerInfoInstructionsPlayBtn.show();

		_this.adjustSize();

		_this.setupSounds();
		_this.bindEvents();
		_this.setupQuestion();


		_this.replayAnswers();
	},
	steps         : [
		{
			'id'               : 'bibliotheque',
			'instruction'      : 'Je cherche mon amie l’ourse. Où pourrait-elle être? Elle aime beaucoup les livres.',
			'audioInstruction' : 'chapitre_5_activite_1_1.mp3',
			'audioWin'         : 'chapitre_5_activite_1_1_success.mp3',
			'audioFail'        : 'chapitre_5_activite_1_fail_bibliotheque.mp3',
		},
		{
			'id'               : 'piscine',
			'instruction'      : 'Je cherche mon amie l’ourse. Où pourrait-elle être? Elle adore nager.',
			'audioInstruction' : 'chapitre_5_activite_1_2.mp3',
			'audioWin'         : 'chapitre_5_activite_1_2_success.mp3',
			'audioFail'        : 'chapitre_5_activite_1_fail_piscine.mp3',
		},
		{
			'id'               : 'hopital',
			'instruction'      : 'Je cherche mon amie l’ourse. Où pourrait-elle être? Elle y est pour rendre visite à son frère blessé.',
			'audioInstruction' : 'chapitre_5_activite_1_3.mp3',
			'audioWin'         : 'chapitre_5_activite_1_3_success.mp3',
			'audioFail'        : 'chapitre_5_activite_1_fail_hopital.mp3',
		},
		{
			'id'               : 'police',
			'instruction'      : 'Je cherche mon amie l’ourse. Où pourrait-elle être? Elle a besoin de leur aide pour retrouver son vélo.',
			'audioInstruction' : 'chapitre_5_activite_1_4.mp3',
			'audioWin'         : 'chapitre_5_activite_1_4_success.mp3',
			'audioFail'        : 'chapitre_5_activite_1_fail_police.mp3',
		},
		{
			'id'               : 'musee',
			'instruction'      : 'Je cherche mon amie l’ourse. Où pourrait-elle être? Elle adore l’art et les peintures.',
			'audioInstruction' : 'chapitre_5_activite_1_5.mp3',
			'audioWin'         : 'chapitre_5_activite_1_5_success.mp3',
			'audioFail'        : 'chapitre_5_activite_1_fail_musee.mp3',
		},
		{
			'id'               : 'pompiers',
			'instruction'      : 'Je cherche mon amie l’ourse. Où pourrait-elle être? Elle se demande comment on éteint un feu.',
			'audioInstruction' : 'chapitre_5_activite_1_6.mp3',
			'audioWin'         : 'chapitre_5_activite_1_6_success.mp3',
			'audioFail'        : 'chapitre_5_activite_1_fail_pompiers.mp3',
		},
		{
			'id'               : 'recyclage',
			'instruction'      : 'Je cherche mon amie l’ourse. Où pourrait-elle être?  Elle adore recycler.',
			'audioInstruction' : 'chapitre_5_activite_1_7.mp3',
			'audioWin'         : 'chapitre_5_activite_1_7_success.mp3',
			'audioFail'        : 'chapitre_5_activite_1_fail_recyclage.mp3',
		},
		{
			'id'               : 'parc',
			'instruction' : 'Je cherche mon amie l’ourse. Où pourrait-elle être? Elle aime les balançoires. ',
			'audioInstruction' : 'chapitre_5_activite_1_8.mp3',
			'audioWin'         : 'chapitre_5_activite_1_8_success.mp3',
			'audioFail'        : 'chapitre_5_activite_1_fail_parc.mp3',
		}
	],
	cleanUp       : function () {
		var _this = PANA.Games.chapitre5activite1;


		// unbinding
		if ( typeof _this.$activityContainer != 'undefined' ) {
			_this.$activityContainer.off( 'click' );
			_this.$activityContainer.off( 'mouseenter mouseleave touchstart touchend' );
		}

		if ( typeof _this.$btnNextStep != 'undefined' ) {
			_this.$btnNextStep.off( 'click' )
		}

		window.removeEventListener( 'resize', _this.debouncedResize );

		PANA.config.$headerInfoInstructionsPlayBtn.off( 'click.chap5instructions' );
		PANA.multimedia.audio.mainInstructions.bindEvents();

		var keysToClean = [
			'options',
			'currentState',
			'sounds',
			'$btnNextStep',
			'$icons',
			'$instructionsSection',
			'$map',
			'$mapFrame',
			'currentStep',
			'currentStepId',
			'currentView',

			'$activityContainer',
		];

		PANA.UTILS.clearFromMemory( _this, keysToClean );
	},
	adjustSize    : function () {
		var _this = PANA.Games.chapitre5activite1;

		var windowHeight = $( window ).height(),
			windowWidth = $( window ).width(),
			headerHeight = $( '#header-avatar' ).outerHeight(),
			mapHeight = $( '.map-frame' ).outerHeight();

		$( '.activity-container' ).css( {
			width : '100%'
		} );

		if ( headerHeight + mapHeight > windowHeight ) {
			var desiredWidth = ( windowHeight - headerHeight  ) / 1080 * 1920;
			$( '.activity-container' ).css( {
				width : desiredWidth
			} );
		}
		//
		//_this.$mapFrame.css({
		//	marginTop: headerHeight - 40
		//})

	},
	setupSounds   : function () {
		var _this = PANA.Games.chapitre5activite1;


		_this.sounds = {};

		var soundGeneralWin = _this.$activityContainer.data( 'sound-win' ) || false,
			soundGeneralFail = _this.$activityContainer.data( 'sound-fail' ) || false,
			soundGeneralSuccess = _this.$activityContainer.data( 'sound-success' ) || false;

		_this.sounds.win = !soundGeneralWin ? false : createjs.Sound.registerSound( soundGeneralWin, _this.chapter + '_' + _this.activity + '_' + 'win', 0, PANA.config.strAudioFolder );
		_this.sounds.fail = !soundGeneralFail ? false : createjs.Sound.registerSound( soundGeneralFail, _this.chapter + '_' + _this.activity + '_' + 'fail', 0, PANA.config.strAudioFolder );
		_this.sounds.success = !soundGeneralSuccess ? false : createjs.Sound.registerSound( soundGeneralSuccess, _this.chapter + '_' + _this.activity + '_' + 'success', 0, PANA.config.strAudioFolder );


		_this.sounds.misc = {};


		for ( var i = 0; i < _this.steps.length; i++ ) {
			var thisStep = _this.steps[ i ];

			_this.sounds.misc[ thisStep.id ] = {};

			_this.sounds.misc[ thisStep.id ][ 'win' ] = createjs.Sound.registerSound( thisStep.audioWin, _this.chapter + '_' + _this.activity + '_' + thisStep.id + '_win', 0, PANA.config.strAudioFolder );
			_this.sounds.misc[ thisStep.id ][ 'fail' ] = createjs.Sound.registerSound( thisStep.audioFail, _this.chapter + '_' + _this.activity + '_' + thisStep.id + '_fail', 0, PANA.config.strAudioFolder );
			_this.sounds.misc[ thisStep.id ][ 'instructions' ] = createjs.Sound.registerSound( thisStep.audioInstruction, _this.chapter + '_' + _this.activity + '_' + thisStep.id + '_instructions', 0, PANA.config.strAudioFolder );
		}

	},
	setupQuestion : function () {
		var _this = PANA.Games.chapitre5activite1;

		_this.$instructionsSection.html( _this.steps[ _this.currentStep ].instruction );
		_this.currentStepId = _this.steps[ _this.currentStep ].id;

	},
	bindEvents    : function () {
		var _this = PANA.Games.chapitre5activite1;

		_this.$activityContainer.on( 'click', '.map-sprites, .building-target', function ( e ) {

			if ( _this.currentView == 'zoomed-out' ) {
				_this.validateAnswer( $( this ) );
			} else {

			}
		} );

		_this.$activityContainer.on( 'mouseenter mouseleave touchstart touchend', '.building-target', function ( e ) {

			var $this = $( this ),
				thisBuilding = $this.data( 'place' ),
				$partnerIcon = _this.$icons.filter( '[data-place="' + thisBuilding + '"]' );

			if ( e.type == 'mouseenter' || e.type == 'touchstart' ) {
				$partnerIcon.addClass( 'hover' );
			} else {
				$partnerIcon.removeClass( 'hover' );
			}
		} );

		_this.$btnNextStep.on( 'click', function ( e ) {
			e.preventDefault();

			$( this ).hide();
			createjs.Sound.stop();

			_this.clearActivityClasses();
			_this.$map.removeClass( 'zoomed-in' )
			_this.currentStep++;
			_this.setupQuestion();

		} );


		PANA.config.$headerInfoInstructionsPlayBtn.on( 'click.chap5instructions', function ( e ) {
			e.preventDefault();


			createjs.Sound.stop();
			createjs.Sound.play( _this.sounds.misc[ _this.currentStepId ].instructions.id );

		} );


		// Debounced so that the browser doesn't go crazy during resize
		_this.debouncedResize = PANA.UTILS.debounce( function () {
			_this.adjustSize();
		}, 250 );
		window.addEventListener( 'resize', _this.debouncedResize );

	},
	validateAnswer : function ( $clicked ) {
		var _this = PANA.Games.chapitre5activite1;

		var clickedPlace = $clicked.data( 'place' );

		if ( _this.currentStepId == clickedPlace ) {
			_this.showSuccess( $clicked );
			_this.saveAnswer( clickedPlace );
			PANA.D2L.fireEvent( 'bonne réponse', _this.chapter + ':' + _this.activity );

		} else {
			_this.showFail( $clicked );
			PANA.D2L.fireEvent( 'mauvaise réponse', _this.chapter + ':' + _this.activity );
		}

	},
	clearActivityClasses : function ( strExcept ) {
		var _this = PANA.Games.chapitre5activite1;

		var arrPlaces = [ 'bibliotheque', 'piscine', 'hopital', 'police', 'musee', 'pompiers', 'recyclage', 'parc' ],
			thisPlace = typeof strExcept != 'undefined' ? strExcept : '';


		for ( var i = 0; i < arrPlaces.length; i++ ) {
			if ( arrPlaces[ i ] != thisPlace ) {
				_this.$map.removeClass( 'active-' + arrPlaces[ i ] );
			}
		}
	},
	showSuccess          : function ( $clicked ) {
		var _this = PANA.Games.chapitre5activite1;

		var thisPlace = $clicked.data( 'place' );

		_this.clearActivityClasses( thisPlace );

		_this.currentView == 'zommed-in';
		_this.$map.addClass( 'active-' + thisPlace ).addClass( 'zoomed-in' );


		if ( _this.sounds.misc[ _this.currentStepId ].win && _this.currentState != 'replaying' ) {

			createjs.Sound.stop();
			createjs.Sound.play( _this.sounds.misc[ _this.currentStepId ].win.id );
		}

		if ( !_this.checkIsDone() ) {
			_this.$btnNextStep.delay( 1000 * 15 ).fadeIn();
		}


	},
	showFail             : function ( $clicked ) {
		var _this = PANA.Games.chapitre5activite1;

		var clickedPlace = $clicked.data( 'place' );


		if ( _this.sounds.misc[ clickedPlace ].fail && _this.currentState != 'replaying' ) {
			createjs.Sound.stop();
			createjs.Sound.play( _this.sounds.misc[ clickedPlace ].fail.id );
		}
	},
	checkIsDone          : function () {
		var _this = PANA.Games.chapitre5activite1;

		if ( ( _this.currentStep + 1 ) === _this.steps.length ) {
			PANA.Games.utils.setGameState( _this.chapter, _this.activity, 'win' );

			_this.endAnimation();

			PANA.NAV.showNav();
			return true;
		} else {
			PANA.Games.utils.setGameState( _this.chapter, _this.activity, 'begun' );
			return false;
		}
	},
	saveAnswer           : function ( clickedPlace ) {
		var _this = PANA.Games.chapitre5activite1;

		PANA.Games.utils.saveAnswer( _this.chapter, _this.activity, clickedPlace );

	},
	replayAnswers        : function () {
		var _this = PANA.Games.chapitre5activite1;

		var state = PANA.Games.utils.getGameState( _this.chapter, _this.activity ),
			answers;

		if ( state ) {
			answers = PANA.Games.utils.getAnswers( _this.chapter, _this.activity );


			if ( state == 'win' ) {
				_this.currentStep = answers.length;
				_this.showSuccess( $( '.map-sprites[data-place="' + answers[ answers.length - 1 ] + '"]' ) );

				_this.endAnimation();

				PANA.NAV.showNav();

			} else if ( answers ) {

				_this.currentState = 'replaying';

				_this.currentStep = answers.length;
				_this.setupQuestion();

				_this.currentState = 'normal';
			}

		}
	},
	endAnimation         : function () {
		var _this = PANA.Games.chapitre5activite1;

		$( '.chap5act1-chap-5-ourse-parc' ).delay( 1250 ).fadeIn();
	},
	getStepIndexById     : function ( id ) {
		var _this = PANA.Games.chapitre5activite1;

		for ( var i = 0; i < _this.steps.length; i++ ) {
			var thisStep = _this.steps[ i ];
			if ( typeof thisStep[ 'id' ] != 'undefined' && id == thisStep[ 'id' ] ) {
				return i;
			}
		}
	}
}