;
PANA.Games.chapitre1activite4 = {
	init                 : function ( activityName, chapter, activity ) {
		var _this = PANA.Games.chapitre1activite4;

		_this.cleanUp();


		_this.options = {
			variableThreshold : '30%',
			snapTo            : true
		}

		_this.chapter = chapter;
		_this.activity = activity;
		_this.currentState = 'normal';


		_this.$activityContainer = $( '.activity' );
		_this.$moneyLeftContainer = _this.$activityContainer.find( '.money-left' );
		_this.$moneyLeftCounter = _this.$moneyLeftContainer.find( '.current-amount' );
		_this.currentSpent = 0;
		_this.currentLeft = 1000; // Cents to not worry about floats
		_this.activityStatus = false;

		_this.$btnRecommencer = _this.$activityContainer.find( '.recommencer' );


		_this.cleanupSounds();
		_this.setupSounds();
		_this.setupDraggables();
		_this.setupDroppables();

		_this.replayAnswers();
		_this.adjustCoinStack();

		_this.bindEvents();

	},
	cleanUp              : function () {
		var _this = PANA.Games.chapitre1activite4;

		if ( typeof _this.$items != 'undefined' ) {
			Draggable.get( '.sprites-pieces' ).kill();
		}

		// Not 100% sure about this one
		if ( typeof _this.$draggables != 'undefined' ) {
			Draggable.get( _this.$draggables ).kill();
		}

		var keysToClean = [
			'options',
			'sounds',
			'currentState',
			'$moneyLeftContainer',
			'$moneyLeftCounter',
			'currentSpent',
			'currentLeft',
			'activityStatus',
			'$items',
			'$coinStacks',
			'itemCalcs'
		];
		PANA.UTILS.clearFromMemory( _this, keysToClean );


		//unbinding
		$( '.activity' ).find( '.recommencer' ).off( 'click' );

		PANA.UTILS.clearFromMemory( _this, [ '$activityContainer', '$btnRecommencer' ] );

	},
	bindEvents           : function () {
		var _this = PANA.Games.chapitre1activite4;

		_this.$btnRecommencer.on( 'click', _this.resetGame );
	},
	setupSounds          : function () {
		var _this = PANA.Games.chapitre1activite4;


		_this.sounds = {};


		var soundGeneralWin = _this.$activityContainer.data( 'sound-win' ) || false,
			soundGeneralFail = _this.$activityContainer.data( 'sound-fail' ) || false,
			soundGeneralSuccess = _this.$activityContainer.data( 'sound-success' ) || false,
			soundTooMuch = _this.$activityContainer.data( 'sound-too-much' ) || false;

		_this.sounds.win = !soundGeneralWin ? false : createjs.Sound.registerSound( soundGeneralWin, _this.chapter + '_' + _this.activity + '_' + 'win', 0, PANA.config.strAudioFolder );
		_this.sounds.fail = !soundGeneralFail ? false : createjs.Sound.registerSound( soundGeneralFail, _this.chapter + '_' + _this.activity + '_' + 'fail', 0, PANA.config.strAudioFolder );
		_this.sounds.success = !soundGeneralSuccess ? false : createjs.Sound.registerSound( soundGeneralSuccess, _this.chapter + '_' + _this.activity + '_' + 'success', 0, PANA.config.strAudioFolder );
		_this.sounds.tooMuch = !soundTooMuch ? false : createjs.Sound.registerSound( soundTooMuch, _this.chapter + '_' + _this.activity + '_' + 'tooMuch', 0, PANA.config.strAudioFolder );


	},
	cleanupSounds        : function () {
		var _this = PANA.Games.chapitre1activite4;


	},
	setupDroppables      : function () {
		var _this = PANA.Games.chapitre1activite4;

		// Setup tyhe stuff for counting
		_this.itemCalcs = {};

		_this.$items.each( function ( index ) {
			var $this = $( this ),
				itemPrice = $this.data( 'item-price' );

			_this.itemCalcs[ index ] = {
				itemIndex      : index,
				itemPrice      : itemPrice,
				itemTotalSpent : 0,
				itemTotalLeft  : itemPrice,
				purchased      : false
			}
		} );

	},
	setupDraggables      : function () {
		var _this = PANA.Games.chapitre1activite4;

		_this.$items = _this.$activityContainer.find( '.item' );
		_this.$coinStacks = _this.$activityContainer.find( '.activity-money-bank' );


		Draggable.create( '.sprites-pieces', {
			type      : "x,y",
			onPress   : function () {
				StartX = this.x;
				StartY = this.y;
			},
			onRelease : function () {
				var $draggableContainer = $( this.target ).parents( '.activity-money-bank' ),
					$draggable = $( this.target ),
					_draggable = this,
					originalPosition = $draggableContainer.data( 'originalPosition' ),
					isMatch = false,
					draggableValue = parseInt( $draggableContainer.data( 'value' ) ),
					$matchingDroppable,
					$attemptedDroppable,
					hit = false;

				// Draggable's hitTest checks if it overlaps based on the threshold set
				// More info at http://greensock.com/docs/#/HTML5/GSAP/Utils/Draggable/hitTest/
				_this.$items.each( function () {

					// If they overlap AND aren't disabled
					if ( _draggable.hitTest( $( this ), _this.options.variableThreshold ) && !$( this ).hasClass( 'disabled' ) ) {
						hit = true;
						// Setting this aside
						$attemptedDroppable = $( this );

						//if (_this.compareDragDrop($draggable, $(this))) {
						//	isMatch = true;
						//	$matchingDroppable = $(this);
						//}

						var itemIndex = _this.$items.index( $attemptedDroppable ),
							thisItemCalcs = _this.itemCalcs[ itemIndex ];

						if ( _this.itemCalcs[ itemIndex ].purchased == true ) {
							return false;
						}


						if ( thisItemCalcs.itemTotalLeft < draggableValue ) {
							// Too much money for the item

							var $failIcon = $( _this.failIcon ).appendTo( $attemptedDroppable );

							setTimeout( function () {
								$failIcon.fadeOut( function () {
									$( this ).remove();
								} );
							}, 500 );

							if ( _this.currState != 'replaying' ) {
								//_this.sounds.tooMuch.play();
								createjs.Sound.stop();
								createjs.Sound.play( _this.sounds.tooMuch.id );
							}

							_this.revertDraggable( $draggable );
							return false;
						}

						thisItemCalcs.itemTotalSpent = thisItemCalcs.itemTotalSpent + draggableValue;
						thisItemCalcs.itemTotalLeft = thisItemCalcs.itemPrice - thisItemCalcs.itemTotalSpent;
						$attemptedDroppable.find( '.current-amount' ).find( '.amount' ).html( PANA.UTILS.formatFrenchMoney( thisItemCalcs.itemTotalLeft / 100 ) );


						_this.currentLeft = _this.currentLeft - draggableValue;
						_this.$moneyLeftCounter.html( PANA.UTILS.formatFrenchMoney( _this.currentLeft / 100 ) );

						if ( thisItemCalcs.itemTotalLeft == 0 ) {
							// WIN EXERCISE!
							var droppableId = $attemptedDroppable.data( 'droppable-id' ),
								strAchete = 'castor' == droppableId ? 'Achetée' : 'Acheté';

							$attemptedDroppable
								.addClass( 'win won-' + droppableId )
								.append( _this.winStars )
								.find( '.current-amount' ).find( '.amount' ).html( strAchete );

							_this.$coinStacks.fadeOut();

							_this.$activityContainer.addClass( 'win' );
							_this.activityStatus = 'win';

							if ( _this.currState != 'replaying' ) {
								//_this.sounds.win.play();
								createjs.Sound.stop();
								createjs.Sound.play( _this.sounds.win.id );
							}

							_this.$btnRecommencer.fadeIn();

							_this.deactivateDraggables();

							_this.checkIsDone( true );

							PANA.D2L.fireEvent( 'acheté une suprise', _this.chapter + ':' + _this.activity );

						} else if ( _this.currentLeft <= 0 ) {
							// no more money
							_this.$coinStacks.fadeOut();

							_this.$activityContainer.addClass( 'fail' );
							$draggable.addClass( 'disabled' );
							_this.deactivateDraggables();

							var $failIcon = $( _this.failIcon ).appendTo( _this.$activityContainer.find( '.activity-container' ) );

							setTimeout( function () {
								$failIcon.fadeOut( function () {
									$( this ).remove();
								} );
							}, 1000 );

							if ( _this.currState != 'replaying' ) {
								//_this.sounds.fail.play();
								createjs.Sound.stop();
								createjs.Sound.play( _this.sounds.fail.id );

								PANA.D2L.fireEvent( 'tout dépensé sans rien acheter', _this.chapter + ':' + _this.activity );
							}

							_this.$btnRecommencer.fadeIn();
							_this.checkIsDone( true );
						} else {

							$draggable.fadeOut( function () {
								_this.adjustCoinStack();
							} );
							//	_this.revertDraggable($(this.target ));
							_this.checkIsDone( false );


							if ( _this.currentState != 'replaying' ) {
								//_this.sounds.success.play();

								createjs.Sound.stop();
								createjs.Sound.play( _this.sounds.success.id );

								PANA.D2L.fireEvent( 'dépensé des sous', _this.chapter + ':' + _this.activity );
							}


						}

						_this.saveAnswer( $draggableContainer.data( 'draggable-id' ), $attemptedDroppable.data( 'droppable-id' ) );
						// Break out of the $.each loop
						return false;

					}


				} );


				if ( !hit ) {
					_this.revertDraggable( $( this.target ) );
				}

			} // onRelease
		} );

	},
	deactivateDraggables : function () {
		var _this = PANA.Games.chapitre1activite4;

		_this.$activityContainer.find( '.activity-money-bank' ).find( '.sprites-pieces.real-one' ).not( 'disabled' ).each( function ( i, el ) {
			Draggable.get( $( this ) ).disable();
		} );

	},
	adjustCoinStack      : function () {
		var _this = PANA.Games.chapitre1activite4;

		_this.$coinStacks.each( function ( index ) {
			var $this = $( this ),
				thisValue = $this.data( 'value' ),
				$existingCoins = $this.find( '.sprites-pieces' ),
				$realOne = $existingCoins.filter( '.real-one' ),
			//numExisting = typeof $this.data( 'numExisting' ) != 'undefined' ? parseInt( $this.data( 'numExisting' ) ) - 1 : 5,
				numPotentialCoins = Math.floor( _this.currentLeft / thisValue ),
				currBott = 0,
				currZindex = 1,
				numDesiredCoins = Math.min( numPotentialCoins, 5/* ,  numExisting */ ); // Was trying to see if I could never "Add" but it ended up looking weird


			if ( numDesiredCoins == 0 ) {
				$existingCoins.remove();
			} else if ( numDesiredCoins == 1 ) {

				TweenLite.to( $realOne, 0, { x : 0, y : 0 } );
				$existingCoins.not( '.real-one' ).remove();
				$realOne.css( {
					bottom : 0
				} ).show();
			} else {
				numDesiredCoins--;
				$existingCoins.not( '.real-one' ).remove();
				for ( var i = 0; i < numDesiredCoins; i++ ) {
					var $currCoin = $( _this.coinSprites[ thisValue ] ).appendTo( $this );
					$currCoin.css( {
						'bottom'  : currBott + '%',
						'z-index' : currZindex
					} );

					currBott += 5; // was 3
					currZindex++;
				}

				TweenLite.to( $realOne, 0, { x : 0, y : 0 } );
				$realOne.css( 'bottom', currBott + '%' );

				$realOne.show();
			}


			if ( thisValue > _this.currentLeft ) {
				$this.addClass( 'disabled' );
				var $potentialDraggable = Draggable.get( $this );
				if ( typeof $potentialDraggable != 'undefined' ) {
					$potentialDraggable.disable();
				}
			}

		} );

	},
	compareDragDrop      : function ( $draggable, $droppable ) {
		var draggableId = $draggable.data( 'draggable-id' ),
			droppableId = $droppable.data( 'droppable-id' );

		return draggableId == droppableId;
	},
	revertDraggable      : function ( $draggable ) {
		TweenLite.to( $draggable, 0.5, { x : 0, y : 0, ease : 'Elastic' } );
	},
	showSuccess          : function ( $draggable, $droppable ) {
		var _this = PANA.Games.chapitre1activite4;

		if ( _this.options.snapTo ) {
			var droppablePosition = $droppable.data( 'originalPosition' );

			TweenLite.to( $draggable, 0.5, {
				left       : droppablePosition.left,
				top        : droppablePosition.top,
				x          : 0,
				y          : 0,
				ease       : 'Elastic',
				onComplete : function () {
				}
			} );

			// Need to get the instance of the Draggable object rather than the jQuery object.
			Draggable.get( $draggable ).disable();

			$draggable.addClass( 'success' )
			$droppable.addClass( 'disabled' ).hide();

			if ( _this.sounds.win && _this.currentState != 'replaying' ) {
				//_this.sounds.win.play();
				createjs.Sound.stop();
				createjs.Sound.play( _this.sounds.win.id );
			}

			_this.checkIsDone();

		}

	},
	showFail             : function ( $draggable, $attemptedDroppable ) {
		var _this = PANA.Games.chapitre1activite4;


		if ( _this.sounds.fail ) {
			//_this.sounds.fail.play();

			createjs.Sound.stop();
			createjs.Sound.play( _this.sounds.fail.id );
		}
	},
	checkIsDone          : function ( isDone ) {
		var _this = PANA.Games.chapitre1activite4;

		if ( isDone ) {
			PANA.Games.utils.setGameState( _this.chapter, _this.activity, 'win' );

			PANA.NAV.showNav();
		} else {
			PANA.Games.utils.setGameState( _this.chapter, _this.activity, 'begun' );
		}
	},
	saveAnswer           : function ( draggableId, droppableId ) {
		var _this = PANA.Games.chapitre1activite4;

		PANA.Games.utils.saveAnswer( _this.chapter, _this.activity, {
			draggableId : draggableId,
			droppableId : droppableId
		} );

	},
	replayAnswers        : function () {
		var _this = PANA.Games.chapitre1activite4;

		var state = PANA.Games.utils.getGameState( _this.chapter, _this.activity ),
			answers;

		if ( state ) {
			answers = PANA.Games.utils.getAnswers( _this.chapter, _this.activity );
		}

		if ( answers ) {
			_this.currentState = 'replaying';
			for ( var i = 0; i < answers.length; i++ ) {
				var draggableid = answers[ i ].draggableId,
					droppableId = answers[ i ].droppableId,
					$attemptedDroppable = _this.$items.filter( '[data-droppable-id="' + droppableId + '"]' ),
					$draggableContainer = _this.$coinStacks.filter( '[data-draggable-id="' + draggableid + '"]' ),
					$draggable = $draggableContainer.find( '.real-one' ),
					itemIndex = _this.$items.index( $attemptedDroppable ),
					thisItemCalcs = _this.itemCalcs[ itemIndex ],
					draggableValue = parseInt( $draggableContainer.data( 'value' ) );


				if ( thisItemCalcs.itemTotalLeft < draggableValue ) {
					// Too much money for the item

				} else {

					thisItemCalcs.itemTotalSpent = thisItemCalcs.itemTotalSpent + draggableValue;
					thisItemCalcs.itemTotalLeft = thisItemCalcs.itemPrice - thisItemCalcs.itemTotalSpent;
					$attemptedDroppable.find( '.current-amount' ).find( '.amount' ).html( PANA.UTILS.formatFrenchMoney( thisItemCalcs.itemTotalLeft / 100 ) );

					_this.currentLeft = _this.currentLeft - draggableValue;
					_this.$moneyLeftCounter.html( PANA.UTILS.formatFrenchMoney( _this.currentLeft / 100 ) );


					if ( thisItemCalcs.itemTotalLeft == 0 ) {
						// WIN EXERCISE!

						var strAchete = 'castor' == droppableId ? 'Achetée' : 'Acheté';

						$attemptedDroppable
							.addClass( 'win won-' + droppableId )
							.append( _this.winStars )
							.find( '.current-amount' ).find( '.amount' ).html( strAchete );

						_this.$coinStacks.fadeOut();

						_this.$activityContainer.addClass( 'win' );
						_this.activityStatus = 'win';
						//_this.sounds.win.play();

						_this.$btnRecommencer.fadeIn();

						_this.deactivateDraggables();


						_this.checkIsDone( true );
					} else if ( _this.currentLeft <= 0 ) {
						// no more money
						_this.$coinStacks.fadeOut();

						_this.$activityContainer.addClass( 'fail' );
						$draggable.addClass( 'disabled' );
						_this.deactivateDraggables();

						_this.$btnRecommencer.fadeIn();
						_this.checkIsDone( true );

					} else {


						$draggable.hide();
						_this.adjustCoinStack();
						//	_this.revertDraggable($(this.target ));

					}

				}
			}

			_this.currentState = 'normal';
		}

	},
	resetGame            : function () {
		var _this = PANA.Games.chapitre1activite4;

		PANA.debug.console.log( 'Resetting' );
		PANA.Games.utils.resetAnswers( _this.chapter, _this.activity );
		PANA.Games.utils.resetGameState( _this.chapter, _this.activity );


		PANA.NAV.loadPage( PANA.config.$ajaxContainer, _this.chapter + ':' + _this.activity );

		PANA.D2L.fireEvent( 'reset d\'activité', _this.chapter + ':' + _this.activity );

	},
	winStars             : '<img class="chap1act4-etoiles" alt="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAXwAAACFAQMAAABiw9HsAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAAB1JREFUeNrtwTEBAAAAwqD1T20IX6AAAAAAAAC4DRl1AAEcoVgsAAAAAElFTkSuQmCC">',
	coinSprites          : {
		5   : '<img class="chap1act4-piece-5 sprites-pieces" alt="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGYAAABmAQMAAAADEXYhAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAABRJREFUeNpjYBgFo2AUjIJRMFgAAAWUAAF2+LisAAAAAElFTkSuQmCC">',
		10  : '<img class="chap1act4-piece-10 sprites-pieces" alt="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEwAAABMAQMAAAD9WcNoAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAABBJREFUeNpjYBgFo2AUUAIAA0QAAR6Y1TgAAAAASUVORK5CYII=">',
		25  : '<img class="chap1act4-piece-25 sprites-pieces" alt="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHkAAAB5AQMAAAAnNBwKAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAABdJREFUeNpjYBgFo2AUjIJRMApGwYgFAAgJAAEsGULoAAAAAElFTkSuQmCC">',
		100 : '<img class="chap1act4-piece-1 sprites-pieces" alt="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIgAAACGAQMAAAA8flBhAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAABlJREFUeNrtwTEBAAAAwqD1T20KP6AAAHgZCWwAAbzb1pAAAAAASUVORK5CYII=">',
		200 : '<img class="chap1act4-piece-2 sprites-pieces" alt="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIwAAACMAQMAAACUDtN9AAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAABlJREFUeNrtwTEBAAAAwqD1T20JT6AAAOBrCmQAAb3x7N4AAAAASUVORK5CYII=">'
	},
	failIcon             : '<img class="gen-fail-x" alt="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKIAAACiAQMAAAD4PIRYAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAABpJREFUeNrtwQENAAAAwqD3T20PBxQAAABvBg3sAAHckWr/AAAAAElFTkSuQmCC">'

}