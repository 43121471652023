;
PANA.Games.chapitre1activite3 = {
	init          : function ( activityName, chapter, activity ) {
		var _this = PANA.Games.chapitre1activite3;

		_this.cleanUp();


		_this.options = {
			snapTo : true
		}

		_this.chapter = chapter;
		_this.activity = activity;
		_this.currentSelection = false;
		_this.animationDelay = 500;
		_this.durationRevealBadAnswer = 500;
		_this.failDuration = 1000; // duration to show the Fail X icon in milliseconds

		_this.currZindex = 1000;
		_this.currState = 'normal';

		_this.$activityContainer = $( '.activity' );

		_this.cleanupSounds();
		_this.setupSounds();
		_this.setupMemory();


		_this.bindEvents();

		_this.replayAnswers();

	},
	cleanUp       : function () {
		var _this = PANA.Games.chapitre1activite3;
		var keysToClean = [
			'options',
			'sounds',
			'currState',
			'currentSelection',
			'durationRevealBadAnswer',
			'failDuration',
			'currZindex',
			'currState',
			'$allPieces',
			'animationDelay',
			'$activityContainer',
		];
		PANA.UTILS.clearFromMemory( _this, keysToClean );

	},
	setupSounds   : function () {
		var _this = PANA.Games.chapitre1activite3;


		_this.sounds = {};


		var soundGeneralWin = _this.$activityContainer.data( 'sound-win' ) || false,
			soundGeneralFail = _this.$activityContainer.data( 'sound-fail' ) || false,
			soundGeneralSuccess = _this.$activityContainer.data( 'sound-success' ) || false;

		_this.sounds.win = !soundGeneralWin ? false : createjs.Sound.registerSound( soundGeneralWin, _this.chapter + '_' + _this.activity + '_' + 'win', 0, PANA.config.strAudioFolder );
		_this.sounds.fail = !soundGeneralFail ? false : createjs.Sound.registerSound( soundGeneralFail, _this.chapter + '_' + _this.activity + '_' + 'fail', 0, PANA.config.strAudioFolder );
		_this.sounds.success = !soundGeneralSuccess ? false : createjs.Sound.registerSound( soundGeneralSuccess, _this.chapter + '_' + _this.activity + '_' + 'success', 0, PANA.config.strAudioFolder );
	},
	cleanupSounds : function () {
		var _this = PANA.Games.chapitre1activite3;


	},
	bindEvents    : function () {
		var _this = PANA.Games.chapitre1activite3;

	},
	setupMemory   : function () {
		var _this = PANA.Games.chapitre1activite3;

		_this.$allPieces = _this.$activityContainer.find( '.activity-memory' );

		_this.$activityContainer.data( 'originalWidth', _this.$activityContainer.outerWidth() );
		_this.$activityContainer.data( 'originalHeight', _this.$activityContainer.outerHeight() );


		_this.$activityContainer.on( 'click', '.activity-memory', function ( e ) {
			if ( $( this ).hasClass( 'disabled' ) || $( this ).hasClass( 'currently-failed' ) || _this.currState == 'paused' ) {
				return false;
			}

			var $this = $( this ),
				clickedId = $this.data( 'piece-id' ),
				clickedIndex = _this.$allPieces.index( $this );


			if ( _this.currentSelection && _this.currentSelection.clickedIndex != clickedIndex ) {
				var $currSelected = _this.$allPieces.eq( _this.currentSelection.clickedIndex ),
					isMatch = _this.comparePiece( $this, $currSelected );

				$this.addClass( 'clicked' );
				_this.currState = 'paused';

				if ( isMatch ) {
					_this.showSuccess( $this, $currSelected );
				} else {
					_this.showFail( $this, $currSelected );
				}

				_this.saveAnswer( {
					piece1 : {
						clickedIndex : clickedIndex,
						pieceId      : clickedId
					},
					piece2 : _this.currentSelection
				} );


				_this.currentSelection = false;
			} else if ( _this.currentSelection && _this.currentSelection.clickedIndex == clickedIndex ) {
				// Re-clicking an active one

				$this.removeClass( 'clicked' );

				_this.currentSelection = false;

			} else {
				$this.addClass( 'clicked' );
				_this.currentSelection = {
					clickedIndex : clickedIndex,
					pieceId      : clickedId
				};
			}
		} );

	},
	resizeAdjust  : function () {
		var _this = PANA.Games.chapitre1activite3;

		//_this.$activityContainer.find( '.success.disabled.moved' ).each( function () {
		//	var $this = $( this ),
		//		$matchedPiece = $this.data( 'matchedPiece' );
		//
		//	$this.find( '.answer' ).css( {
		//		left : $matchedPiece.offset().left - $this.offset().left + 5,
		//		top  : $matchedPiece.offset().top - $this.offset().top + 5,
		//	} );
		//} );


	},
	comparePiece  : function ( $piece1, $piece2 ) {
		var piece1Id = $piece1.data( 'piece-id' ),
			piece2Id = $piece2.data( 'piece-id' );

		return piece1Id == piece2Id;
	},
	showSuccess   : function ( $piece1, $piece2 ) {
		var _this = PANA.Games.chapitre1activite3;

		if ( _this.options.snapTo ) {

			var $answer = $piece1.find( '.answer' ),
				pieceId = $piece1.data( 'piece-id' );
			animationDelay = _this.currState == 'replaying' ? 0 : _this.animationDelay / 1000
			_this.currZindex += 10;
			$piece2.css( 'z-index', _this.currZindex ).addClass( 'clicked' );
			_this.currZindex += 10;
			$piece1.add( $answer ).addClass( 'clicked shadow moved' ).css( 'z-index', _this.currZindex );

			$piece1.data( 'matchedPiece', $piece2 );
			$piece2.data( 'matchedPiece', $piece1 );

			var factor = parseInt( $( window ).outerWidth() * .02 );
			var vertOffset = Math.floor( Math.random() * factor ) + 1; // this will get a number between 1 and 5;
			vertOffset *= Math.floor( Math.random() * 2 ) == 1 ? 1 : -1; // this will add minus sign in 50% of cases

			var horiOffset = Math.floor( Math.random() * factor ) + 1; // this will get a number between 1 and 5;
			horiOffset *= Math.floor( Math.random() * 2 ) == 1 ? 1 : -1; // this will add minus sign in 50% of cases


			// The dollar shadow is angular, the other ones are round
			if ( pieceId == 1 ) {
				var $shadow = $( '<img class="coin-shadow coin-shadow-loonie" src="_/images/shadow-loonie.png" />' ).appendTo( $piece1 ).css( 'z-index', _this.currZindex - 5 ).hide();
			} else {
				var $shadow = $( '<img class="coin-shadow coin-shadow-round" src="_/images/shadow-coin.png" />' ).appendTo( $piece1 ).css( 'z-index', _this.currZindex - 5 ).hide();
			}


			TweenLite.to( $answer.add( $shadow ), animationDelay, {
				delay : 0, // give the CSS transition time to finish
				left  : $piece2.offset().left - $piece1.offset().left + horiOffset,
				top   : $piece2.offset().top - $piece1.offset().top + vertOffset,

				//ease      : 'EaseInOut',
				onComplete : function () {
					$answer.addClass( 'shadow' );
					$shadow.delay( 350 ).fadeIn( 1000, function () {

						var $directContainer = $shadow.parents( '.activity-memory' ),
							shadowPosition = $shadow.position(),
							answerPosition = $answer.position(),
							shadowLeft = shadowPosition.left / $directContainer.innerWidth() * 100,
							shadowTop = shadowPosition.top / $directContainer.innerHeight() * 100,
							answerLeft = answerPosition.left / $directContainer.innerWidth() * 100,
							answerTop = answerPosition.top / $directContainer.innerHeight() * 100;

						$shadow.css( {
							top  : shadowTop + '%',
							left : shadowLeft + '%'
						} );
						$answer.css( {
							top  : answerTop + '%',
							left : answerLeft + '%'
						} );
					} );

				}
			} );


			$piece1.add( $piece2 ).addClass( 'success disabled' );

			$piece1.addClass( 'top-piece' );
			$piece2.addClass( 'bottom-piece' );


			if ( _this.sounds.success && _this.currState != 'replaying' ) {
				//	_this.sounds.success.play();

				createjs.Sound.stop();
				createjs.Sound.play( _this.sounds.success.id );

				PANA.D2L.fireEvent( 'bonne réponse', _this.chapter + ':' + _this.activity );
			}

			_this.currState = 'normal';
			_this.checkIsDone();

		}

	},
	showFail      : function ( $piece1, $piece2 ) {
		var _this = PANA.Games.chapitre1activite3;

		var $bothPieces = $piece1.add( $piece2 ),
			$failIcon = $( '<img class="gen-fail-x" alt="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKIAAACiAQMAAAD4PIRYAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAABpJREFUeNrtwQENAAAAwqD3T20PBxQAAABvBg3sAAHckWr/AAAAAElFTkSuQmCC">' ).appendTo( $bothPieces );

		$bothPieces.addClass( 'currently-failed' );

		setTimeout( function () {
			$failIcon.fadeOut( 'slow', function () {
				$bothPieces.removeClass( 'clicked currently-failed' );
				$( this ).remove();
				_this.currentSelection = false;

				_this.currState = 'normal';
			} );
		}, _this.failDuration );


		if ( _this.sounds.fail && _this.currState != 'replaying' ) {
			//_this.sounds.fail.stop().play();

			createjs.Sound.stop();
			createjs.Sound.play( _this.sounds.fail.id );

			PANA.D2L.fireEvent( 'mauvaise réponse', _this.chapter + ':' + _this.activity );
		}
	},
	checkIsDone   : function () {
		var _this = PANA.Games.chapitre1activite3;

		if ( _this.$allPieces.length === _this.$allPieces.filter( '.success' ).length ) {

			PANA.Games.utils.setGameState( _this.chapter, _this.activity, 'win' );

			if ( _this.sounds.win && _this.currState != 'replaying' ) {
				//_this.sounds.win.play();

				createjs.Sound.stop();
				createjs.Sound.play( _this.sounds.win.id );

				PANA.D2L.fireEvent( 'gagné', _this.chapter + ':' + _this.activity );
			}

			PANA.NAV.showNav();
		} else {
			PANA.Games.utils.setGameState( _this.chapter, _this.activity, 'begun' );
		}
	},
	saveAnswer    : function ( answer ) {
		var _this = PANA.Games.chapitre1activite3;

		PANA.Games.utils.saveAnswer( _this.chapter, _this.activity, answer );

	},
	replayAnswers : function () {
		var _this = PANA.Games.chapitre1activite3;

		var state = PANA.Games.utils.getGameState( _this.chapter, _this.activity ),
			answers;

		if ( state ) {
			answers = PANA.Games.utils.getAnswers( _this.chapter, _this.activity );
		}

		_this.currState = 'replaying';
		if ( answers ) {
			_this.$activityContainer.addClass( 'replaying' );

			for ( var i = 0; i < answers.length; i++ ) {
				//piece1: {
				//	clickedIndex: clickedIndex,
				//		pieceId     : clickedId
				//},
				//piece2: _this.currentSelection
				var piece1Index = answers[ i ].piece1.clickedIndex,
					piece1Id = answers[ i ].piece1.pieceId,
					piece2Index = answers[ i ].piece2.clickedIndex,
					piece2Id = answers[ i ].piece2.pieceId;

				if ( piece1Id === piece2Id ) {
					var $piece1 = _this.$allPieces.eq( piece1Index ),
						$piece2 = _this.$allPieces.eq( piece2Index );


					_this.currState = 'replaying'; // showSuccess overwrites this
					_this.showSuccess( $piece1, $piece2 );
				}
			}
			_this.$activityContainer.removeClass( 'replaying' );
			_this.currState = 'normal';
		}
	}
}