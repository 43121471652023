;
PANA.Games.chapitre1activite1 = {
	init          : function ( activityName, chapter, activity ) {
		var _this = PANA.Games.chapitre1activite1;

		_this.options = {
			snapTo : true
		}

		_this.cleanUp();

		_this.chapter = chapter;
		_this.activity = activity;
		_this.currState = 'normal';

		_this.$activityContainer = $( '.activity' );
		_this.$correctContainer = _this.$activityContainer.find( '.correct-answer' );
		_this.$correctContainerAmount = _this.$correctContainer.find( '.total-amount' );

		_this.correctId = _this.$correctContainer.data( 'correct-id' );
		_this.currentAmount = 0;
		_this.topZindex = 10;
		_this.failDuration = 1000; // duration to show the Fail X icon in milliseconds

		_this.$moneyBag = $( '.money-bag' );


		_this.cleanupSounds();
		_this.setupSounds();
		_this.setupTouch();
		_this.bindEvents();

		_this.replayAnswers();

	},
	cleanUp       : function () {
		var _this = PANA.Games.chapitre1activite1;

		// unbinding
		if ( typeof _this.$activityContainer != 'undefined' ) {
			_this.$activityContainer.off( 'dragstart' );
			_this.$activityContainer.off( 'click.touchGame' );
		}

		var keysToClean = [
			'options',
			'currState',
			'sounds',
			'$correctContainer',
			'$correctContainerAmount',
			'correctId',
			'currentAmount',
			'topZindex',
			'failDuration',
			'$moneyBag',
			'$allPieces',
			'$activityContainer',
		];

		PANA.UTILS.clearFromMemory( _this, keysToClean );

	},
	setupSounds   : function () {
		var _this = PANA.Games.chapitre1activite1;

		_this.sounds = {};


		var soundGeneralWin = _this.$activityContainer.data( 'sound-win' ) || false,
			soundGeneralFail = _this.$activityContainer.data( 'sound-fail' ) || false,
			soundGeneralSuccess = _this.$activityContainer.data( 'sound-success' ) || false;

		_this.sounds.win = !soundGeneralWin ? false : createjs.Sound.registerSound( soundGeneralWin, _this.chapter + '_' + _this.activity + '_' + 'win', 0, PANA.config.strAudioFolder );
		_this.sounds.fail = !soundGeneralFail ? false : createjs.Sound.registerSound( soundGeneralFail, _this.chapter + '_' + _this.activity + '_' + 'fail', 0, PANA.config.strAudioFolder );
		_this.sounds.success = !soundGeneralSuccess ? false : createjs.Sound.registerSound( soundGeneralSuccess, _this.chapter + '_' + _this.activity + '_' + 'success', 0, PANA.config.strAudioFolder );

		//_this.sounds.win = soundGeneralWin ? PANA.multimedia.audio.prepAudio( soundGeneralWin ) : false;
		//_this.sounds.win = createjs.Sound.registerSound(_this.$activityContainer.data( 'sound-win' ), _this.chapter + '_' + _this.activity + '_' + 'win', 0, PANA.config.strAudioFolder);
		//_this.sounds.fail = soundGeneralFail ? PANA.multimedia.audio.prepAudio( soundGeneralFail ) : false;
		//_this.sounds.success = soundGeneralSuccess ? PANA.multimedia.audio.prepAudio( soundGeneralSuccess, true ) : false;
	},
	cleanupSounds : function () {
		var _this = PANA.Games.chapitre1activite1;

	},
	bindEvents    : function () {
		var _this = PANA.Games.chapitre1activite1;

	},
	setupTouch    : function () {
		var _this = PANA.Games.chapitre1activite1;

		_this.$allPieces = _this.$activityContainer.find( '.activity-touch' );

		_this.$activityContainer.on( 'dragstart', function ( e ) {
			e.preventDefault();
		} );

		_this.$activityContainer.on( 'click.touchGame', '.activity-touch', function ( e ) {
			if ( $( this ).hasClass( 'disabled' ) ) {
				return false;
			}

			var $this = $( this ),
				clickedId = $this.data( 'touch-id' ),
				clickedIndex = _this.$allPieces.index( $this ),
				clickedValue = parseInt( $this.data( 'value' ) ),
				isMatch = clickedId == _this.correctId;

			_this.saveAnswer( $this, clickedIndex, clickedValue );

			if ( isMatch ) {
				_this.showSuccess( $this, clickedValue )
			} else {
				_this.showFail( $this );
			}

			_this.checkIsDone();

		} );

	},

	comparePiece  : function ( $piece1, $piece2 ) {
		var piece1Id = $piece1.data( 'piece-id' ),
			piece2Id = $piece2.data( 'piece-id' );

		return piece1Id == piece2Id;
	},
	showSuccess   : function ( $clicked, clickedValue ) {
		var _this = PANA.Games.chapitre1activite1;

		$clicked.addClass( 'success disabled' );

		var currX = $clicked.position().left,
			currY = $clicked.position().top,
			moneyBagX = _this.$moneyBag.position().left + ( _this.$moneyBag.outerWidth() / 2 ) - ($clicked.outerWidth() / 2),
			moneyBagY = _this.$moneyBag.position().top + ( _this.$moneyBag.outerHeight() / 2 ) - ($clicked.outerHeight() / 2),
			offsetY = ( _this.$moneyBag.offset().top - _this.$activityContainer.offset().top  ) * 1.5;

		$clicked.css( {
			left : currX,
			top  : currY
		} );

		_this.topZindex += 10;

		$clicked.css( 'z-index', _this.topZindex ); // make sure it's always on top

		if ( _this.currState != 'replaying' ) {
			if ( PANA.UTILS.areWeMobile() ) {
				// mobile version
				TweenLite.to( $clicked, 1, {
					bezier     : [ { left : moneyBagX + (_this.$moneyBag.outerWidth() / 2 ), top : moneyBagY - 100 }, {
						left : moneyBagX + (_this.$moneyBag.outerWidth() / 2 ),
						top  : moneyBagY
					} ], ease  : SlowMo.easeInOut,
					onComplete : function () {

						$clicked.remove();
					}
				} );
			} else {
				TweenLite.to( $clicked, 2, {
					bezier                                           : [ {
						left : moneyBagX - 150,
						top  : moneyBagY - offsetY
					}, { left : moneyBagX, top : moneyBagY } ], ease : SlowMo.easeInOut,
					onComplete                                       : function () {
						$clicked.remove();
					}
				} );
			}
		} else {
			// replaying. don'T want it animated
			$clicked.remove();
		}


		_this.currentAmount = _this.currentAmount + clickedValue;
		_this.$correctContainerAmount.html( PANA.UTILS.formatFrenchMoney( _this.currentAmount / 100 ) );


		if ( _this.sounds.success && _this.currState != 'replaying' ) {
			//if ( typeof _this.sounds.fail != 'undefined' ) {
			//	_this.sounds.fail.stop();
			//}
			//_this.sounds.success.play();

			createjs.Sound.stop();
			createjs.Sound.play( _this.sounds.success.id );

			PANA.D2L.fireEvent( 'bonne réponse', _this.chapter + ':' + _this.activity );
		}

		_this.checkIsDone();

	},
	showFail      : function ( $clicked ) {
		var _this = PANA.Games.chapitre1activite1;

		if ( _this.currState != 'replaying' ) {
			var $failIcon = $( '<img class="gen-fail-x" alt="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKIAAACiAQMAAAD4PIRYAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAABpJREFUeNrtwQENAAAAwqD3T20PBxQAAABvBg3sAAHckWr/AAAAAElFTkSuQmCC">' ).appendTo( $clicked );
			$clicked.addClass( 'failed disabled' );

			setTimeout( function () {
				$failIcon.fadeOut( 'slow', function () {
					$( this ).remove();
					$clicked.removeClass( 'failed disabled' );
				} );
			}, _this.failDuration )

		}

		if ( _this.sounds.fail && _this.currState != 'replaying' ) {


			createjs.Sound.stop();
			createjs.Sound.play( _this.sounds.fail.id );

			PANA.D2L.fireEvent( 'mauvaise réponse', _this.chapter + ':' + _this.activity );
		}
	},
	checkIsDone   : function () {
		var _this = PANA.Games.chapitre1activite1;

		if ( _this.$allPieces.filter( '.correct' ).length === _this.$allPieces.filter( '.success' ).length ) {
			PANA.Games.utils.setGameState( _this.chapter, _this.activity, 'win' );

			_this.endAnimation();

			if ( _this.sounds.win && _this.currState != 'replaying' ) {

				createjs.Sound.stop();
				createjs.Sound.play( _this.sounds.win.id );

				PANA.D2L.fireEvent( 'gagné', _this.chapter + ':' + _this.activity );
			}

			_this.$activityContainer.off( 'click.touchGame' );
			_this.$allPieces.addClass( 'disabled' );

			PANA.NAV.showNav();
		} else {
			PANA.Games.utils.setGameState( _this.chapter, _this.activity, 'begun' );
		}
	},
	saveAnswer    : function ( $clicked, clickedIndex, clickedValue ) {
		var _this = PANA.Games.chapitre1activite1;

		PANA.Games.utils.saveAnswer( _this.chapter, _this.activity, {
			pieceId    : $clicked.data( 'touch-id' ),
			pieceIndex : clickedIndex,
			pieceValue : clickedValue
		} );

	},
	replayAnswers : function () {
		var _this = PANA.Games.chapitre1activite1;

		var state = PANA.Games.utils.getGameState( _this.chapter, _this.activity ),
			answers;

		if ( state ) {
			answers = PANA.Games.utils.getAnswers( _this.chapter, _this.activity );
		}

		if ( answers ) {
			_this.currState = 'replaying';
			for ( var i = 0; i < answers.length; i++ ) {
				var pieceIndex = answers[ i ].pieceIndex,
					pieceId = answers[ i ].pieceId
				pieceValue = answers[ i ].pieceValue,
					isMatch = pieceId == _this.correctId;


				if ( isMatch ) {
					_this.showSuccess( _this.$allPieces.eq( pieceIndex ), pieceValue )
				} else {
					_this.showFail( _this.$allPieces.eq( pieceIndex ) );
				}

				_this.checkIsDone();


			}
			_this.currState = 'normal';
		}
	},
	endAnimation  : function () {
		var _this = PANA.Games.chapitre1activite1;


	}
}