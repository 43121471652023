;
PANA.Games.chapitre1activite2 = {
	init            : function ( activityName, chapter, activity ) {
		var _this = PANA.Games.chapitre1activite2;

		_this.cleanUp();
		_this.options = {
			variableThreshold : '30%',
			snapTo            : true
		}

		_this.chapter = chapter;
		_this.activity = activity;
		_this.currState = 'normal';

		_this.$activity = $( '.activity' );
		_this.$activityContainer = _this.$activity.find( '.activity-container' );
		_this.failDuration = 500;


		_this.cleanupSounds();
		_this.setupSounds();
		_this.setupDraggables();

		_this.bindEvents();

		_this.replayAnswers();

	},
	cleanUp         : function () {
		var _this = PANA.Games.chapitre1activite2;

		// Not 100% sure about this one
		if ( typeof _this.$draggables != 'undefined' ) {
			Draggable.get( _this.$draggables ).kill();
		}

		var keysToClean = [
			'options',
			'currState',
			'sounds',
			'$draggables',
			'$droppables',
			'failDuration',
			'$activity',
			'$activityContainer',

		];
		PANA.UTILS.clearFromMemory( _this, keysToClean );

	},
	bindEvents      : function () {
		var _this = PANA.Games.chapitre1activite2;

	},
	setupSounds     : function () {
		var _this = PANA.Games.chapitre1activite2;

		_this.sounds = {};

		var soundGeneralWin = _this.$activity.data( 'sound-win' ) || false,
			soundGeneralFail = _this.$activity.data( 'sound-fail' ) || false,
			soundGeneralSuccess = _this.$activity.data( 'sound-success' ) || false;

		_this.sounds.win = !soundGeneralWin ? false : createjs.Sound.registerSound( soundGeneralWin, _this.chapter + '_' + _this.activity + '_' + 'win', 0, PANA.config.strAudioFolder );
		_this.sounds.fail = !soundGeneralFail ? false : createjs.Sound.registerSound( soundGeneralFail, _this.chapter + '_' + _this.activity + '_' + 'fail', 0, PANA.config.strAudioFolder );
		_this.sounds.success = !soundGeneralSuccess ? false : createjs.Sound.registerSound( soundGeneralSuccess, _this.chapter + '_' + _this.activity + '_' + 'success', 0, PANA.config.strAudioFolder );
	},
	cleanupSounds   : function () {
		var _this = PANA.Games.chapitre1activite2;


	},
	setupDraggables : function () {
		var _this = PANA.Games.chapitre1activite2;

		_this.$draggables = _this.$activityContainer.find( '.activity-draggable img' );
		_this.$droppables = _this.$activityContainer.find( '.activity-droppable' );

		Draggable.create( _this.$draggables, {
			type    : "top,left",
			onPress : function () {
				StartX = this.x;
				StartY = this.y;
			},

			onRelease : function () {
				var $draggable = $( this.target ),
					_draggable = this,
					isMatch = false,
					$parentDraggableContainer = $draggable.parents( '.activity-draggable' ),
					$matchingDroppable,
					$attemptedDroppable;

				// Draggable's hitTest checks if it overlaps based on the threshold set
				// More info at http://greensock.com/docs/#/HTML5/GSAP/Utils/Draggable/hitTest/
				_this.$droppables.each( function () {

					// If they overlap AND aren't disabled
					if ( _draggable.hitTest( $( this ), _this.options.variableThreshold ) && !$( this ).hasClass( 'disabled' ) ) {

						// Setting this aside
						$attemptedDroppable = $( this );

						if ( _this.compareDragDrop( $parentDraggableContainer, $attemptedDroppable ) ) {
							isMatch = true;
							$matchingDroppable = $( this );
						}

						_this.saveAnswer( $attemptedDroppable.data( 'droppableId' ), $parentDraggableContainer.data( 'draggable-id' ) );
					}

				} );

				if ( isMatch ) {
					// Yay! Win!
					_this.showSuccess( $draggable, $matchingDroppable );
					//_this.checkIsDone();

				} else {

					// we ALWAYS want to revert the draggable to its original position
					_this.revertDraggable( $draggable );

					// but it doesn't mean that it actually hovered over a droppable
					if ( $attemptedDroppable ) {
						_this.showFail( $draggable, $attemptedDroppable );
						_this.checkIsDone();
					}
				}


			} // onRelease
		} );

	},
	resizeAdjust    : function () {
		var _this = PANA.Games.chapitre1activite2;

	},
	compareDragDrop : function ( $draggable, $droppable ) {
		var draggableId = $draggable.data( 'draggable-id' ),
			droppableId = $droppable.data( 'droppable-id' );

		return draggableId == droppableId;
	},
	revertDraggable : function ( $draggable ) {
		var _this = PANA.Games.chapitre1activite2;

		TweenLite.to( $draggable, 0.5, { left : 0, top : 0, ease : 'Elastic' } );
	},
	showSuccess     : function ( $draggable, $droppable ) {
		var _this = PANA.Games.chapitre1activite2;

		if ( _this.options.snapTo ) {
			var droppablePosition = $droppable.data( 'originalPosition' );

			Draggable.get( $draggable ).disable();

			$draggable.addClass( 'success' ).hide();
			$droppable.addClass( 'disabled success' );

			if ( _this.sounds.success && _this.currState != 'replaying' ) {
				//_this.sounds.success.play();

				createjs.Sound.stop();
				createjs.Sound.play( _this.sounds.success.id );
				PANA.D2L.fireEvent( 'bonne réponse', _this.chapter + ':' + _this.activity );
			}

			_this.checkIsDone();

		}

	},
	showFail        : function ( $draggable, $attemptedDroppable ) {
		var _this = PANA.Games.chapitre1activite2;

		var $failIcon = $( '<img class="gen-fail-x" alt="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKIAAACiAQMAAAD4PIRYAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAABpJREFUeNrtwQENAAAAwqD3T20PBxQAAABvBg3sAAHckWr/AAAAAElFTkSuQmCC">' ).appendTo( $attemptedDroppable );

		setTimeout( function () {
			$failIcon.fadeOut( 'slow', function () {
				$( this ).remove();
			} );
		}, _this.failDuration )


		if ( _this.sounds.fail && _this.currState != 'replaying' ) {
			//_this.sounds.fail.pos( 0 ).play();
			createjs.Sound.stop();
			createjs.Sound.play( _this.sounds.fail.id );

			PANA.D2L.fireEvent( 'mauvaise réponse', _this.chapter + ':' + _this.activity );
		}
	},
	checkIsDone     : function () {
		var _this = PANA.Games.chapitre1activite2;

		if ( _this.$draggables.length === _this.$draggables.filter( '.success' ).length ) {
			PANA.Games.utils.setGameState( _this.chapter, _this.activity, 'win' );
			_this.endAnimation();

			if ( _this.sounds.win && _this.currState != 'replaying' ) {
				//_this.sounds.win.play();

				createjs.Sound.stop();
				createjs.Sound.play( _this.sounds.win.id );

				PANA.D2L.fireEvent( 'gagné', _this.chapter + ':' + _this.activity );

			}
			PANA.NAV.showNav();
		} else {
			PANA.Games.utils.setGameState( _this.chapter, _this.activity, 'begun' );
		}
	},
	saveAnswer      : function ( draggableId, droppableId ) {
		var _this = PANA.Games.chapitre1activite2;

		PANA.Games.utils.saveAnswer( _this.chapter, _this.activity, {
			draggableId : draggableId,
			droppableId : droppableId
		} );

	},
	replayAnswers   : function () {
		var _this = PANA.Games.chapitre1activite2;

		var state = PANA.Games.utils.getGameState( _this.chapter, _this.activity ),
			answers;

		if ( state ) {
			answers = PANA.Games.utils.getAnswers( _this.chapter, _this.activity );
		}

		if ( answers ) {
			_this.currState = 'replaying';
			for ( var i = 0; i < answers.length; i++ ) {
				var draggableId = answers[ i ].draggableId,
					droppableId = answers[ i ].droppableId;

				if ( draggableId === droppableId ) {
					var $draggable = _this.$draggables.parents( '.coin' ).filter( '[data-draggable-id="' + draggableId + '"]' ).find( 'img' ),
						$droppable = _this.$droppables.filter( '[data-droppable-id="' + droppableId + '"]' );

					$draggable.css( 'zIndex', 5 );
					$droppable.css( 'zIndex', 4 );

					_this.showSuccess( $draggable, $droppable );
				}
			}
			_this.currState = 'normal';
		}
	},
	endAnimation    : function () {
		var _this = PANA.Games.chapitre1activite2;

		var desiredActivityPadding = parseFloat( _this.$activityContainer.outerHeight() ) / 2;
		_this.$activityContainer.animate( {
			'margin-top' : -desiredActivityPadding
		}, 500, function () {
			_this.$droppables.each( function ( i, el ) {
				window.setTimeout( function () {
					_this.$droppables.eq( i ).addClass( 'end-animation' );
				}, i * 250 );
			} );
		} );

		_this.$droppables.parents( '.activity-droppable' ).each( function ( i, el ) {
			var $this = $( this ),
				currTop = $this.position().top,
				currentActivityHeight = _this.$activityContainer.outerHeight(),
				desiredTop = currTop - ( currentActivityHeight / 2);

			$this.animate( {
				'top' : desiredTop
			}, 500 );
		} );


	}
}