;
PANA.Games.chapitre4activite4 = {
	init             : function ( activityName, chapter, activity ) {
		var _this = PANA.Games.chapitre4activite4;

		_this.cleanUp();

		_this.options = {
			snapTo : true
		}

		_this.chapter = chapter;
		_this.activity = activity;

		_this.$activityContainer = $( '.activity' );
		_this.$roulette = _this.$activityContainer.find( '.roulette' );
		_this.$ours = _this.$activityContainer.find( '.ours-content-container, .ours-triste-container' );
		_this.$spinButton = _this.$activityContainer.find( '.chap4act4-btn-tourner' );

		_this.currentState = 'not begun';


		_this.numQuestions = _this.steps.length;
		_this.numCorrectAnswers = 0;
		_this.currentStep = 0;
		_this.numSteps = 8;


		_this.$btnNext = _this.$activityContainer.find( '.question-nav' ).find( '.btn-next' );

		_this.setupSounds();
		_this.bindInfoClick();

		_this.setupTouch();
		_this.bindNextQuestion();

		_this.replayAnswers();


	},
	steps            : {
		1 : {
			description   : '7. Une jeune ourse qui offre une fleur à sa maman. (contente)',
			audio         : 'chapitre_4_activite_4_7.mp3',
			audioFail     : 'chapitre_4_activite_4_7_fail.mp3',
			angle         : -270,
			correctAnswer : 'contente'
		},
		2 : {
			description   : '3. Deux jeunes ourses qui se chicanent pour un jouet. (triste)',
			audio         : 'chapitre_4_activite_4_3.mp3',
			audioFail     : 'chapitre_4_activite_4_3_fail.mp3',
			angle         : -90,
			correctAnswer : 'triste'
		},
		3 : {
			description   : '1. Deux oursons qui jouent ensemble et une troisième jeune ourse qui joue seule, au loin. (triste)',
			audio         : 'chapitre_4_activite_4_1.mp3',
			audioFail     : 'chapitre_4_activite_4_1_fail.mp3',
			angle         : 0,
			correctAnswer : 'triste'
		},
		4 : {
			description   : '5. Une jeune ourse qui est blessée et marche avec des béquilles. (triste)',
			audio         : 'chapitre_4_activite_4_5.mp3',
			audioFail     : 'chapitre_4_activite_4_5_fail.mp3',
			angle         : -180,
			correctAnswer : 'triste'
		},
		5 : {
			description   : '2. Une maman ours qui donne un câlin à sa jeune ourse. (contente)',
			audio         : 'chapitre_4_activite_4_2.mp3',
			audioFail     : 'chapitre_4_activite_4_2_fail.mp3',
			angle         : -45,
			correctAnswer : 'contente'
		},

		6 : {
			description   : '4. Une jeune ourse qui aide sa petite sœur à attacher son soulier. (contente)',
			audio         : 'chapitre_4_activite_4_4.mp3',
			audioFail     : 'chapitre_4_activite_4_4_fail.mp3',
			angle         : -135,
			correctAnswer : 'contente'
		},
		7 : {
			description   : '6. Un papa ours qui chicane sa jeune ourse. (triste)',
			audio         : 'chapitre_4_activite_4_6.mp3',
			audioFail     : 'chapitre_4_activite_4_6_fail.mp3',
			angle         : -225,
			correctAnswer : 'triste'
		},
		8 : {
			description   : '8. Une jeune ourse qui partage un repas avec son ami. (jus avec deux pailles, dessert divisé en deux, contente)',
			audio         : 'chapitre_4_activite_4_8.mp3',
			audioFail     : 'chapitre_4_activite_4_8_fail.mp3',
			angle         : -315,
			correctAnswer : 'contente'
		}
	},
	cleanUp          : function () {
		var _this = PANA.Games.chapitre4activite4;

		// unbinding
		if ( typeof _this.$activityContainer != 'undefined' ) {
			_this.$activityContainer.off( 'click' )
		}
		if ( typeof _this.$ours != 'undefined' ) {
			_this.$ours.off( 'click' );
		}

		var keysToClean = [
			'options',
			'currentState',
			'currentStep',
			'numCorrectAnswers',
			'numQuestions',
			'numSteps',
			'sounds',
			'$roulette',
			'$ours',
			'$spinButton',
			'$btnNext',
			'$activityContainer',
		];

		PANA.UTILS.clearFromMemory( _this, keysToClean );
	},
	cleanupSounds    : function () {
		var _this = PANA.Games.chapitre4activite4;


	},
	setupSounds      : function () {
		var _this = PANA.Games.chapitre4activite4;


		_this.sounds = {};

		var soundGeneralWin = _this.$activityContainer.data( 'sound-win' ) || false,
			soundGeneralFail = _this.$activityContainer.data( 'sound-fail' ) || false,
			soundGeneralSuccess = _this.$activityContainer.data( 'sound-success' ) || false,
			soundGeneralSpin = _this.$activityContainer.data( 'sound-spin' ) || false;

		_this.sounds.win = soundGeneralWin ? createjs.Sound.registerSound( soundGeneralWin, _this.chapter + '_' + _this.activity + '_win', 0, PANA.config.strAudioFolder ) : false;
		_this.sounds.success = soundGeneralSuccess ? createjs.Sound.registerSound( soundGeneralSuccess, _this.chapter + '_' + _this.activity + '_success', 0, PANA.config.strAudioFolder ) : false;
		_this.sounds.fail = soundGeneralFail ? createjs.Sound.registerSound( soundGeneralFail, _this.chapter + '_' + _this.activity + '_fail', 0, PANA.config.strAudioFolder ) : false;
		_this.sounds.spin = soundGeneralSpin ? createjs.Sound.registerSound( soundGeneralSpin, _this.chapter + '_' + _this.activity + '_spin', 0, PANA.config.strAudioFolder ) : false;


		_this.sounds.steps = {};

		$.each( _this.steps, function ( qIndex, qElement ) {
			var audioInstructions = this.audio,
				audioFail = this.audioFail;

			_this.sounds.steps[ qIndex ] = {
				audioInstructions : createjs.Sound.registerSound( audioInstructions, _this.chapter + '_' + _this.activity + '_' + qIndex + '_instructions', 0, PANA.config.strAudioFolder ),
				audioFail         : createjs.Sound.registerSound( audioFail, _this.chapter + '_' + _this.activity + '_' + qIndex + '_fail', 0, PANA.config.strAudioFolder ),
			}

		} );
	},
	nextQuestion     : function ( onlyWrongAnswered ) {
		var _this = PANA.Games.chapitre4activite4;


		_this.currentStep++;

		_this.$ours.find( '.fail-icon' ).fadeOut( 'fast', function () {
			$( this ).remove();
		} );


		var thisCallback = function () {
			_this.currentState = 'normal';
		};

		_this.$activityContainer.removeClass( 'can-play' ).addClass( 'spinning' );

		var addCanPlay = function () {
			_this.$activityContainer.removeClass( 'spinning' ).addClass( 'can-play' );
		};

		_this.spinWheel( _this.$roulette, _this.steps[ _this.currentStep ].angle, 10, 5000, addCanPlay );

	},
	bindNextQuestion : function () {
		var _this = PANA.Games.chapitre4activite4;

		_this.$activityContainer.on( 'click', '.chap4act4-btn-tourner', function ( e ) {
			e.preventDefault();

			if ( _this.currentState != 'normal' && _this.currentState != 'answered' && _this.currentState != 'not begun' ) {
				return false;
			}

			_this.currentState = 'normal';

			var $this = $( this );

			_this.nextQuestion();

			$( this ).removeClass( 'active' ).fadeOut();

		} );
	},
	setupTouch       : function () {
		var _this = PANA.Games.chapitre4activite4;


		_this.$ours.on( 'click', function ( e ) {
			e.preventDefault();
			if ( _this.currentState != 'normal' ) {
				return false;
			}

			_this.$activityContainer.removeClass( 'can-play' );
			_this.validateAnswer( $( this ) );
			_this.currentState = 'answered';

		} );

	},
	bindInfoClick    : function () {
		var _this = PANA.Games.chapitre4activite4;

		_this.$activityContainer.on( 'click', '.chap4act4-btn-audio', function ( e ) {
			e.preventDefault();

			if ( typeof _this.sounds.steps[ _this.currentStep ][ 'audioInstructions' ] != 'undefined' ) {
				createjs.Sound.stop();
				createjs.Sound.play( _this.sounds.steps[ _this.currentStep ][ 'audioInstructions' ].id );

			}

		} );
	},

	validateAnswer  : function ( $clicked ) {
		var _this = PANA.Games.chapitre4activite4;

		var answer = $clicked.data( 'answer' ),
			correctAnswer = _this.steps[ _this.currentStep ].correctAnswer,
			isCorrect = answer == correctAnswer;

		PANA.debug.console.log( 'Validating. Expected: ' + correctAnswer + ' | Answered: ' + answer );

		if ( _this.currentState != 'replaying' ) {
			_this.saveAnswer( answer );
		}

		if ( isCorrect ) {
			_this.numCorrectAnswers++;
			_this.showSuccess();

		} else {
			_this.showFail( $clicked );
		}

		_this.checkIsDone();
	},
	showSuccess     : function ( $clicked, clickedValue ) {
		var _this = PANA.Games.chapitre4activite4;

		if ( _this.currentState != 'replaying' ) {
			createjs.Sound.stop();
			createjs.Sound.play( _this.sounds.success.id );
		}
	},
	showFail        : function ( $clicked ) {
		var _this = PANA.Games.chapitre4activite4;

		if ( _this.currentState != 'replaying' ) {

			var $failIcon = $( _this.failIcon ).appendTo( $clicked );

			if ( _this.sounds.steps[ _this.currentStep ].audioFail ) {
				// Play "success" specific to this choice
				createjs.Sound.stop();
				createjs.Sound.play( _this.sounds.steps[ _this.currentStep ].audioFail.id );
			} else if ( _this.sounds.fail ) {
				// Play a general "success" sound
				createjs.Sound.stop();
				createjs.Sound.play( _this.sounds.fail.id );
			}
		}
	},
	checkIsDone     : function () {
		var _this = PANA.Games.chapitre4activite4;

		PANA.debug.console.log( 'Step ' + _this.currentStep + ' of ' + _this.numSteps );

		if ( _this.currentStep == _this.numSteps ) {
			PANA.Games.utils.setGameState( _this.chapter, _this.activity, 'win' );
			_this.currentState = 'won';
			_this.endAnimation();

			if ( _this.sounds.win ) {
				createjs.Sound.stop();
				createjs.Sound.play( _this.sounds.win.id );
			}

			PANA.NAV.showNav();
		} else {
			PANA.Games.utils.setGameState( _this.chapter, _this.activity, 'begun' );
			_this.$spinButton.show().addClass( 'active' );
		}
	},
	saveAnswer      : function ( clickedValue ) {
		var _this = PANA.Games.chapitre4activite4;

		if ( _this.currentState != 'replayingBad' ) {
			PANA.Games.utils.saveAnswer( _this.chapter, _this.activity, clickedValue );
		} else if ( _this.currentState === 'replayingBad' ) {
			PANA.debug.console.log( 'Multiple choice : Overwriting answer', _this.currentQuestion );
			PANA.Games.utils.saveAnswer( _this.chapter, _this.activity, clickedValue, ( _this.currentQuestion - 1) );
		}

	},
	replayAnswers   : function () {
		var _this = PANA.Games.chapitre4activite4;

		var state = PANA.Games.utils.getGameState( _this.chapter, _this.activity ),
			answers;

		if ( state ) {
			answers = PANA.Games.utils.getAnswers( _this.chapter, _this.activity );
		}

		if ( answers ) {
			_this.currentState = 'replaying';

			_this.currentStep = answers.length;
			if ( _this.currentStep >= ( _this.numSteps  ) ) {
				_this.currentStep = _this.numSteps;
				_this.spinWheel( _this.$roulette, _this.steps[ _this.currentStep ].angle, 0, 0 );
				_this.$spinButton.hide();
				_this.checkIsDone();
			} else {
				_this.spinWheel( _this.$roulette, _this.steps[ _this.currentStep ].angle, 0, 0 );
				_this.currentState = 'answered';
			}

		}
	},
	endAnimation    : function () {
		var _this = PANA.Games.chapitre4activite4;


	},
	spinWheel       : function ( $element, finalAngle, spins, durationMs, callback ) {
		var _this = PANA.Games.chapitre4activite4;


		var currentRotation = ( _this.getGsapRotation( $element ) % 360 ) - 360,
			rotationFinal = ( spins * 360 ) + finalAngle - currentRotation;


		var spinSound = false;
		if ( _this.sounds.spin ) {
			createjs.Sound.stop();
			spinSound = createjs.Sound.play( _this.sounds.spin.id );
		}
		_this.currentState = 'spinnning';

		_this.fadeInSound( spinSound );

		setTimeout( function () {
			_this.fadeOutSound( spinSound );
		}, durationMs - 1500 );

		TweenLite.to( $element, ( durationMs / 1000 ), {
			rotation   : '+=' + rotationFinal, ease : Power3.easeInOut,
			onComplete : function () {
				_this.currentState = 'normal';
				if ( typeof callback == 'function' ) {
					callback();
				}

			}
		} );

		if ( durationMs > 0 ) {
			setTimeout( function () {
				$element.removeClass( 'faster' ).addClass( 'fast' );
			}, durationMs / 5 );

			setTimeout( function () {
				$element.removeClass( 'fast' ).addClass( 'faster' );
			}, durationMs / 5 * 2 );

			setTimeout( function () {
				$element.removeClass( 'faster' ).addClass( 'fast' );
				;
			}, durationMs / 5 * 3 );

			setTimeout( function () {
				$element.removeClass( 'fast faster' );
			}, durationMs / 5 * 4 );
		}

	},
	fadeOutSound    : function ( soundInstance ) {
		var numSteps = 10,
			fadeOutInterval = setInterval( function () {
				var desiredVolume = numSteps / 10;

				if ( desiredVolume > 0 ) {
					soundInstance.volume = desiredVolume;
					numSteps--;
				} else {
					soundInstance.stop();
					clearInterval( fadeOutInterval );
				}
			}, 100 );
	},
	fadeInSound     : function ( soundInstance ) {
		soundInstance.volume = 0;
		var numSteps = 10,
			fadeInInterval = setInterval( function () {
				var desiredVolume = 1 - (numSteps / 10 );

				if ( desiredVolume < 1 ) {
					soundInstance.volume = desiredVolume;
					numSteps--;
				} else {
					clearInterval( fadeInInterval );
				}
			}, 100 );
	},
	getGsapRotation : function ( $element ) {
		if ( typeof  $element[ 0 ]._gsTransform == 'undefined' || typeof  $element[ 0 ]._gsTransform.rotation == 'undefined' ) {
			return 0;
		} else {
			return $element[ 0 ]._gsTransform.rotation;
		}
	},
	failIcon        : '<img class="fail-icon" alt="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAANcAAADXAQMAAACzs1b2AAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAAB1JREFUeNrtwTEBAAAAwqD1T20KP6AAAAAAAICHAReEAAHU6brLAAAAAElFTkSuQmCC">'
}