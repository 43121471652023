;


PANA.config = {
	debug                 : false,
	strDefaultChapterPage : 'chapitre_0:accueil', //default page to load upon starting up
	override_start_page   : 'chapitre_0:accueil',
	autoLoadQuiz          : true,
	$ajaxContainer        : $( '#main-container' ),
	$pageWrapper          : $( '#page-wrap' ),
	$mainLogo             : $( '#header-main-logo' ),
	$body                 : $( 'body' ),
	strContentFolder      : '_content', // where the HTML content is
	strAudioFolder        : '_/audio/', // where the HTML content is
	mute                  : false,
	baseWidth             : 1920,
	baseHeight            : 1080,
	$D2Lparent            : parent.$( 'body' ),
	$activityBlocker      : $( '.activity-blocker' ),
	$footer               : $( '#footer-main' ),
	interruptAudio        : true,
};

PANA.config.$header = $( '#header-main' );
PANA.config.$headerInfoContainer = PANA.config.$header.find( '.header-info' );
PANA.config.$headerInfoTitle = PANA.config.$headerInfoContainer.find( '.chapter-title' );
PANA.config.$headerInfoInstructions = PANA.config.$headerInfoContainer.find( '.chapter-instructions' );
PANA.config.$headerInfoInstructionsPlayBtn = PANA.config.$headerInfoContainer.find( '.btn-play-instructions' );
PANA.config.$headerInfoChapter = PANA.config.$headerInfoContainer.find( '.chapter-number' );
PANA.config.$headerAvatarContainer = PANA.config.$header.find( '#header-avatar' );


PANA.chapters = {
	'chapitre_0' : {
		'status'  : false,
		'accueil' : {
			'type' : 'avatars',
		},
		'tdm'     : {
			'type' : 'tdm'
		}
	},
	'chapitre_1' : {// LA MONNAIE
		'status'           : false,
		'video'            : {
			type : 'video'
		},
		'activite_1_video' : {
			type : 'video'
		},
		'activite_1'       : {
			type : 'touch'
		}, // LE PORTE-MONNAIE
		'activite_2'       : {
			type : 'draggable'
		}, // LES SYMBOLES
		'activite_3'       : {
			type : 'memory'
		}, // LES JUMELLES
		'activite_4_video' : {
			type : 'video'
		},
		'activite_4'       : {
			type : 'moneyBank'
		} // UNE SURPRISE!
	},
	'chapitre_2' : { // LES SAISONS
		'status'     : false,
		'video'      : {
			type : 'video'
		},
		'activite_1' : {
			type : ''
		}, // HABILLE-MOI STP!
	},
	'chapitre_3' : { // L’ENVIRONNEMENT
		'status'           : false,
		'video'            : {
			type : 'video'
		},
		'activite_1_video' : {
			type : 'video'
		},
		'activite_1'       : {
			type : ''
		}, // LE GRAND MÉNAGE
		'activite_2'       : {
			type : ''
		}, // LA PLANTE

	},
	'chapitre_4' : { // SÉCURITÉ ET BIEN-ÊTRE
		'status'           : false,
		'video'            : {
			type : 'video'
		},
		'activite_1'       : {
			type : ''
		}, // LE BALLON
		'activite_2_video' : {
			type : 'video'
		},
		'activite_2'       : {
			type : ''
		}, // LA FÊTE!
		'activite_3'       : {
			type : ''
		}, // SE RÉCONCILIER
		'activite_4'       : {
			type : ''
		}, //  LA ROULETTE
		'activite_5_video' : {
			type : 'video'
		},
		'activite_5'       : {
			type : ''
		} // FROTTE, FROTTE, FROTTE!
	},
	'chapitre_5' : {
		'status'     : false,
		'video'      : {
			type : 'video'
		},
		'activite_1' : {
			type : 'map'
		} // AS-TU VU MON AMI L’OURS?
	}
};


PANA.defaultChapters = {
	'chapitre_0' : {
		'accueil' : {
			'type' : 'avatars',
		},
		'tdm'     : {
			'type' : 'tdm'
		}
	},
	'chapitre_1' : {// LA MONNAIE
		'video'            : {
			type : 'video'
		},
		'activite_1_video' : {
			type : 'video'
		},
		'activite_1'       : {
			type : 'touch'
		}, // LE PORTE-MONNAIE
		'activite_2'       : {
			type : 'draggable'
		}, // LES SYMBOLES
		'activite_3'       : {
			type : 'memory'
		}, // LES JUMELLES
		'activite_4_video' : {
			type : 'video'
		},
		'activite_4'       : {
			type : 'moneyBank'
		} // UNE SURPRISE!
	},
	'chapitre_2' : { // LES SAISONS
		'video'      : {
			type : 'video'
		},
		'activite_1' : {
			type : ''
		}, // HABILLE-MOI STP!
	},
	'chapitre_3' : { // L’ENVIRONNEMENT
		'video'            : {
			type : 'video'
		},
		'activite_1_video' : {
			type : 'video'
		},
		'activite_1'       : {
			type : ''
		}, // LE GRAND MÉNAGE
		'activite_2'       : {
			type : ''
		}, // LA PLANTE

	},
	'chapitre_4' : { // SÉCURITÉ ET BIEN-ÊTRE
		'video'            : {
			type : 'video'
		},
		'activite_1'       : {
			type : ''
		}, // LE BALLON
		'activite_2'       : {
			type : ''
		}, // LA FÊTE!
		'activite_3_video' : {
			type : 'video'
		},
		'activite_3'       : {
			type : ''
		}, // SE RÉCONCILIER
		'activite_4'       : {
			type : ''
		}, //  LA ROULETTE
		'activite_5_video' : {
			type : 'video'
		},
		'activite_5'       : {
			type : ''
		} // FROTTE, FROTTE, FROTTE!
	},
	'chapitre_5' : {
		'video'      : {
			type : 'video'
		},
		'activite_1' : {
			type : 'map'
		} // AS-TU VU MON AMI L’OURS?
	}
};