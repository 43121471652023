;
PANA.Games.chapitre3activite2 = {
	init            : function ( activityName, chapter, activity ) {
		var _this = PANA.Games.chapitre3activite2;

		_this.cleanUp();

		_this.options = {
			variableThreshold : '30%',
			snapTo            : true
		}

		_this.chapter = chapter;
		_this.activity = activity;
		_this.currState = 'normal';

		_this.$activityContainer = $( '.activity' );
		_this.$plantsContainer = $( '.plant-container' );
		_this.numGoodAnswers = 0;


		_this.setupSounds();
		_this.setupDraggables();

		_this.adjustSizes();

		_this.bindEvents();


		_this.replayAnswers();
	},
	cleanUp         : function () {
		var _this = PANA.Games.chapitre3activite2;


		if ( typeof  _this.$draggables != 'undefined' ) {
			Draggable.get( _this.$draggables ).kill();
		}

		var keysToClean = [
			'options',
			'currState',
			'sounds',
			'$plantsContainer',
			'numGoodAnswers',
			'$draggables',
			'$draggablesParents',
			'$droppables',
			'$activityContainer',
		];

		PANA.UTILS.clearFromMemory( _this, keysToClean );

	},
	adjustSizes     : function () {
		var _this = PANA.Games.chapitre3activite2;

		var $article = $( '#chapitre_3__activite_2' )
		$article.css( {
			height : 'auto'
		} );


		var $btnPlayInstructions = $( '.btn-play-instructions' ),
			bottomOfButton = $btnPlayInstructions.offset().top + $btnPlayInstructions.outerHeight(),
			footerHeight = $( '#footer-main' ).outerHeight(),
			$content = $( '#main-container' ).find( '.content' ),
			contentHeight = $content.outerHeight(),
			windowHeight = $( window ).height(),
			minHeight = bottomOfButton + contentHeight - footerHeight;


		if ( windowHeight > minHeight ) {
			$article.outerHeight( windowHeight - bottomOfButton - footerHeight );
		} else {
			$article.css( {
				height : 'auto'
			} );
		}


	},
	bindEvents      : function () {
		var _this = PANA.Games.chapitre3activite2;

		// Debounced so that the browser doesn't go crazy during resize
		var debouncedResize = PANA.UTILS.debounce( function () {
			_this.adjustSizes();
		}, 250 );
		window.addEventListener( 'resize', debouncedResize );
	},
	setupSounds     : function () {
		var _this = PANA.Games.chapitre3activite2;

		if ( typeof _this.sounds !== 'undefined' ) {
			delete _this.sounds;
		}

		_this.sounds = {};

		var soundGeneralWin = _this.$activityContainer.data( 'sound-win' ) || false,
			soundGeneralFail = _this.$activityContainer.data( 'sound-fail' ) || false,
			soundGeneralSuccess = _this.$activityContainer.data( 'sound-success' ) || false;

		_this.sounds.win = !soundGeneralWin ? false : createjs.Sound.registerSound( soundGeneralWin, _this.chapter + '_' + _this.activity + '_' + 'win', 0, PANA.config.strAudioFolder, PANA.config.defaultSoundJSprops );
		_this.sounds.fail = !soundGeneralFail ? false : createjs.Sound.registerSound( soundGeneralFail, _this.chapter + '_' + _this.activity + '_' + 'fail', 0, PANA.config.strAudioFolder, PANA.config.defaultSoundJSprops );
		_this.sounds.success = !soundGeneralSuccess ? false : createjs.Sound.registerSound( soundGeneralSuccess, _this.chapter + '_' + _this.activity + '_' + 'success', 0, PANA.config.strAudioFolder, PANA.config.defaultSoundJSprops );
	},
	setupDraggables : function () {
		var _this = PANA.Games.chapitre3activite2;

		_this.$draggablesParents = _this.$activityContainer.find( '.activity-draggable' );
		_this.$draggables = _this.$draggablesParents.find( 'img' );
		_this.$droppables = $( '.activity-droppable' );


		_this.Draggable = Draggable.create( _this.$draggables, {
			type      : "top,left",
			onRelease : function () {
				var $draggable = $( this.target ),
					$draggableParent = $draggable.parents( '.activity-draggable' ),
					_draggable = this,
					isMatch = false,
					$matchingDroppable,
					$attemptedDroppable,
					draggableIndex = _this.$draggables.index( $draggable );

				// Draggable's hitTest checks if it overlaps based on the threshold set
				// More info at http://greensock.com/docs/#/HTML5/GSAP/Utils/Draggable/hitTest/
				_this.$droppables.each( function () {

					// If they overlap AND aren't disabled
					if ( _draggable.hitTest( $( this ), _this.options.variableThreshold ) && !$( this ).hasClass( 'disabled' ) ) {

						// Setting this aside
						$attemptedDroppable = $( this );

						if ( _this.compareDragDrop( $draggableParent, $( this ) ) ) {
							isMatch = true;
							$matchingDroppable = $( this );
						}

					}

				} );

				if ( isMatch ) {
					// Yay! Win!
					_this.showSuccess( $draggable, $matchingDroppable );
					_this.saveAnswer( draggableIndex, _this.$droppables.index( $attemptedDroppable ) );

					//_this.checkIsDone();

				} else {

					// we ALWAYS want to revert the draggable to its original position
					_this.revertDraggable( $draggable );

					// but it doesn't mean that it actually hovered over a droppable
					if ( $attemptedDroppable ) {
						_this.showFail( $draggable, $attemptedDroppable );
						_this.checkIsDone();
					}
				}


			} // onRelease
		} );

	},
	compareDragDrop : function ( $draggable, $droppable ) {
		var draggableId = $draggable.data( 'draggable-id' ),
			droppableId = $droppable.data( 'droppable-id' );

		return draggableId == droppableId;
	},
	revertDraggable : function ( $draggable ) {

		TweenLite.to( $draggable, 0.5, { left : 0, top : 0, ease : 'Elastic' } );
	},
	showSuccess     : function ( $draggable, $droppable ) {
		var _this = PANA.Games.chapitre3activite2;

		if ( _this.options.snapTo ) {



			// Need to get the instance of the Draggable object rather than the jQuery object.
			Draggable.get( $draggable ).disable();
			$draggable.addClass( 'success' );

			if ( _this.currState != 'replaying' ) {
				$draggable.fadeOut( function () {
					$draggable.
					css( {
						left : 0,
						top  : 0,
					} )
				} );
			} else {
				$draggable.hide().css( {
					left : 0,
					top  : 0,
				} );
			}

			if ( _this.sounds.success && _this.currState != 'replaying' ) {
				createjs.Sound.stop();
				createjs.Sound.play( _this.sounds.success.id );

				PANA.D2L.fireEvent( 'bonne réponse', _this.chapter + ':' + _this.activity );
			}

			_this.checkIsDone();

		}

	},
	showFail        : function ( $draggable, $attemptedDroppable ) {
		var _this = PANA.Games.chapitre3activite2;

		if ( _this.sounds.fail && _this.currState != 'replaying' ) {
			createjs.Sound.stop();
			createjs.Sound.play( _this.sounds.fail.id );

			PANA.D2L.fireEvent( 'mauvaise réponse', _this.chapter + ':' + _this.activity );
		}
		if ( _this.currState != 'replaying' ) {
			var $failIcon = $( '<img class="gen-fail-x" alt="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKIAAACiAQMAAAD4PIRYAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAABpJREFUeNrtwQENAAAAwqD3T20PBxQAAABvBg3sAAHckWr/AAAAAElFTkSuQmCC">' ).appendTo( $attemptedDroppable );


			setTimeout( function () {
				$failIcon.fadeOut( 'slow', function () {
					$( this ).remove();
				} );
			}, _this.failDuration )

		}
	},
	checkIsDone     : function () {
		var _this = PANA.Games.chapitre3activite2;

		_this.numGoodAnswers = _this.$draggables.filter( '.success' ).length;
		PANA.debug.console.log( _this.numGoodAnswers + ' out of ' + _this.$draggablesParents.filter( '[data-draggable-id="compost"]' ).length + ' good answers' );

		_this.adjustPlant();


		if ( _this.$draggablesParents.filter( '[data-draggable-id="compost"]' ).length === _this.numGoodAnswers ) {
			PANA.Games.utils.setGameState( _this.chapter, _this.activity, 'win' );
			_this.endAnimation();

			_this.$draggables.each( function () {
				Draggable.get( this ).disable();
			} );

			if ( _this.sounds.win && _this.currState != 'replaying' ) {
				createjs.Sound.stop();
				createjs.Sound.play( _this.sounds.win.id );

			}


			if ( _this.currState != 'replaying' ) {
				PANA.D2L.fireEvent( 'mauvaise réponse', _this.chapter + ':' + _this.activity );
			}
			PANA.NAV.showNav();
		} else {
			PANA.Games.utils.setGameState( _this.chapter, _this.activity, 'begun' );
		}
	},
	saveAnswer      : function ( draggableIndex, droppableIndex ) {
		var _this = PANA.Games.chapitre3activite2;

		PANA.Games.utils.saveAnswer( _this.chapter, _this.activity, {
			draggableIndex : draggableIndex,
			droppableIndex : droppableIndex
		} );

	},
	replayAnswers   : function () {
		var _this = PANA.Games.chapitre3activite2;

		var state = PANA.Games.utils.getGameState( _this.chapter, _this.activity ),
			answers;

		if ( state ) {
			answers = PANA.Games.utils.getAnswers( _this.chapter, _this.activity );
		}

		if ( answers ) {
			_this.currState = 'replaying';
			for ( var i = 0; i < answers.length; i++ ) {
				var draggableIndex = answers[ i ].draggableIndex,
					droppableIndex = answers[ i ].droppableIndex;
				//if (draggableid === droppableId) {
				var $draggable = _this.$draggables.eq( draggableIndex ),
					$droppable = _this.$droppables.eq( droppableIndex );


				_this.showSuccess( $draggable, $droppable );
				//}
			}
			_this.currState = 'normal';
		}
	},
	endAnimation    : function () {
		var _this = PANA.Games.chapitre3activite2;


	},
	adjustPlant     : function () {
		var _this = PANA.Games.chapitre3activite2;

		_this.$plantsContainer
			.removeClass( 'state-0 state-1 state-2 state-3 state-4' )
			.addClass( 'state-' + _this.numGoodAnswers );

	}
}