;
PANA.Games.utils = {};

PANA.Games.utils.saveAnswer = function ( chapter, activity, answer, index ) {
	if ( typeof PANA.chapters[ chapter ] !== 'undefined' && typeof PANA.chapters[ chapter ][ activity ] !== 'undefined' ) {

		// Init the array of answers if it doesn't exist yet
		if ( typeof PANA.chapters[ chapter ][ activity ][ 'answered' ] === 'undefined' ) {
			PANA.chapters[ chapter ][ activity ][ 'answered' ] = [];
		}

		if ( typeof index === 'undefined' || index === false ) {
			PANA.chapters[ chapter ][ activity ][ 'answered' ].push( answer );
		} else if ( index > -1 ) {
			// Overwrite an answer
			PANA.debug.console.log( 'PANA.Games.utils.saveAnswer: Overwriting answer', answer );

			PANA.chapters[ chapter ][ activity ][ 'answered' ][ index ] = answer;
		}
	}
};

PANA.Games.utils.getAnswers = function ( chapter, activity ) {
	if (
		typeof PANA.chapters[ chapter ] !== 'undefined'
		&& typeof PANA.chapters[ chapter ][ activity ] !== 'undefined'
		&& PANA.chapters[ chapter ][ activity ][ 'answered' ] !== 'undefined'
	) {
		return PANA.chapters[ chapter ][ activity ][ 'answered' ];
	} else {
		return false;
	}
};


PANA.Games.utils.resetAnswers = function ( chapter, activity ) {
	if ( typeof PANA.chapters[ chapter ] !== 'undefined' && typeof PANA.chapters[ chapter ][ activity ] !== 'undefined' ) {

		if ( typeof PANA.chapters[ chapter ][ activity ][ 'answered' ] !== 'undefined' ) {
			delete PANA.chapters[ chapter ][ activity ][ 'answered' ];
		}
	}
};

PANA.Games.utils.setGameState = function ( chapter, activity, state ) {

	// Init the state if it doesn't exist
	if ( typeof PANA.chapters[ chapter ][ activity ][ 'state' ] === 'undefined' ) {
		PANA.chapters[ chapter ][ activity ][ 'state' ] = '';
	}
	PANA.chapters[ chapter ][ activity ][ 'state' ] = state;
}

PANA.Games.utils.getGameState = function ( chapter, activity ) {

	if ( typeof PANA.chapters[ chapter ][ activity ][ 'state' ] !== 'undefined' ) {
		return PANA.chapters[ chapter ][ activity ][ 'state' ];
	} else {
		return false;
	}
}

PANA.Games.utils.resetGameState = function ( chapter, activity ) {

	// Init the state if it doesn't exist
	if ( typeof PANA.chapters[ chapter ][ activity ][ 'state' ] !== 'undefined' ) {
		delete PANA.chapters[ chapter ][ activity ][ 'state' ]
	}
}


PANA.Games.utils.loadGame = function ( chaptersAnswers ) {
	PANA.chapters = chaptersAnswers;
}

PANA.Games.utils.markChaptersDone = function () {

}
